import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { BehaviorSubject } from 'rxjs';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class UserManagementAPIService {
  constructor(private http: HttpClient) {}

  public themeValue = new BehaviorSubject(this.theme);
  public token = '';
  public userNameList = new BehaviorSubject([]);

  public get theme() {
    return localStorage.getItem('theme');
  }

  public set theme(themeValue) {
    this.themeValue.next(themeValue); // this will make sure to tell every subscriber about the change.
    localStorage.setItem('theme', themeValue!);
  }

  public associateSoftwareToken(accessToken: string): Observable<string> {
    this.token = window.localStorage.getItem('Authorization')!;

    var headers = new HttpHeaders().set('Authorization', this.token);

    var response = this.http.post<string>(
      environment.URLAsociateSoftwareToken,
      {
        AccessToken: accessToken,
      },
      { headers: headers }
    );

    return response;
  }

  public verifySoftwareToken(
    userCode: string,
    FriendlyDeviceName: string
  ): Observable<string> {
    this.token = window.localStorage.getItem('Authorization')!;
    var accessToken = window.localStorage.getItem('AccessToken')!;
    var acAccessToken = window.localStorage.getItem('ACAccessToken')!;

    var headers = new HttpHeaders().set('Authorization', this.token);

    var response = this.http.post<string>(
      environment.URLVerifySoftwareToken,
      {
        AccessToken: acAccessToken == null ? accessToken : acAccessToken,
        UserCode: userCode,
        FriendlyDeviceName: FriendlyDeviceName,
      },
      { headers: headers }
    );

    return response;
  }

  public updatecurrentusermfastatus(status: string): Observable<string> {
    this.token = window.localStorage.getItem('Authorization')!;
    var headers = new HttpHeaders().set('X-Api-Key', environment.apiKey);

    var response = this.http.post<string>(
      environment.URLupdatecurrentusermfastatus,
      {
        username: localStorage.getItem('SessionUsername'),
        status: status,
      },
      { headers: headers }
    );

    return response;
  }

  public getcurrentusermfastatus(): Observable<string> {
    this.token = window.localStorage.getItem('Authorization')!;
    var headers = new HttpHeaders().set('X-Api-Key', environment.apiKey);

    var response = this.http.post<string>(
      environment.URLgetcurrentusermfastatus,
      {
        username: localStorage.getItem('SessionUsername'),
      },
      { headers: headers }
    );

    return response;
  }

  public disableMFA(): Observable<string> {
    var headers = new HttpHeaders().set('X-Api-Key', environment.apiKey);

    var response = this.http.post<string>(
      environment.URLdisablemfa,
      {
        username: localStorage.getItem('SessionUsername'),
      },
      { headers: headers }
    );

    return response;
  }

  public adminDisableMFA(username?: string): Observable<string> {
    var headers = new HttpHeaders().set('X-Api-Key', environment.apiKey);

    var response = this.http.post<string>(
      environment.URLdisablemfa,
      {
        username:
          username == null ? localStorage.getItem('SessionUsername') : username,
      },
      { headers: headers }
    );

    return response;
  }

  public getDownloableFilesByProfileType(userType: string): Observable<string> {
    this.token = window.localStorage.getItem('Authorization')!;

    var headers = new HttpHeaders().set('Authorization', this.token);

    var response = this.http.post<string>(
      environment.URLgetdownloablefilesbyprofiletype,
      {
        usertype: userType,
      },
      { headers: headers }
    );

    return response;
  }

  public addNewUserCognito(username: string): Observable<string> {
    var headers = new HttpHeaders().set('X-Api-Key', environment.apiKey);

    var response = this.http.post<string>(
      environment.URLaddnewusercognito,
      {
        username: username,
      },
      { headers: headers }
    );

    return response;
  }

  public getLicensesNumber(): Observable<string> {
    this.token = window.localStorage.getItem('Authorization')!;

    var headers = new HttpHeaders().set('Authorization', this.token);

    var response = this.http.post<string>(
      environment.URLgetuserslicensesnumber,
      {},
      { headers: headers }
    );

    return response;
  }

  public updateNewUserStatus(username: string): Observable<string> {
    this.token = window.localStorage.getItem('Authorization')!;

    var headers = new HttpHeaders().set('Authorization', this.token);

    var response = this.http.post<string>(
      environment.URLupdatenewuserstatus,
      { username: username },
      { headers: headers }
    );

    return response;
  }

  public addNewUserDB(
    username: string,
    userType: string,
    name: string,
    surname: string,
    departments: Array<string>,
    language: string
  ): Observable<string> {
    this.token = window.localStorage.getItem('Authorization')!;

    var headers = new HttpHeaders().set('Authorization', this.token);

    var response = this.http.post<string>(
      environment.URLaddnewuserdb,
      {
        username: username,
        userType: userType,
        name: name,
        surname: surname,
        departments: departments,
        language: language,
      },
      { headers: headers }
    );

    return response;
  }

  public addNewUserPasswordCognito(password: string): Observable<string> {
    var headers = new HttpHeaders().set('X-Api-Key', environment.apiKey);

    var response = this.http.post<string>(
      environment.URLsetnewuserpasswordcognito,
      {
        username: localStorage.getItem('SessionUsername'),
        password: password,
      },
      { headers: headers }
    );

    return response;
  }

  public changePassword(oldPass: string, newPass: string): Observable<string> {
    var headers = new HttpHeaders().set('X-Api-Key', environment.apiKey);

    var accestoken = window.localStorage.getItem('AccessToken');

    var response = this.http.post<string>(
      environment.URLchangepassword,
      {
        accestoken: accestoken,
        previous: oldPass,
        proposed: newPass,
        username: localStorage.getItem('SessionUsername'),
      },
      { headers: headers }
    );

    return response;
  }

  public changeUserPass(userName: string, newPass: string) {
    var headers = new HttpHeaders().set('X-Api-Key', environment.apiKey);

    var response = this.http.post<string>(
      environment.URLsetnewuserpasswordcognito,
      {
        username: userName,
        password: newPass,
      },
      { headers: headers }
    );

    return response;
  }

  public changeUserPasswordCognito(
    username: string,
    password: string
  ): Observable<string> {
    var headers = new HttpHeaders().set('X-Api-Key', environment.apiKey);

    var response = this.http.post<string>(
      environment.URLsetnewuserpasswordcognito,
      {
        username: username,
        password: password,
      },
      { headers: headers }
    );

    return response;
  }

  public checkUserAvailability(
    username: string,
    userType: string
  ): Observable<string> {
    this.token = window.localStorage.getItem('Authorization')!;

    var headers = new HttpHeaders().set('Authorization', this.token);

    var response = this.http.post<string>(
      environment.URLcheckuseravailability,
      {
        username: username,
        userType: userType,
      },
      {
        headers: headers,
      }
    );

    return response;
  }

  public currentUserInfo(username: string): Observable<string> {
    var headers = new HttpHeaders().set('X-Api-Key', environment.apiKey);

    var response = this.http.post<string>(
      environment.URLcurrentuserinfo,
      {
        username: username,
      },
      {
        headers: headers,
      }
    );

    return response;
  }

  public disabledUserCognito(usersname: Array<string>) {
    var headers = new HttpHeaders().set('X-Api-Key', environment.apiKey);

    var response = this.http.post<string>(
      environment.URLdisableusercognito,
      {
        usernames: usersname,
      },
      { headers: headers }
    );

    return response;
  }

  public disabledUserCognitoWeb(usersname: Array<string>) {
    this.token = window.localStorage.getItem('Authorization')!;

    var headers = new HttpHeaders().set('Authorization', this.token);

    var response = this.http.post<string>(
      environment.URLdisableusercognito,
      {
        usernames: usersname,
      },
      { headers: headers }
    );

    return response;
  }

  public forceChangePassword(password: string): Observable<string> {
    var headers = new HttpHeaders().set('X-Api-Key', environment.apiKey);

    var response = this.http.post<string>(
      environment.URLforcechangeuserpassword,
      {
        username: localStorage.getItem('SessionUsername'),
        password: password,
        session: localStorage.getItem('SessionToken'),
      },
      { headers: headers }
    );

    return response;
  }

  public forgotPassword(username: string): Observable<string> {
    var headers = new HttpHeaders().set('X-Api-Key', environment.apiKey);

    var response = this.http.post<string>(
      environment.URLforgotpassword,
      {
        username: username,
      },
      { headers: headers }
    );

    return response;
  }

  public forgotPasswordConfirmation(
    username: string,
    confirmationCode: string,
    newPass: string
  ): Observable<string> {
    var headers = new HttpHeaders().set('X-Api-Key', environment.apiKey);

    var response = this.http.post<string>(
      environment.URLforgotpasswordconfirmation,
      {
        username: username,
        confirmationCode: confirmationCode,
        newPass: newPass,
      },
      { headers: headers }
    );

    return response;
  }

  public getBearer(username: string, password: string): Observable<string> {
    var headers = new HttpHeaders().set('X-Api-Key', environment.apiKey);

    var response = this.http.post<string>(
      environment.URLloginweb,
      { username: username, password: password },
      { headers: headers }
    );

    return response;
  }

  public getUserDetails(username: string): Observable<string> {
    this.token = window.localStorage.getItem('Authorization')!;

    var headers = new HttpHeaders().set('Authorization', this.token);

    var response = this.http.post<string>(
      environment.URLcurrentuserinfo,
      {
        username: username,
      },
      {
        headers: headers,
      }
    );

    return response;
  }

  public getUserList(
    username: string,
    disabled: string,
    connectionStatus: string,
    hardware: string,
    creationDate: string
  ): Observable<string> {
    this.token = window.localStorage.getItem('Authorization')!;

    var headers = new HttpHeaders().set('Authorization', this.token);

    var response = this.http.post<string>(
      environment.URLuserlistweb,
      {
        requestuser: localStorage.getItem('SessionUsername'),
        username: username,
        disabled: disabled,
        connectionstatus: connectionStatus,
        hardware: hardware,
        creationdate: creationDate,
      },
      {
        headers: headers,
      }
    );

    return response;
  }

  public getUsersNameList() {
    return this.userNameList.value;
  }

  public logLogin(username: string, userDevice: string): Observable<string> {
    this.token = window.localStorage.getItem('Authorization')!;

    var headers = new HttpHeaders().set('Authorization', this.token);

    var response = this.http.post<string>(
      environment.URLlogLogin,
      {
        username: username,
        userDevice: userDevice,
      },
      {
        headers: headers,
      }
    );

    return response;
  }

  public mfaLogin(mfaToken: string): Observable<string> {
    var headers = new HttpHeaders().set('X-Api-Key', environment.apiKey);

    var session = window.localStorage.getItem('SessionToken');
    var SessionUsername = window.localStorage.getItem('SessionUsername');

    var response = this.http.post<string>(
      environment.URLloginsecondfactor,
      { session: session, mfacode: mfaToken, username: SessionUsername },
      { headers: headers }
    );

    return response;
  }

  public reactivateUserCognito(username: string): Observable<string> {
    var headers = new HttpHeaders().set('X-Api-Key', environment.apiKey);

    var response = this.http.post<string>(
      environment.URLreactivateusercognito,
      {
        username: username,
      },
      { headers: headers }
    );

    return response;
  }

  public reactivateUserCognitoWeb(username: string): Observable<string> {
    this.token = window.localStorage.getItem('Authorization')!;

    var headers = new HttpHeaders().set('Authorization', this.token);

    var response = this.http.post<string>(
      environment.URLreactivateusercognito,
      {
        username: username,
      },
      { headers: headers }
    );

    return response;
  }

  public refreshToken(): Observable<string> {
    var headers = new HttpHeaders().set('X-Api-Key', environment.apiKey);

    var refreshToken = window.localStorage.getItem('RefreshToken');

    var response = this.http.post<string>(
      environment.URLrefreshtoken,
      { refreshToken: refreshToken, platform: environment.platform },
      { headers: headers }
    );

    return response;
  }

  public removeToken(username: string, password: string): Observable<string> {
    var headers = new HttpHeaders().set('X-Api-Key', environment.apiKey);

    localStorage.removeItem('RefreshToken');

    var response = this.http.post<string>(
      environment.URLremoveToken,
      {
        username: username,
        password: password,
        platform: environment.platform,
      },
      { headers: headers }
    );

    return response;
  }

  public removeUserCognito(username: string): Observable<string> {
    var headers = new HttpHeaders().set('X-Api-Key', environment.apiKey);

    var response = this.http.post<string>(
      environment.URLremoveusercognito,
      {
        username: username,
      },
      { headers: headers }
    );

    return response;
  }

  public removeUserCognitoWeb(username: string): Observable<string> {
    this.token = window.localStorage.getItem('Authorization')!;

    var headers = new HttpHeaders().set('Authorization', this.token);

    var response = this.http.post<string>(
      environment.URLremoveusercognito,
      {
        username: username,
      },
      { headers: headers }
    );

    return response;
  }

  public setUsersNameList(arrayList) {
    return this.userNameList.next(arrayList);
  }

  public updateDisabledStatus(
    userList: Array<string>,
    status: string
  ): Observable<string> {
    this.token = window.localStorage.getItem('Authorization')!;

    var headers = new HttpHeaders().set('Authorization', this.token);

    var response = this.http.post<string>(
      environment.URLupdatedisabledstatus,
      {
        usernames: userList,
        newStatus: status,
      },
      {
        headers: headers,
      }
    );

    return response;
  }
}
