<div (keyup.enter)="backToUserList()" class="content">
  <Label class="tokenLabel" *ngIf="activateUserSuccess == true" i18n
    >The selected user has been successfully reactivated.
  </Label>
  <Label class="tokenLabel" *ngIf="activateUserSuccess != true" i18n
    >Sorry, selected user could not be reactivated.
  </Label>
  <Label
    class="tokenLabel"
    *ngIf="activateUserSuccess != true && userTypeFull"
    i18n
    >This type of user has all the licenses in use.
  </Label>
</div>
<div>
  <button class="confirmButton" mat-button (click)="backToUserList()" i18n>
    Back
  </button>
</div>
