<div class="container">
  <div class="content">
    <div *ngIf="isUserAdded" class="info" i18n>
      User added to the department
    </div>
    <div *ngIf="!isUserAdded" class="info" i18n>
      The user could not be added, please, try again later
    </div>
    <div class="buttonDiv">
      <button
        *ngIf="isUserAdded"
        mat-raised-button
        color="primary"
        class="confirmButton button"
        (click)="closeDialogAndRefresh()"
      >
        <label> Ok </label></button
      ><button
        *ngIf="!isUserAdded"
        mat-raised-button
        color="primary"
        class="confirmButton button"
        (click)="closeDialog()"
      >
        <label> Ok </label>
      </button>
    </div>
  </div>
</div>
