<body>
  <div class="content">
    <div>
      <label class="text" i18n>You are not allowed to access this page</label>
    </div>
    <div class="buttons">
      <button mat-button (click)="close()">Close</button>
    </div>
  </div>
</body>
