import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-mfa-login-confirmation-dialog',
  templateUrl: './mfa-login-confirmation-dialog.component.html',
  styleUrls: ['./mfa-login-confirmation-dialog.component.css'],
})
export class MfaLoginConfirmationDialogComponent implements OnInit {
  constructor(
    private dialogRef: MatDialogRef<MfaLoginConfirmationDialogComponent>
  ) {}

  public closeDialog() {
    this.dialogRef.close();
  }

  ngOnInit(): void {}
}
