<body class="sessionsBodyComponent">
  <div class="sessions-container hidden" id="hiddenContainer">
    <div class="filter-buttons">
      <button
        mat-flat-button
        class="filterButton"
        color="primary"
        (click)="filterByStartDates()"
        i18n
      >
        <span class="material-symbols-outlined"> schedule </span>
        Filter by start date
      </button>
      <button
        mat-flat-button
        class="filterButton"
        color="primary"
        (click)="filterByEndDates()"
        i18n
      >
        <span class="material-symbols-outlined"> schedule </span>
        Filter by end date
      </button>
      <button
        mat-icon-button
        color="primary"
        *ngIf="filtering"
        (click)="reload()"
      >
        <mat-icon>refresh</mat-icon>
      </button>
      <label class="filterLabel" *ngIf="filteringByStartDate && filtering" i18n
        >filtering start date between
      </label>
      <b class="filterLabel" *ngIf="filteringByStartDate && filtering">{{
        startDateFormated
      }}</b>
      <label class="filterLabel" *ngIf="filteringByStartDate && filtering" i18n>
        and
      </label>
      <b class="filterLabel" *ngIf="filteringByStartDate && filtering">{{
        endDateFormated
      }}</b>

      <label class="filterLabel" *ngIf="filteringByEndDate && filtering" i18n
        >filtering end date between
      </label>
      <b class="filterLabel" *ngIf="filteringByEndDate && filtering">{{
        startDateFormated
      }}</b>
      <label class="filterLabel" *ngIf="filteringByEndDate && filtering" i18n>
        and
      </label>
      <b class="filterLabel" *ngIf="filteringByEndDate && filtering">{{
        endDateFormated
      }}</b>
    </div>
    <div class="filter-bar">
      <mat-form-field appearance="fill" class="filterSessionInput">
        <mat-label i18n>Filter</mat-label>
        <input
          matInput
          (keyup)="applyFilter($event)"
          placeholder="Search by selected fields"
          i18n-placeholder="Search by selected fields placeholder"
          #input
          class="filterInput"
        />
      </mat-form-field>
      <mat-form-field appearance="fill" class="filter-options selectTextBlack">
        <mat-label i18n class="filterByTitleLabel">Filter by</mat-label>
        <mat-select
          [formControl]="filterOptions"
          [value]="filterSelect"
          multiple
        >
          <mat-select-trigger>
            {{filterOptions.value?.[0] || ''}}
            <span
              *ngIf="(filterOptions.value?.length || 0) > 1"
              class="filter-option-additional-selection"
            >
              (+{{ (filterOptions.value?.length || 0) - 1 }}
              {{ filterOptions.value?.length === 2 ? "other" : "others" }})
            </span>
          </mat-select-trigger>
          <mat-option
            *ngFor="let filterOption of filterOptionList"
            [value]="filterOption"
            >{{ filterOption }}</mat-option
          >
        </mat-select>
      </mat-form-field>
    </div>
    <div class="table-container">
      <table
        id="sessionsTable"
        mat-table
        [dataSource]="dataSource"
        matSort
        matSortActive="start"
        matSortDirection="desc"
      >
        <ng-container class="select" matColumnDef="select">
          <th mat-header-cell *matHeaderCellDef>
            <mat-checkbox
              (change)="$event ? toggleAllRows() : null"
              [checked]="selection.hasValue() && isAllSelected()"
              [indeterminate]="selection.hasValue() && !isAllSelected()"
              [aria-label]="checkboxLabel()"
            >
            </mat-checkbox>
          </th>
          <td mat-cell *matCellDef="let row">
            <mat-checkbox
              class="checkBox"
              (click)="$event.stopPropagation()"
              (change)="$event ? selection.toggle(row) : null"
              [checked]="selection.isSelected(row)"
              [aria-label]="checkboxLabel(row)"
            >
            </mat-checkbox>
          </td>
        </ng-container>
        <ng-container matColumnDef="localId">
          <th mat-header-cell *matHeaderCellDef mat-sort-header i18n>
            Local ID
          </th>
          <td mat-cell *matCellDef="let element">{{ element.localId }}</td>
        </ng-container>

        <ng-container matColumnDef="remoteId">
          <th mat-header-cell *matHeaderCellDef mat-sort-header i18n>
            Remote ID
          </th>
          <td mat-cell *matCellDef="let element">{{ element.remoteId }}</td>
        </ng-container>

        <ng-container matColumnDef="status">
          <th mat-header-cell *matHeaderCellDef mat-sort-header i18n>Status</th>
          <td mat-cell *matCellDef="let element">{{ element.status }}</td>
        </ng-container>

        <ng-container matColumnDef="start">
          <th
            class="start"
            mat-header-cell
            *matHeaderCellDef
            mat-sort-header
            i18n
          >
            Start
          </th>
          <td class="start" mat-cell *matCellDef="let element">
            <label *ngIf="!checkSessionDate(element.start)">
              {{ element.start | date : "dd/MM/yyyy HH:mm" }}
            </label>
            <label *ngIf="checkSessionDate(element.start)" i18n>
              Date not added
            </label>
          </td>
        </ng-container>

        <ng-container matColumnDef="end">
          <th mat-header-cell *matHeaderCellDef mat-sort-header i18n>End</th>
          <td mat-cell *matCellDef="let element">
            <label *ngIf="!checkSessionDate(element.end)">
              {{ element.end | date : "dd/MM/yyyy HH:mm" }}
            </label>
            <label *ngIf="checkSessionDate(element.end)" i18n>
              Date not added
            </label>
          </td>
        </ng-container>

        <ng-container matColumnDef="quality">
          <th mat-header-cell *matHeaderCellDef mat-sort-header i18n>
            Quality
          </th>
          <td mat-cell *matCellDef="let element">
            <label *ngIf="element.quality != null">{{ element.quality }}</label>
            <label *ngIf="element.quality == null" i18n>Not added</label>
          </td>
        </ng-container>

        <ng-container matColumnDef="reportContents">
          <th mat-header-cell *matHeaderCellDef mat-sort-header i18n>Report</th>
          <td mat-cell *matCellDef="let element">
            <label
              *ngIf="
                element.reportContents != null &&
                element.reportContents != getNotAddedMessage()
              "
              ><mat-icon>description</mat-icon></label
            >
            <label
              *ngIf="
                element.reportContents == null ||
                element.reportContents == getNotAddedMessage()
              "
              ><mat-icon> no_sim</mat-icon></label
            >
          </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr
          mat-row
          *matRowDef="let row; columns: displayedColumns"
          (click)="navigateToReport(row)"
        ></tr>
      </table>
    </div>
    <div class="paginator">
      <mat-paginator
        [pageSizeOptions]="[5, 10, 20]"
        [pageIndex]=""
        showFirstLastButtons
        aria-label="Select page of sessions"
      >
      </mat-paginator>
    </div>

    <div class="filter-buttons">
      <button
        mat-flat-button
        class="removeButton"
        *ngIf="!selection.isEmpty() && removing"
        color="primary"
        (click)="removeSessions()"
        i18n
      >
        Delete
      </button>
      <button
        mat-flat-button
        class="removeButton"
        *ngIf="removing"
        color="primary"
        (click)="cancelDeleteSession()"
        i18n
      >
        Cancel
      </button>
    </div>
  </div>
</body>
