import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-invalid-token',
  templateUrl: './invalid-token.component.html',
  styleUrls: ['./invalid-token.component.css'],
})
export class InvalidTokenComponent implements OnInit {
  confirm() {
    this.dialog.closeAll();
  }

  public revoked: boolean;

  constructor(
    public dialogRef: MatDialogRef<InvalidTokenComponent>,
    public dialog: MatDialog
  ) {}

  ngOnInit(): void {
    window.localStorage.removeItem('RefreshToken');
  }
}
