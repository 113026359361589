import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { UserManagementAPIService } from 'src/app/Services/UserManagement/user-management-api.service';
import { DirectDownloadComponentComponent } from '../direct-download-component/direct-download-component.component';

@Component({
  selector: 'app-executables-downloads',
  templateUrl: './executables-downloads.component.html',
  styleUrls: ['./executables-downloads.component.css'],
})
export class ExecutablesDownloadsComponent implements OnInit {
  constructor(
    private userManegement: UserManagementAPIService,
    public dialog: MatDialog,
    private sanitizer: DomSanitizer
  ) {}

  public downloadables: Map<string, JSON> = new Map<string, JSON>();
  public fieldDownloables = 5;
  public userType = window.localStorage.getItem('userType');

  public getLast(): string {
    return Array.from(this.downloadables).sort().pop()[0];
  }

  public openQrCodeDownloadDialog(url: string) {
    var dialog = this.dialog.open(DirectDownloadComponentComponent, {
      height: '35em',
      width: '30em',
    });

    dialog.componentInstance.qrUrl = url;
  }

  public sanitizeUrl(url: string): SafeResourceUrl {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }

  public ngOnInit(): void {
    this.userManegement
      .getDownloableFilesByProfileType(this.userType)
      .subscribe({
        next: (data) => {
          var json = JSON.stringify(data);
          var jsonParsed = JSON.parse(json);
          var usersTypes;
          var filesGroup;
          var file;
          for (usersTypes in jsonParsed) {
            for (filesGroup in jsonParsed[usersTypes]) {
              file = jsonParsed[usersTypes][filesGroup];
              if (
                file.type == 'exec' ||
                file.type == 'exe' ||
                file.type == 'msi' ||
                file.type == 'appxbundle'
              ) {
                this.downloadables.set(file.title, file);
              }
            }
          }
        },
        error: () => {},
      });
  }
}
