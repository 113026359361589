<body>
  <div class="report-container">
    <div class="reportInfoHeader">
      <div class="infoElement"><b i18n>ID</b> - {{ session.id }}</div>
      <div class="infoElement">
        <b i18n>Local ID - </b>{{ session.localId }}
      </div>
      <div class="infoElement">
        <b i18n>Remote ID - </b>{{ session.remoteId }}
      </div>
      <div class="infoElement"><b i18n>Status - </b>{{ session.status }}</div>
      <div class="infoElement"><b i18n>Start - </b>{{ session.start }}</div>
      <div class="infoElement"><b i18n>End - </b>{{ session.end }}</div>
    </div>
    <div class="reportEditButtons">
      <button
        *ngIf="notEnabled"
        class="cancelSaveReportButton"
        mat-flat-button
        color="primary"
        (click)="editReportFields()"
        i18n
      >
        Edit report
      </button>
      <div *ngIf="!notEnabled">
        <button
          class="cancelSaveReportButton"
          mat-flat-button
          color="primary"
          (click)="saveReportFields()"
          i18n
        >
          Save changes
        </button>
        <button
          class="cancelSaveReportButton"
          mat-flat-button
          color="primary"
          (click)="cancelEditReportFields()"
          i18n
        >
          Cancel and refresh
        </button>
        <button
          (click)="openAddMediaDialog()"
          class="cancelSaveReportButton"
          *ngIf="!notEnabled"
          mat-flat-button
          color="primary"
          i18n
        >
          Add media
        </button>
      </div>
    </div>

    <div class="report">
      <div class="reportTitle" i18n>Report</div>
      <div class="items" *ngIf="hasKey()">
        <div *ngFor="let item of reportJsonObjectMap | keyvalue">
          <div class="itemKey">{{ item.key }}</div>
          <mat-form-field
            class="itemValueFormField whiteTextInputForm"
            *ngIf="
              !checkItemValueTypeIMG(item.value) && !checkIfArray(item.key)
            "
          >
            <textarea
              [disabled]="notEnabled"
              class="itemValue"
              #itemValue
              matInput
              [(ngModel)]="item.value"
              cols="30"
              rows="5"
              (change)="updateValue(item.key, itemValue.value)"
            >
              {{ item.value }}
            </textarea>
          </mat-form-field>
          <div class="itemValueMedia" *ngIf="checkItemValueTypeIMG(item.value)">
            <mat-icon>download</mat-icon>
            <img
              src="{{ item.value }}"
              alt="itemImage"
              onclick="window.open('_blank')"
            />
          </div>
          <div class="divMedia" *ngIf="checkIfArray(item.key)"></div>
        </div>
        <div *ngIf="arrayOfImages.length != 0">
          <h3>
            <b i18n>Images</b>
          </h3>
          <div *ngFor="let image of arrayOfImages">
            <div class="iconAndMedia">
              <a class="imageLink" href="{{ image }}" target="_blank">
                <img src="{{ image }}" alt="" id="{{ image }}"
              /></a>

              <div class="downloadIcon">
                <a href="{{ image }}" download="{{ image }}">
                  <mat-icon class="downloadMatIcon">download</mat-icon></a
                >
              </div>
              <div
                class="deleteIcon"
                (click)="openDialog(image)"
                *ngIf="!notEnabled"
              >
                <mat-icon>delete</mat-icon>
              </div>
            </div>
          </div>
        </div>
        <div *ngIf="videoArray.length != 0" class="videoDiv">
          <h3>
            <b i18n>Videos</b>
          </h3>
          <div *ngFor="let video of videoArray">
            <div class="iconAndMedia">
              <mat-list role="list">
                <mat-list-item id="{{ video }}"
                  ><a href="{{ video }}">{{ video }}</a>
                  <div class="downloadIcon">
                    <a href="{{ video }}" download="{{ video }}">
                      <mat-icon class="downloadMatIcon">download</mat-icon></a
                    >
                  </div>
                  <div
                    class="deleteIcon"
                    (click)="openDialog(video)"
                    *ngIf="!notEnabled"
                  >
                    <mat-icon class="deleteMatIcon">delete</mat-icon>
                  </div>
                </mat-list-item>
              </mat-list>
            </div>
          </div>
        </div>
        <div *ngIf="arrayOfOtherMedia.length != 0" class="otherMediaDiv">
          <h3>
            <b i18n>Other media</b>
          </h3>
          <div *ngFor="let other of arrayOfOtherMedia">
            <div class="iconAndMedia">
              <mat-list role="list">
                <mat-list-item id="{{ other }}"
                  ><a href="{{ other }}">{{ other }}</a>
                  <div class="downloadIcon">
                    <a href="{{ other }}" download="{{ other }}">
                      <mat-icon class="downloadMatIcon">download</mat-icon></a
                    >
                  </div>
                  <div
                    class="deleteIcon"
                    (click)="openDialog(other)"
                    *ngIf="!notEnabled"
                  >
                    <mat-icon class="deleteMatIcon">delete</mat-icon>
                  </div>
                </mat-list-item>
              </mat-list>
            </div>
          </div>
        </div>
        <div class="addMediaDiv">
          <button
            (click)="openAddMediaDialog()"
            class="addMediaButton"
            *ngIf="!notEnabled"
            mat-flat-button
            color="primary"
            i18n
          >
            Add media
          </button>
        </div>
      </div>
      <div class="items" *ngIf="!hasKey()">
        <div class="itemKey" i18n>No report added</div>
      </div>
    </div>
  </div>
</body>
