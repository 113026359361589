import { MatDialogRef } from '@angular/material/dialog';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-error-delete-session-dialog',
  templateUrl: './error-delete-session-dialog.component.html',
  styleUrls: ['./error-delete-session-dialog.component.css'],
})
export class ErrorDeleteSessionDialogComponent implements OnInit {
  constructor(public dialog: MatDialogRef<ErrorDeleteSessionDialogComponent>) {}

  public errorDeleteSession: boolean;

  public closeDialog() {
    this.dialog.close();
  }

  ngOnInit(): void {}
}
