import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { map, Observable, startWith } from 'rxjs';
import { Department } from 'src/app/Interfaces/departmentInterface';
import { User } from 'src/app/Interfaces/userInterface';
import { UserDataApiService } from 'src/app/Services/UserDataService/user-data-api.service';
import { UserManagementAPIService } from 'src/app/Services/UserManagement/user-management-api.service';
import { UserSessionCheckerService } from 'src/app/Services/UserManagement/UserSessionChecker/user-session-checker.service';

import { ChangesUserDataDialogComponent } from './changes-user-data-dialog/changes-user-data-dialog.component';
import { ConfirmationChangeDataComponent } from './confirmation-change-data/confirmation-change-data.component';

@Component({
  selector: 'app-edit-user-profile-admin-dialog',
  templateUrl: './edit-user-profile-admin-dialog.component.html',
  styleUrls: ['./edit-user-profile-admin-dialog.component.css'],
})
export class EditUserProfileAdminDialogComponent implements OnInit {
  constructor(
    private dialogRef: MatDialogRef<EditUserProfileAdminDialogComponent>,
    public userDataApiService: UserDataApiService,
    public userSessionCheckerService: UserSessionCheckerService,
    public userManagementAPIService: UserManagementAPIService,
    private dialog: MatDialog
  ) {}
  public selected = '';
  public creationDate = '';
  public edit = false;
  public email = '';
  public name = '';
  public surname = '';

  public language = '';
  public SeletedLanguageInDB = '';

  public departments = new Array<Department>();
  public user: User;

  public form = new FormControl('');
  public departmentsOwner = new Array<Department>();
  public departmentsName = new Array<string>();
  public avaliableDepartmentsName = new Array<string>();
  public departmentsOption: Observable<string[]>;
  public changePersonalData = {
    email: '',
    name: '',
    usertype: '',
    surname: '',
    language: '',
    departments: Array<Department>(),
    deleteDepartments: Array<Department>(),
  };

  public userType = '';

  public cancel() {
    this.edit = false;
    (<HTMLInputElement>document.getElementById('nameInput')).value = this.name;
    (<HTMLInputElement>document.getElementById('surnameInput')).value =
      this.surname;
    (<HTMLInputElement>document.getElementById('languageInput')).value =
      this.language;

    this.changePersonalData.departments = [];
    this.changePersonalData.deleteDepartments.forEach((deleteDepart) => {
      this.departments.push(deleteDepart);
    });
    this.changePersonalData.deleteDepartments = [];
  }

  public addDepart(newDepart: string) {
    this.departmentsOwner.forEach((depart) => {
      if (depart.departmentName == newDepart)
        this.changePersonalData.departments.push(depart);
    });
    (<HTMLInputElement>document.getElementById('newDepartInput')).value = '';
    this.filterDepartmetsOptions();
  }

  public changeUserType(userType: string) {
    if (userType == 'NONE') {
      this.changePersonalData.usertype = $localize`None`;
    } else if (userType == 'ADMIN') {
      this.changePersonalData.usertype = $localize`Admin`;
    } else if (userType == 'USER_N1') {
      this.changePersonalData.usertype = $localize`Operator`;
    } else if (userType == 'USER_N2') {
      this.changePersonalData.usertype = $localize`Expert`;
    } else {
      this.changePersonalData.usertype = $localize`User type not setted`;
    }

    this.selected = userType;
  }

  public editProfile() {
    this.edit = true;
  }

  public getData() {
    this.userDataApiService.getUserData(this.user.username).subscribe({
      next: (data) => {
        var json = JSON.stringify(data);
        var jsonParsed = JSON.parse(json);

        console.log(jsonParsed);

        this.name =
          jsonParsed.name == 'None' ? $localize`Not defined` : jsonParsed.name;
        this.email = this.user.username;

        this.SeletedLanguageInDB = jsonParsed.language;

        this.surname =
          jsonParsed.surname == 'None'
            ? $localize`Not defined`
            : jsonParsed.surname;
        this.creationDate =
          jsonParsed.creationDate == 'None'
            ? $localize`Not defined`
            : jsonParsed.creationDate;

        this.userType = this.user.userType;

        var arrayParsed = jsonParsed.departments;

        var department;
        var field;

        /*
        for (department in arrayParsed) {
          for (field in arrayParsed[department]) {
            var newDepartment = <Department>{};
            newDepartment.departmentName =
              arrayParsed[department][field].departmentName;
            newDepartment.departmentDescription =
              arrayParsed[department][field].departmentDescription;
            newDepartment.iddepartment =
              arrayParsed[department][field].iddepartment;
            newDepartment.iddepartmentUser =
              arrayParsed[department][field].iddepartmentUser;
            this.departments.push(newDepartment);
          }
        }

        this.getDepartmentsOwn();

        */
      },
      error: (error) => {
        console.error('There was an error!', error);
      },
    });
  }

  public deleteDepart(dep: Department, newDepart: string) {
    if (newDepart == 'newDepart') {
      this.removeNewDepart(dep);
    } else {
      this.removeDepart(dep);
    }
    this.filterDepartmetsOptions();
  }

  private removeNewDepart(dep: Department) {
    var index = 0;
    this.changePersonalData.departments.forEach((department) => {
      if (department.iddepartment == dep.iddepartment) {
        this.changePersonalData.departments.splice(index, 1);
      }
      index++;
    });
  }

  private removeDepart(dep: Department) {
    var index = 0;
    this.departments.forEach((department) => {
      if (department.iddepartment == dep.iddepartment) {
        this.changePersonalData.deleteDepartments.push(department);
        this.departments.splice(index, 1);
      }
      index++;
    });
  }

  private getDepartmentsOwn() {
    this.userDataApiService.getDepartmentsOwner().subscribe({
      next: (result) => {
        var json = JSON.stringify(result);
        var jsonParsed = JSON.parse(json);

        var depart;

        for (depart in jsonParsed) {
          this.departmentsOwner.push(jsonParsed[depart]);
          this.departmentsName.push(jsonParsed[depart].departmentName);
        }
        this.filterDepartmetsOptions();
      },
      error: (err) => {
        console.error(err);
      },
    });
  }

  private filterDepartmetsOptions() {
    this.avaliableDepartmentsName = this.departmentsName.filter(
      (department) => !this.checkDepartmentNameRepeat(department)
    );

    this.departmentsOption = this.form.valueChanges.pipe(
      startWith(''),
      map((value) => this._filter(value || ''))
    );
  }

  public ngOnInit(): void {
    this.userSessionCheckerService.checkUserSession();

    this.getData();
  }

  private _filter(value: string): string[] {
    if (value != '') {
      const filterValue = value.toLowerCase();

      return this.avaliableDepartmentsName.filter((option) =>
        option.toLowerCase().includes(filterValue)
      );
    } else {
      return this.avaliableDepartmentsName;
    }
  }

  private saveName(nameInput: string) {
    this.changePersonalData.name = nameInput;
  }

  private saveSurname(surnameInput: string) {
    this.changePersonalData.surname = surnameInput;
  }

  private saveLanguage(languageInput: string) {
    this.changePersonalData.language = languageInput;
  }

  private saveDepartments() {
    this.changePersonalData.departments.forEach((depart) => {
      this.departments.push(depart);
    });
  }

  private saveChanges(name: string, surname: string, language: string) {
    this.saveName(name);
    this.saveSurname(surname);
    this.saveLanguage(language);
  }

  public updateValues(name: string, surname: string, language: string) {
    this.saveChanges(name, surname, language);
    var currentUserInfo = {
      name: '',
      surname: '',
      language: '',
    };

    currentUserInfo.name = this.name;
    currentUserInfo.surname = this.surname;
    currentUserInfo.language = this.language;

    var dialogRef = this.dialog.open(ChangesUserDataDialogComponent, {
      width: '30em',
      height: 'auto',
    });

    dialogRef.componentInstance.changePersonalData = this.changePersonalData;
    dialogRef.componentInstance.currentUserData = currentUserInfo;

    dialogRef.afterClosed().subscribe((result) => {
      if (result.edit) {
        //Save changes in edit profile
        this.surname = surname;
        this.name = name;
        this.language = language;
        this.saveDepartments();

        //Save the idds in a new Array to delete or conect the departments with users in DB
        var newDepartmentsId = new Array<string>();
        var deleteDepartmentsId = new Array<string>();
        this.changePersonalData.departments.forEach((dep) => {
          newDepartmentsId.push(dep.iddepartment);
        });

        this.changePersonalData.deleteDepartments.forEach((dep) => {
          deleteDepartmentsId.push(dep.iddepartmentUser);
        });

        //Change data in DB
        this.userDataApiService
          .updateCurrentUserData(
            this.user.username,
            this.changePersonalData.name,
            this.changePersonalData.surname,
            newDepartmentsId,
            deleteDepartmentsId,
            this.changePersonalData.language
          )
          .subscribe({
            next: () => {
              this.edit = false;
              this.changePersonalData.name = '';
              this.changePersonalData.surname = '';
              this.changePersonalData.language = '';
              this.changePersonalData.deleteDepartments = [];
              this.changePersonalData.departments = [];
              this.filterDepartmetsOptions();
              var dialogRef = this.dialog.open(
                ConfirmationChangeDataComponent,
                {
                  width: '30em',
                  height: '10em',
                }
              );
              dialogRef.componentInstance.changeUserDataSuccess = true;
            },
            error: (error) => {
              console.error('There was an error!', error);
              var dialogRef = this.dialog.open(
                ConfirmationChangeDataComponent,
                {
                  width: '30em',
                  height: '10em',
                }
              );
              dialogRef.componentInstance.changeUserDataSuccess = false;
            },
          });
      }
    });
  }

  public closeDialog() {
    this.dialogRef.close();
  }

  private checkDepartmentNameRepeat(name: string) {
    var exist = false;
    this.departmentsOwner.forEach((element) => {
      if (element.departmentName == name) {
        exist = this.checkDepartmentRepeat(element);
      }
    });

    return exist;
  }

  private checkDepartmentOwner(depart: Department): boolean {
    return this.departmentsOwner.includes(depart);
  }

  public checkDepartmentAvailable(newDepart: string) {
    var depart = <Department>{};
    this.departmentsOwner.forEach((element) => {
      if (element.departmentName == newDepart) {
        depart = element;
      }
    });

    if (!this.checkDepartmentOwner(depart)) {
      return false;
    } else if (this.checkDepartmentRepeat(depart)) {
      return false;
    } else {
      return true;
    }
  }

  private checkDepartInDepartmentUsers(depart: Department): boolean {
    var exist = false;

    this.departments.forEach((element) => {
      if (element.iddepartment == depart.iddepartment) {
        exist = true;
      }
    });

    return exist;
  }

  private checkDepartmentRepeat(depart: Department): boolean {
    return (
      this.checkDepartInDepartmentUsers(depart) ||
      this.changePersonalData.departments.includes(depart)
    );
  }
}
