import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-deactivate-user-mfaconfirmation-response',
  templateUrl: './deactivate-user-mfaconfirmation-response.component.html',
  styleUrls: ['./deactivate-user-mfaconfirmation-response.component.css'],
})
export class DeactivateUserMFAConfirmationResponseComponent implements OnInit {
  hasBeenDeactivated: boolean;

  constructor(public dialog: MatDialog) {}

  ngOnInit(): void {}

  public closeDialog() {
    this.dialog.closeAll();
  }
}
