<div class="container">
  <div class="content">
    <div class="qrCode">
      <qrcode
        *ngIf="qrUrl != null"
        [qrdata]="qrUrl.toString()"
        [width]="256"
        [errorCorrectionLevel]="'M'"
      ></qrcode>
    </div>
    <div class="qrCodePlaceholder" *ngIf="qrUrl == null">
      <div class="loader"></div>
    </div>
    <div class="infoText" i18n>
      Scan the QR code to proceed with the download.
    </div>
    <div class="buttonDiv">
      <button mat-raised-button i18n (click)="closeDialog()">
        <mat-icon>close</mat-icon>Close
      </button>
    </div>
  </div>
</div>
