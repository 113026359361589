<div (keyup.enter)="close()" class="content">
  <Label class="tokenLabel" *ngIf="changeUserDataSuccess == true" i18n
    >Personal data successfully changed.
  </Label>
  <Label class="tokenLabel" *ngIf="changeUserDataSuccess != true" i18n
    >Personal data could not be changed, please try again later.
  </Label>
</div>
<div>
  <button class="confirmButton" mat-button (click)="close()" i18n>Close</button>
</div>
