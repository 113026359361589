import { Component, OnInit } from '@angular/core';
import { SessionsManagementAPIService } from '../../../../../Services/CallsManagement/sessions-management-api.service';
import { HttpClient, HttpEventType } from '@angular/common/http';
import { FormControl } from '@angular/forms';

import { UserSessionCheckerService } from '../../../../../Services/UserManagement/UserSessionChecker/user-session-checker.service';
import '@angular/localize/init';
import { MatDialog } from '@angular/material/dialog';
import { environment } from 'src/environments/environment';
import { ReplaceFileConfirmationComponent } from './replace-file-confirmation/replace-file-confirmation.component';

@Component({
  selector: 'app-add-media',
  templateUrl: './add-media.component.html',
  styleUrls: ['./add-media.component.css'],
})
export class AddMediaComponent implements OnInit {
  constructor(
    private sessionsManagementAPIService: SessionsManagementAPIService,
    private http: HttpClient,
    private dialog: MatDialog,
    private userSessionCheckerService: UserSessionCheckerService
  ) {}

  public color = 'primary';
  public environment = environment.name;
  public file = new FormControl('');
  public globalFile: File;
  public isfileUploading = false;
  public maxSize = 100;
  public mediaArray = new Array<string>();
  public mode = 'determinate';
  public overhead = false;
  public progress: any;
  public reportJsonObject: any = {};
  public reportJsonObjectMap = new Map<string, string>();
  public value = 0;
  public values = '';

  public cancelUploadFile() {
    this.dialog.closeAll();
  }

  public checkIfArray(key: string): boolean {
    var keyValue = this.reportJsonObjectMap.get(key);
    var keyValueAny: string | any = keyValue;

    if (keyValueAny instanceof Array) {
      return true;
    } else {
      return false;
    }
  }

  public ngOnInit(): void {
    var userSessionState = this.checkUserSession();
    var element = document.getElementById('maxFileSize');
    if (userSessionState == false) {
      this.dialog.closeAll();
    }
  }

  public onFileSelected(event) {
    const file: File = event.target.files[0];
    var sizeInMB = file.size * 0.000001;

    this.overhead = false;
    if (file.size) {
      if (sizeInMB < this.maxSize) {
        this.globalFile = file;
      } else {
        this.overhead = true;
      }
    }
  }

  public uploadFile() {
    this.values = '';

    this.sessionsManagementAPIService
      .getSessions('', '', '', '', '')
      .subscribe({
        next: (data: string) => {
          var json = JSON.stringify(data);
          var jsonParsed = JSON.parse(json);

          var sessionId = window.localStorage.getItem('sessionId')!;
          //obtenemos el objeto report del json

          this.reportJsonObject = JSON.parse(jsonParsed[sessionId].report);

          //obtenemos el array de media del objeto report y la metemos en un KVP
          for (var key in this.reportJsonObject) {
            this.reportJsonObjectMap.set(key, this.reportJsonObject[key]);
          }

          this.values = '';
          this.reportJsonObjectMap.forEach((value, key) => {
            if (this.checkIfArray(key)) {
              this.values = value;
            }
          });

          //split values variable by comma and push to a new variable
          var valuesArray = this.values.toString().split(',');
          this.mediaArray = [];
          valuesArray.forEach((element) => {
            this.mediaArray.push(element);
          });

          //metemos el nuevo fichero en la array

          var fileToAdd =
            'https://wallbox-web-' +
            this.environment +
            '.s3.amazonaws.com/WallboxSessionReports/' +
            sessionId +
            '/' +
            this.globalFile.name;

          if (this.mediaArray.includes(fileToAdd)) {
            var dialogRef = this.dialog.open(ReplaceFileConfirmationComponent, {
              height: '200px',
              width: '500px',
            });
            dialogRef.componentInstance.fileName = this.globalFile.name;

            dialogRef.afterClosed().subscribe((confirm) => {
              if (confirm) {
                this.addMedia(fileToAdd, sessionId);
              }
            });
          } else {
            this.addMedia(fileToAdd, sessionId);
          }
        },
        error: (error) => {
          console.error('There was an error!', error);
        },
      });
  }

  private addMedia(file: string, sessionId: string) {
    this.isfileUploading = true;
    this.mediaArray.push(file);

    //asignamos el valor a la array de media del objeto report
    for (var i = 0; i < Object.keys(this.reportJsonObject).length; i++) {
      if (Object.values(this.reportJsonObject)[i] instanceof Array) {
        var aux = Object.values(this.reportJsonObject)[i] as Array<string>;

        if (!aux.includes(file)) {
          aux.push(file);
        }

        Object.values(this.reportJsonObject)[i] = aux;
        break;
      }
    }

    //lo metemos en el KVP y lo mandamos a la API para que se actualice en la BD
    for (var key in this.reportJsonObject) {
      this.reportJsonObjectMap.set(key, this.reportJsonObject[key]);
    }

    this.sessionsManagementAPIService
      .updateSessionReport(sessionId, this.reportJsonObjectMap)
      .subscribe({
        next: () => {},
        error: (error) => {
          console.error('There was an error!', error);
        },
      });
    this.sessionsManagementAPIService.saveFile(this.globalFile.name).subscribe({
      next: (data: string) => {
        const formData = new FormData();

        var json = JSON.stringify(data);
        var jsonParsed = JSON.parse(json);

        var sessionId = window.localStorage.getItem('sessionId')!;

        formData.append(
          'key',
          'WallboxSessionReports/' + sessionId + '/' + this.globalFile.name
        );

        Object.keys(jsonParsed.fields).forEach((key) => {
          if (key == 'x-amz-algorithm') {
            formData.append('x-amz-algorithm', jsonParsed.fields[key]);
          }
          if (key == 'x-amz-credential') {
            formData.append('x-amz-credential', jsonParsed.fields[key]);
          }
          if (key == 'x-amz-date') {
            formData.append('x-amz-date', jsonParsed.fields[key]);
          }
          if (key == 'x-amz-security-token') {
            formData.append('x-amz-security-token', jsonParsed.fields[key]);
          }
          if (key == 'policy') {
            formData.append('policy', jsonParsed.fields[key]);
          }
          if (key == 'x-amz-signature') {
            formData.append('x-amz-signature', jsonParsed.fields[key]);
          }
        });

        var fileExtOrig = this.globalFile.name.split('.').pop();
        //Pasar a minúscula
        var fileExt = fileExtOrig!.toLowerCase();

        if (
          fileExt == 'jpg' ||
          fileExt == 'png' ||
          fileExt == 'jpeg' ||
          fileExt == 'gif' ||
          fileExt == 'bmp' ||
          fileExt == 'tiff' ||
          fileExt == 'svg' ||
          fileExt == 'jfif'
        ) {
          formData.append('Content-Type', 'image/' + fileExtOrig);
        } else if (
          fileExt == 'mp4' ||
          fileExt == 'avi' ||
          fileExt == 'mov' ||
          fileExt == 'wmv' ||
          fileExt == 'flv' ||
          fileExt == 'mkv'
        ) {
          formData.append('Content-Type', 'video/' + fileExtOrig);
        } else {
          formData.append('Content-Type', 'binary/octet-stream');
        }

        formData.append('file', this.globalFile);

        const upload = this.http.post<string>(jsonParsed.url, formData, {
          reportProgress: true,
          observe: 'events',
        });

        upload.subscribe({
          next: (data) => {
            if (data.type === HttpEventType.UploadProgress) {
              this.progress = Math.round((100 * data.loaded) / data.total!);
              this.value = this.progress;
            } else if (data.type == HttpEventType.Response) {
              window.location.reload();
            }
          },
          error: (err) => {
            console.error(err);
            console.error(err.error.message);
          },
        });
      },
      error: (error) => {
        console.error('There was an error!', error);
      },
    });
  }

  private checkFileName(fileToAdd: string): boolean {
    var check;
    if (this.mediaArray.includes(fileToAdd)) {
      var dialogRef = this.dialog.open(ReplaceFileConfirmationComponent);
      dialogRef.componentInstance.fileName = fileToAdd;

      dialogRef.afterClosed().subscribe((confirm) => {
        if (!confirm) {
          check = false;
        }
      });
    }
    return check;
  }

  private checkUserSession(): boolean {
    this.userSessionCheckerService.checkUserSession();
    return this.userSessionCheckerService.check;
  }
}
