import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { UserManagementAPIService } from '../../Services/UserManagement/user-management-api.service';
import { OverlayContainer } from '@angular/cdk/overlay';
import { UserSessionCheckerService } from 'src/app/Services/UserManagement/UserSessionChecker/user-session-checker.service';
import { AcQRLoginComponent } from '../login/ac-qrlogin/ac-qrlogin.component';
import { UserDataApiService } from 'src/app/Services/UserDataService/user-data-api.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent implements OnInit {
  constructor(
    private dialog: MatDialog,
    private router: Router,
    private userManagementAPIService: UserManagementAPIService,
    private _overlayContainer: OverlayContainer,
    private checkUser: UserSessionCheckerService,
    private userDataAPIService: UserDataApiService
  ) {}
  currentYear: number = new Date().getFullYear();
  public currentLanguage = '';

  public isToggle = false;
  public isCheckedDarkModeToggle = false;
  themes: string[] = ['Light', 'Dark', 'Mix'];
  themeSelected: string;
  public token = '';
  public userCapabilities: string[] = JSON.parse(
    localStorage.getItem('userCapabilities')
  );

  public username: string = window.localStorage.getItem('SessionUsername')!;

  public changeLanguage(language: 'Spanish' | 'English'): void {
    localStorage.setItem('WebLanguage', language);

    if (language === 'English') {
      window.location.href =
        environment.WebLanguages.English + '/dashboard/home';
    }
    if (language === 'Spanish') {
      window.location.href =
        environment.WebLanguages.Spanish + '/dashboard/home';
    }
  }

  public changeTheme(theme: 'theme-alternate' | 'theme' | 'theme-mix'): void {
    // remove old theme class and add new theme class
    const overlayContainerClasses =
      this._overlayContainer.getContainerElement().classList;

    const body = document.getElementById('body');

    const themeClassesToRemove = Array.from(overlayContainerClasses).filter(
      (item: string) => item.includes('theme-')
    );
    if (themeClassesToRemove.length) {
      overlayContainerClasses.remove(...themeClassesToRemove);
      body?.classList.remove(...themeClassesToRemove);
    }
    overlayContainerClasses.add(theme);
    body?.classList.add(theme);

    this.userManagementAPIService.theme = theme;
  }

  public checkIfUserHasMFA(): boolean {
    var accestoken = window.localStorage.getItem('AccessToken');

    return accestoken != null ? true : false;
  }

  public falseToggle(): void {
    this.isToggle = false;
  }

  public logout(): void {
    window.localStorage.removeItem('Authorization');
    window.localStorage.removeItem('AccessToken');
    window.localStorage.removeItem('userCapabilities');
    this.checkUser.check = false;
    this.router.navigate(['/login']);
  }

  public ngOnInit(): void {
    this.currentLanguage = localStorage.getItem('WebLanguage')!;

    var theme = localStorage.getItem('theme');

    if (theme != null || theme != undefined) {
      this.changeTheme(theme as 'theme-alternate' | 'theme' | 'theme-mix');

      if (theme == 'theme') {
        this.isCheckedDarkModeToggle = false;
        this.themeSelected = 'Light';
      }

      if (theme == 'theme-mix') {
        this.isCheckedDarkModeToggle = false;
        this.themeSelected = 'Mix';
      }

      if (theme == 'theme-alternate') {
        this.isCheckedDarkModeToggle = true;
        this.themeSelected = 'Dark';
      }
    } else {
      this.themeSelected = 'Light';
    }
  }

  public openHoloLensAccessDialog(): void {
    this.dialog.open(AcQRLoginComponent, {
      height: '35em',
      width: '30em',
    });
  }

  public removeAUTH(): void {
    window.localStorage.removeItem('Authorization');
  }

  public trueToggle(): void {
    this.isToggle = false;
  }

  public userHasNoMFAaler() {
    alert('Only users with MFA enabled can change the password');
  }
}
