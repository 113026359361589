import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { map, Observable, startWith } from 'rxjs';
import { DeactivateUserMFAConfirmationComponent } from './deactivate-user-mfaconfirmation/deactivate-user-mfaconfirmation.component';

@Component({
  selector: 'app-deactivate-user-mfa',
  templateUrl: './deactivate-user-mfa.component.html',
  styleUrls: ['./deactivate-user-mfa.component.css'],
})
export class DeactivateUserMFAComponent implements OnInit {
  constructor(public dialog: MatDialog) {}

  form = new FormControl('');
  options: string[] = [];
  filteredOptions: Observable<string[]>;

  ngOnInit(): void {
    this.filteredOptions = this.form.valueChanges.pipe(
      startWith(''),
      map((value) => this._filter(value || ''))
    );
  }
  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.options.filter((option) =>
      option.toLowerCase().includes(filterValue)
    );
  }

  showConfirmationDialog(username: string) {
    this.dialog.closeAll();

    var confirmationDialog = this.dialog.open(
      DeactivateUserMFAConfirmationComponent,
      {
        height: '15em',
        width: '30em',
      }
    );

    confirmationDialog.componentInstance.username = username;
  }

  public cancelUser2FADeactivation() {
    this.dialog.closeAll();
  }
}
