<body class="departmentBodyComponent">
  <div class="container">
    <div class="filter-buttons">
      <button
        *ngIf="!removeMode"
        mat-flat-button
        class="filterButton"
        color="primary"
        (click)="addDepartment()"
        i18n
      >
        <mat-icon>add</mat-icon> <label> Add department </label>
      </button>
      <button
        *ngIf="!removeMode"
        mat-flat-button
        class="filterButton"
        color="primary"
        (click)="removeMode = true"
        i18n
      >
        <mat-icon>delete</mat-icon> <label> Remove department</label>
      </button>
      <button
        *ngIf="removeMode"
        mat-flat-button
        class="filterButton"
        color="primary"
        (click)="removeSelected()"
        i18n
      >
        <mat-icon>delete_forever</mat-icon> <label> Remove </label>
      </button>
      <button
        *ngIf="removeMode"
        mat-flat-button
        class="filterButton"
        color="primary"
        (click)="removeMode = false"
        (click)="setAll(false)"
        i18n
      >
        <mat-icon>clear</mat-icon> <label> Cancel </label>
      </button>

      <mat-checkbox
        *ngIf="removeMode"
        class="matCheckBox"
        [checked]="allSelected"
        (change)="setAll($event.checked)"
        >Select all</mat-checkbox
      >
    </div>

    <div class="card-container">
      <div class="cards" *ngFor="let card of cards | async">
        <mat-card
          [ngClass]="card.selected ? 'matCardSelected' : 'matCard'"
          [id]="card.iddepartment"
        >
          <mat-card-header class="matCardHeader">
            <div class="textHeaderDiv">
              <mat-card-title>{{ card.title }}</mat-card-title>
            </div>
            <div class="selectorDiv">
              <mat-checkbox
                *ngIf="removeMode"
                class="selector"
                color="primary"
                [(ngModel)]="card.selected"
                (ngModelChange)="updateAllComplete()"
              ></mat-checkbox>
            </div>
          </mat-card-header>
          <div class="matCardContentDiv">
            <mat-card-content class="matCardContent">
              {{ card.text }}

              <mat-divider></mat-divider>
            </mat-card-content>
          </div>

          <mat-card-actions class="matCardActions">
            <button
              mat-raised-button
              color="primary"
              class="matButton matButtonAddUser"
              (click)="addNewUserToDepartment(card.iddepartment)"
            >
              <mat-icon class="addUserMatIcon">person_add_alt_1</mat-icon
              ><label>Add user</label>
            </button>
            <button
              class="matButtonMenu"
              mat-raised-button
              color="primary"
              [matMenuTriggerFor]="menu"
            >
              <mat-icon>more_horiz</mat-icon>
            </button>
            <mat-menu #menu="matMenu" class="departmentCardMenu">
              <button
                mat-menu-item
                (click)="openDepartmentUserList(card.iddepartment)"
              >
                <mat-icon>people</mat-icon>
                <span>View users</span>
              </button>
              <button
                (click)="removeUsersFromDepartments(card.iddepartment)"
                mat-menu-item
              >
                <mat-icon>person_remove</mat-icon>
                <span>Remove user</span>
              </button>
            </mat-menu>
          </mat-card-actions>
        </mat-card>
      </div>
    </div>
    <div class="paginatorDiv">
      <mat-paginator
        class="paginator"
        [pageSizeOptions]="[6, 10, 20]"
        [pageIndex]=""
        showFirstLastButtons
        aria-label="Select page of department"
      >
      </mat-paginator>
    </div>
  </div>
</body>
