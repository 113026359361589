<div class="container">
  <div class="content">
    <div class="info">
      <label *ngIf="departmentsRemoved" i18n
        >Departments successfully removed</label
      >
      <label *ngIf="!departmentsRemoved" i18n
        >Sorry we could not remove the selected departments, try again
        later</label
      >
    </div>
    <div class="buttonDiv">
      <button mat-raised-button i18n (click)="close()">Close</button>
    </div>
  </div>
</div>
