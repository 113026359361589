<html>
  <head></head>
  <body>
    <div class="container hidden" id="hiddenContainer">
      <div class="buttonsHead">
        <button
          mat-button
          [routerLinkActive]="['active']"
          [routerLink]="['./personal-data']"
          class="navigationButton"
          color="primary"
          i18n
        >
          Personal data
        </button>
        <button
          mat-button
          [routerLinkActive]="['active']"
          [routerLink]="['./security']"
          class="navigationButton"
          color="primary"
          i18n
        >
          Security
        </button>
      </div>
      <div class="profileContent">
        <router-outlet class="routerOutlet"></router-outlet>
      </div>
    </div>
  </body>
</html>
