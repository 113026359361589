<div (keyup.enter)="closeDialog()" class="content">
  <Label class="tokenLabel" *ngIf="errorDeleteSession == true" i18n
    >Sessions removed successfully
  </Label>
  <Label class="tokenLabel" *ngIf="errorDeleteSession != true" i18n
    >The sessions could not be removed, please try again
  </Label>
</div>
<div>
  <button class="confirmButton" mat-button (click)="closeDialog()" i18n>
    Back
  </button>
</div>
