<div class="container">
  <div class="content">
    <label class="usernameLabel" i18n
      >Are you sure you want to deactivate 2FA for the user:
      <b>{{ username }}</b> ?</label
    >
    <div class="buttonDiv">
      <button
        mat-raised-button
        color="primary"
        (click)="confirmUser2FADeactivation()"
        i18n
      >
        Confirm
      </button>
      <button
        mat-raised-button
        color="error"
        (click)="cancelUser2FADeactivation()"
        i18n
      >
        Cancel
      </button>
    </div>
  </div>
</div>
