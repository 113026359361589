<div class="titleChange">
  <label i18n
    >Change password: <b>{{ username }}</b>
  </label>
</div>
<mat-form-field class="example-full-width whiteTextInputForm" appearance="fill">
  <mat-label i18n>New password</mat-label>
  <input
    [formControl]="newPassword"
    matInput
    type="password"
    required
    autocomplete="new-password" /></mat-form-field
><label
  class="errorMessage"
  *ngIf="cmpNewPassword() == 0 && newPassword.value != ''"
  >{{ getNewPasswordError() }}</label
><mat-form-field
  class="example-full-width whiteTextInputForm"
  appearance="fill"
>
  <mat-label i18n>Introduce the password again</mat-label>
  <input
    [formControl]="newRepeatedPassword"
    matInput
    (keydown.enter)="
      cmpNewPassword() != 0 && newRepeatedPassword.value == newPassword.value
    "
    type="password"
    required
    autocomplete="new-password" /></mat-form-field
><label class="errorMessage">{{ repeatPasswordError() }}</label
><br /><label i18n>
  Must be at least 8 characters long, with numbers, letters and symbols</label
><button
  [disabled]="
    cmpNewPassword() == 0 || newRepeatedPassword.value != newPassword.value
  "
  class="confirmButton"
  *ngIf="!this.spinner"
  mat-button
  (click)="confirm(newPassword.value)"
  i18n
>
  Confirm
</button>
<div *ngIf="this.spinner" class="loaderContent"><div class="loader"></div></div>
<div>
  <button
    *ngIf="!this.spinner"
    class="confirmButton"
    mat-button
    (click)="cancelChangeUserPass()"
    i18n
  >
    Cancel
  </button>
</div>
