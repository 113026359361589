import { Component, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { UserManagementAPIService } from '../../../Services/UserManagement/user-management-api.service';
import { MfaLoginConfirmationDialogComponent } from './mfa-login-confirmation-dialog/mfa-login-confirmation-dialog.component';

@Component({
  selector: 'app-login-mfadialog',
  templateUrl: './login-mfadialog.component.html',
  styleUrls: ['./login-mfadialog.component.css'],
})
export class LoginMFADialogComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<LoginMFADialogComponent>,
    public dialog: MatDialog,
    private userManagementAPIService: UserManagementAPIService
  ) {}

  public mfaCode = new FormControl('', [
    Validators.required,
    Validators.minLength(6),
    Validators.maxLength(6),
  ]);

  public onlyNumbersCode(code: string): boolean {
    var mfaWithoutNumbers = code.replace(/[0-9]/gm, '');
    if (mfaWithoutNumbers.length == 0) {
      return true;
    } else {
      return false;
    }
  }

  public getErrorMessagge(code: FormControl) {
    if (!this.onlyNumbersCode(code.value)) {
      return 'Please, enter a only number code';
    } else {
      return 'Your code is not valid.';
    }
  }

  public confirm(mfa: string) {
    this.userManagementAPIService.mfaLogin(mfa).subscribe({
      next: (data) => {
        var json = JSON.stringify(data);
        var jsonParsed = JSON.parse(json);
        var token = jsonParsed.token;

        window.localStorage.setItem('Authorization', 'Bearer ' + token);
        window.localStorage.setItem('AccessToken', jsonParsed.AccessToken);
        window.localStorage.setItem('RefreshToken', jsonParsed.RefreshToken);

        this.dialogRef.close();
      },
      error: () => {
        this.dialog.open(MfaLoginConfirmationDialogComponent, {
          width: '300px',
          height: '300px',
        });
      },
    });
  }

  ngOnInit(): void {}
}
