import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class DepartmentApiService {
  constructor(private http: HttpClient) {}

  private departmentId = new BehaviorSubject('0');
  currentDepartmentId = this.departmentId.asObservable();

  updateDepartmentId(message: string) {
    this.departmentId.next(message);
  }

  public getDepartments(): Observable<string> {
    var token = window.localStorage.getItem('Authorization')!;
    var headers = new HttpHeaders().set('Authorization', token);

    var response = this.http.post<string>(
      environment.URLgetdepartments,
      {
        username: localStorage.getItem('SessionUsername'),
      },
      { headers: headers }
    );

    return response;
  }

  public deleteDepartments(ids: string[]): Observable<string> {
    var token = window.localStorage.getItem('Authorization')!;
    var headers = new HttpHeaders().set('Authorization', token);

    var response = this.http.post<string>(
      environment.URLdeletedepartments,
      {
        departments: ids,
      },
      { headers: headers }
    );

    return response;
  }

  public addDepartment(
    departmentName: string,
    departmentDescription: string
  ): Observable<string> {
    var token = window.localStorage.getItem('Authorization')!;
    var headers = new HttpHeaders().set('Authorization', token);

    var response = this.http.post<string>(
      environment.URLaddnewdepartment,
      {
        username: localStorage.getItem('SessionUsername'),
        departmentName: departmentName,
        departmentDescription: departmentDescription,
      },
      { headers: headers }
    );

    return response;
  }

  public removeUserFromDepartment(departmentUsersID: Array<string>) {
    var token = window.localStorage.getItem('Authorization')!;
    var headers = new HttpHeaders().set('Authorization', token);

    var response = this.http.post<string>(
      environment.URLremoveuserfromdepartment,
      {
        departments: departmentUsersID,
      },
      { headers: headers }
    );

    return response;
  }

  public addUserToDepartment(username: string, departmentId: string[]) {
    var token = window.localStorage.getItem('Authorization')!;
    var headers = new HttpHeaders().set('Authorization', token);

    console.log(username);
    console.log(departmentId);

    var response = this.http.post<string>(
      environment.URLaddusertodepartment,
      {
        username: username,
        departments: departmentId,
      },
      { headers: headers }
    );

    return response;
  }

  public getUserListFromDepartment(departmentId: string) {
    var token = window.localStorage.getItem('Authorization')!;
    var headers = new HttpHeaders().set('Authorization', token);

    var response = this.http.post<string>(
      environment.URLgetuserlistfromdepartment,
      {
        iddepartment: departmentId,
      },
      { headers: headers }
    );

    return response;
  }
}
