import { Component, OnInit, OnDestroy } from '@angular/core';
import { UserSessionCheckerService } from '../../../Services/UserManagement/UserSessionChecker/user-session-checker.service';
import {
  dashboardCards,
  DashboardCard,
} from '../../../Interfaces/dashboardCard';
import '@angular/localize/init';
import { AcQRLoginComponent } from '../../login/ac-qrlogin/ac-qrlogin.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css'],
})
export class HomeComponent implements OnInit, OnDestroy {
  constructor(
    private userSessionCheckerService: UserSessionCheckerService,
    private dialog: MatDialog
  ) {}

  private userSessionCheckerServiceSubscription: any;
  public userCapabilities: string[] = JSON.parse(
    localStorage.getItem('userCapabilities')
  );
  public visibleCards: DashboardCard[] = [];

  ngOnDestroy(): void {
    this.userSessionCheckerServiceSubscription.unsubscribe();
  }

  public openHoloLensAccessDialog(): void {
    this.dialog.open(AcQRLoginComponent, {
      height: '35em',
      width: '30em',
    });
  }

  public callMethod(methodCall: string): void {
    if (methodCall == 'openHoloLensAccessDialog()') {
      this.openHoloLensAccessDialog();
    }
  }

  public ngOnInit(): void {
    this.userSessionCheckerService.checkUserSession();

    this.userSessionCheckerServiceSubscription =
      this.userSessionCheckerService.currentUserStatus.subscribe((status) => {
        if (status == true) {
          document.getElementById('hiddenContainer').classList.remove('hidden');

          this.visibleCards = dashboardCards.filter(
            (card) =>
              this.userCapabilities.includes(card.capability) ||
              card.capability == ''
          );

          console.log(this.visibleCards);
        }
      });
  }
}
