import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { NewDepartmentConfirmationComponent } from './new-department-confirmation/new-department-confirmation.component';
import { DepartmentApiService } from 'src/app/Services/DepartmentManagement/department-api.service';

@Component({
  selector: 'app-new-department',
  templateUrl: './new-department.component.html',
  styleUrls: ['./new-department.component.css'],
})
export class NewDepartmentComponent implements OnInit {
  constructor(
    public dialog: MatDialog,
    public departmentApiService: DepartmentApiService
  ) {}

  ngOnInit(): void {}

  public closeDialog() {
    this.dialog.closeAll();
  }

  public addDepartment(departmentName: string, departmentDescription: string) {
    this.departmentApiService
      .addDepartment(departmentName, departmentDescription)
      .subscribe({
        next: () => {
          this.closeDialog();

          var confirmationDialog = this.dialog.open(
            NewDepartmentConfirmationComponent,
            {
              width: '25em',
              height: '12em',
            }
          );

          confirmationDialog.componentInstance.departmentCreated = true;
        },
        error: () => {
          var confirmationDialog = this.dialog.open(
            NewDepartmentConfirmationComponent,
            {
              width: '25em',
              height: '12em',
            }
          );

          confirmationDialog.componentInstance.departmentCreated = false;
        },
      });
  }
}
