import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
@Component({
  selector: 'app-change-password-confirmation-dialog',
  templateUrl: './change-password-confirmation-dialog.component.html',
  styleUrls: ['./change-password-confirmation-dialog.component.css'],
})
export class ChangePasswordConfirmationDialogComponent implements OnInit {
  confirm() {
    this.dialog.closeAll();
  }
  public passwordChanged: boolean;
  public incorrectPassword: boolean;
  public tooManyAttempts: boolean;
  constructor(public dialog: MatDialog) {}
  ngOnInit(): void {}
}
