import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-confirmation-change-data',
  templateUrl: './confirmation-change-data.component.html',
  styleUrls: ['./confirmation-change-data.component.css'],
})
export class ConfirmationChangeDataComponent implements OnInit {
  constructor(
    private dialogRef: MatDialogRef<ConfirmationChangeDataComponent>
  ) {}
  public changeUserDataSuccess: boolean;

  public close() {
    this.dialogRef.close();
  }

  ngOnInit(): void {}
}
