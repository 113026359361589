<body class="faqsBodyComponent">
  <div class="container">
    <mat-accordion>
      <mat-expansion-panel hideToggle>
        <mat-expansion-panel-header>
          <mat-panel-title i18n class="matPanelTitle">
            This is the expansion title
          </mat-panel-title>
          <mat-panel-description i18n class="matPanelDescription">
            This is a summary of the content
          </mat-panel-description>
        </mat-expansion-panel-header>
        <p i18n>This is the primary content of the panel.</p>
      </mat-expansion-panel>
      <mat-expansion-panel hideToggle>
        <mat-expansion-panel-header>
          <mat-panel-title i18n class="matPanelTitle">
            This is the expansion title
          </mat-panel-title>
          <mat-panel-description i18n class="matPanelDescription">
            This is a summary of the content
          </mat-panel-description>
        </mat-expansion-panel-header>
        <p i18n>This is the primary content of the panel.</p>
      </mat-expansion-panel>
      <mat-expansion-panel hideToggle>
        <mat-expansion-panel-header>
          <mat-panel-title i18n class="matPanelTitle">
            This is the expansion title
          </mat-panel-title>
          <mat-panel-description i18n class="matPanelDescription">
            This is a summary of the content
          </mat-panel-description>
        </mat-expansion-panel-header>
        <p i18n>This is the primary content of the panel.</p>
      </mat-expansion-panel>
      <mat-expansion-panel hideToggle>
        <mat-expansion-panel-header>
          <mat-panel-title i18n class="matPanelTitle">
            This is the expansion title
          </mat-panel-title>
          <mat-panel-description i18n class="matPanelDescription">
            This is a summary of the content
          </mat-panel-description>
        </mat-expansion-panel-header>
        <p i18n>This is the primary content of the panel.</p>
      </mat-expansion-panel>
      <mat-expansion-panel hideToggle>
        <mat-expansion-panel-header>
          <mat-panel-title i18n class="matPanelTitle">
            This is the expansion title
          </mat-panel-title>
          <mat-panel-description i18n class="matPanelDescription">
            This is a summary of the content
          </mat-panel-description>
        </mat-expansion-panel-header>
        <p i18n>This is the primary content of the panel.</p>
      </mat-expansion-panel>
    </mat-accordion>
  </div>
</body>
