<body>
  <div>
    <div class="textContent">
      <label i18n
        >A file with the name <b>"{{ this.fileName }}"</b> already exists.
      </label>
      <label i18n> Do you want to replace it?</label>
    </div>
    <div class="buttonsDiv">
      <div>
        <button
          (click)="close(true)"
          mat-button
          color="primary"
          class="returnButton"
          i18n
        >
          Yes
        </button>
      </div>
      <div>
        <button
          (click)="close(false)"
          mat-flat-button
          color="warn"
          class="returnButton"
          i18n
        >
          No
        </button>
      </div>
    </div>
  </div>
</body>
