<div (keyup.enter)="closeDialog()" class="content">
  <Label class="tokenLabel" *ngIf="addUserSuccess == true" i18n
    >User added successfully.<br />An email has been sent to the user with a
    temporary password. Please ask the user to login to finish the registration
    process.
  </Label>
  <Label class="tokenLabel" *ngIf="addUserSuccess != true" i18n
    >Sorry, the user could not be added.
  </Label>
</div>
<div>
  <button
    class="confirmButton"
    mat-button
    (click)="closeDialog()"
    *ngIf="addUserSuccess != true"
    i18n
  >
    Back
  </button>
  <button
    class="confirmButton"
    mat-button
    (click)="closeDialogAndReload()"
    *ngIf="addUserSuccess == true"
    i18n
  >
    Back
  </button>
</div>
