import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-invalid-password',
  templateUrl: './invalid-password.component.html',
  styleUrls: ['./invalid-password.component.css'],
})
export class InvalidPasswordComponent implements OnInit {
  confirm() {
    this.dialog.close();
  }

  constructor(public dialog: MatDialogRef<InvalidPasswordComponent>) {}

  ngOnInit(): void {}
}
