<div class="content">
  <label
    *ngIf="mfaIsEnabled && !errorInActivation && !errorInPassword"
    class="messageLabel"
    i18n
    >The two factor authentication has been activated for this account</label
  >
  <label
    *ngIf="!mfaIsEnabled && !errorInActivation && !errorInPassword"
    class="messageLabel"
    i18n
    >The two factor authentication has been deactivated for this account</label
  >

  <label *ngIf="errorInActivation" class="messageLabel" i18n
    >An error has occurred while activating the two factor authentication (the
    provided code may be wrong). Please, try again</label
  >

  <label *ngIf="errorInPassword" class="messageLabel" i18n
    >Incorrect password</label
  >

  <button mat-raised-button color="primary" (click)="closeDialog()" i18n>
    Go back
  </button>
</div>
