import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import * as CryptoJS from 'crypto-js';
import { Department } from 'src/app/Interfaces/departmentInterface';

@Injectable({
  providedIn: 'root',
})
export class UserDataApiService {
  constructor(private http: HttpClient) {}

  public getCurrentUserData(name?: string): Observable<string> {
    var token = window.localStorage.getItem('Authorization')!;
    var headers = new HttpHeaders().set('Authorization', token);

    var response = this.http.post<string>(
      environment.URLgetcurrentuserdata,
      {
        username: localStorage.getItem('SessionUsername'),
      },
      { headers: headers }
    );

    return response;
  }

  public getUserData(name: string): Observable<string> {
    var token = window.localStorage.getItem('Authorization')!;
    var headers = new HttpHeaders().set('Authorization', token);

    var response = this.http.post<string>(
      environment.URLgetcurrentuserdata,
      {
        username: name,
      },
      { headers: headers }
    );

    return response;
  }

  public getDepartmentsOwner() {
    var token = window.localStorage.getItem('Authorization')!;
    var headers = new HttpHeaders().set('Authorization', token);

    var response = this.http.post<string>(
      environment.URLgetdepartments,
      {
        username: localStorage.getItem('SessionUsername'),
      },
      { headers: headers }
    );

    return response;
  }

  public updateCurrentUserData(
    username: string,
    name: string,
    surname: string,
    newDepartments: Array<string>,
    removeDepartments: Array<string>,
    language: string
  ): Observable<string> {
    var token = window.localStorage.getItem('Authorization')!;
    var headers = new HttpHeaders().set('Authorization', token);

    localStorage.setItem('UserLanguage', language);
    localStorage.setItem('WebLanguage', language);

    var response = this.http.post<string>(
      environment.URLupdatecurrentuserdata,
      {
        username: username,
        name: name,
        surname: surname,
        newDepartments: newDepartments,
        removeDepartments: removeDepartments,
        language: language,
      },
      { headers: headers }
    );

    return response;
  }

  public getUserQRAccessCode(): Observable<string> {
    var token = window.localStorage.getItem('Authorization')!;
    var headers = new HttpHeaders().set('Authorization', token);

    var response = this.http.post<string>(
      environment.URLgetcurrentuseraccessqrcode,
      {
        username: localStorage.getItem('SessionUsername'),
      },
      { headers: headers }
    );

    return response;
  }

  public updateUserQRAccessCode(
    username: string,
    accessQrCode: string
  ): Observable<string> {
    var headers = new HttpHeaders().set('X-Api-Key', environment.apiKey);

    var response = this.http.post<string>(
      environment.URLupdatecurrentuseraccessqrcode,
      {
        username: username,
        accessQrCode: accessQrCode,
      },
      { headers: headers }
    );

    return response;
  }

  rnd = (() => {
    const gen = (min, max) =>
      max++ &&
      [...Array(max - min)].map((s, i) => String.fromCharCode(min + i));

    const sets = {
      num: gen(48, 57),
      alphaLower: gen(97, 122),
      alphaUpper: gen(65, 90),
      special: [...`~!@#$%^&*()_+-=[]{}|;:'",./<>?`],
    };

    function* iter(len, set) {
      if (set.length < 1) set = Object.values(sets).flat();
      for (let i = 0; i < len; i++) yield set[(Math.random() * set.length) | 0];
    }

    return Object.assign(
      (len, ...set) => [...iter(len, set.flat())].join(''),
      sets
    );
  })();

  public encrypt(key, value: string) {
    key = CryptoJS.enc.Utf8.parse(key);
    //var iv = CryptoJS.enc.Utf8.parse(this.rnd(16));
    var iv = CryptoJS.enc.Utf8.parse('$D.Aasnhtefjscf%');
    var ciphertext = CryptoJS.AES.encrypt(value, key, { iv: iv }).toString();
    return ciphertext;
  }
}
