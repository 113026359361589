<div (keyup.enter)="backToUserList()" class="content">
  <Label class="tokenLabel" *ngIf="disableUserSuccess == true" i18n
    >Selected user(s) have been successfully disabled
  </Label>
  <Label class="tokenLabel" *ngIf="disableUserSuccess != true" i18n
    >Sorry, selected users could not be disabled from the user list.
  </Label>
</div>
<div>
  <button class="confirmButton" mat-button (click)="backToUserList()" i18n>
    Back
  </button>
</div>
