<div class="container">
  <mat-form-field class="formField">
    <mat-label i18n>Code generated in Authenticator APP</mat-label>
    <input
      matInput
      placeholder="Generated code"
      i18n-placeholder="Generated code placeholder"
      #code
    />
  </mat-form-field>
  <mat-form-field class="formField">
    <mat-label i18n>Name of the device with the app</mat-label>
    <input
      matInput
      placeholder="Friendly name for the device"
      i18n-placeholder="Friendly name for the device placeholder"
      #friendlyname
    />
  </mat-form-field>
  <div class="buttonsContainer">
    <button
      mat-button
      color="primary"
      (click)="verifyUser(code.value, friendlyname.value)"
      i18n
    >
      Confirm
    </button>
    <button mat-button color="error" (click)="closeDialog()" i18n>
      Cancel
    </button>
  </div>
</div>
