<html>
  <body>
    <img
      src="assets/img/skleLogos/sklePrivacyPolicyLogo.png"
      class="skleLogo"
    />
    <div class="container">
      <div class="content">
        <div class="title">Política de privacidad y protección de datos</div>
        <div class="subregionTitle">Politica de privacidad</div>
        <div class="textContent">
          <p>
            Tu privacidad es importante para nosotros. En esta declaración de
            privacidad se explica qué datos personales recopilamos de nuestros
            usuarios y cómo los utilizamos. Te animamos a leer detenidamente
            estos términos antes de facilitar tus datos personales en esta web.
            Los mayores de 14 años podrán registrarse en
            https://skylife-eng.com/ como usuarios sin el previo consentimiento
            de sus padres o tutores.
          </p>
          <p>
            En el caso de los menores de 14 años se requiere el consentimiento
            de los padres o tutores para el tratamiento de sus datos personales.
          </p>
          <p>
            En ningún caso se recabarán del menor de edad datos relativos a la
            situación profesional, económica o a la intimidad de los otros
            miembros de la familia, sin el consentimiento de éstos.
          </p>
          <p>
            Si eres menor de 14 años y has accedido a este sitio web sin avisar
            a tus padres no debes registrarte como usuario.
          </p>
          <p>
            En esta web se respetan y cuidan los datos personales de los
            usuarios. Como usuario debes saber que tus derechos están
            garantizados.
          </p>
          <p>
            Nos hemos esforzado en crear un espacio seguro y confiable y por eso
            queremos compartir nuestros principios respecto a tu privacidad:
          </p>

          <ul>
            <li>
              Nunca solicitamos información personal a menos que realmente sea
              necesaria para prestar los servicios que nos requieras.
            </li>
            <li>
              Nunca compartimos información personal de nuestros usuarios con
              nadie, excepto para cumplir con la ley o en caso que contemos con
              tu autorización expresa.
            </li>
            <li>
              Nunca utilizaremos tus datos personales con una finalidad
              diferente a la expresada en esta política de privacidad.
            </li>
          </ul>

          <p>
            Es preciso advertir que esta Política de Privacidad podría variar en
            función de exigencias legislativas o de autorregulación, por lo que
            se aconseja a los usuarios que la visiten periódicamente. Será
            aplicable en caso de que los usuarios decidan rellenar algún
            formulario de cualquiera de sus formularios de contacto donde se
            recaben datos de carácter personal.
          </p>
          <p>
            Skylife Engineering S.L ha adecuado esta web a las exigencias del
            Reglamento (UE) 2016/679 del Parlamento Europeo y del Consejo de 27
            de abril de 2016 relativo a la protección de las personas físicas
            (RGPD), la Ley Orgánica 3/2018, de 5 de diciembre, de Protección de
            Datos Personales y garantía de los derechos digitales (LOPDGDD), así
            como con la Ley 34/2002, de 11 de julio, de Servicios de la Sociedad
            de la Información y Comercio Electrónico (LSSICE o LSSI).
          </p>
          <p class="subregionTitle">
            Responsable del tratamiento de tus datos personales
          </p>

          <ul>
            <li>
              <label class="listElementTitle">Identidad del Responsable:</label>
              Skylife Engineering S.L
            </li>
            <li>
              <label class="listElementTitle">Nombre comercial:</label> Skylife
              Engineering S.L
            </li>
            <li><label class="listElementTitle">NIF/CIF:</label> B91936997</li>
            <li>
              <label class="listElementTitle">Dirección:</label>
              Calle Américo Vespucio 5 Bloque 1 Local A 8-12. 41092 Sevilla
              (España)
            </li>
            <li>
              <label class="listElementTitle">Correo electrónico:</label>
              skylife@skylife-eng.com
            </li>
            <li>
              <label class="listElementTitle">Actividad:</label>
              Empresa de Ingeniería: Desarrollo de Software y Hardware
            </li>
            <li>
              Inscrita en el Registro Mercantil de Sevilla, en el tomo 5370,
              folio 63, hoja SE-89533, el 03-05-2011
            </li>
          </ul>

          <p>
            A efectos de lo previsto en el Reglamento General de Protección de
            Datos antes citado, los datos personales que nos envíes a través de
            los formularios de la web, recibirán el tratamiento de datos de
            “Usuarios de la web y suscriptores”.
          </p>
          <p>
            Para el tratamiento de datos de nuestros usuarios, implementamos
            todas las medidas técnicas y organizativas de seguridad establecidas
            en la legislación vigente.
          </p>
          <p class="subregionTitle">
            Principios que aplicaremos a tu información personal
          </p>
          <p>
            En el tratamiento de tus datos personales, aplicaremos los
            siguientes principios que se ajustan a las exigencias del nuevo
            reglamento europeo de protección de datos:
          </p>

          <ul>
            <li>
              <label class="listElementTitle">
                Principio de licitud, lealtad y transparencia:</label
              >
              Siempre vamos a requerir tu consentimiento para el tratamiento de
              tus datos personales para uno o varios fines específicos que te
              informaremos previamente con absoluta transparencia.
            </li>
            <li>
              <label class="listElementTitle"
                >Principio de minimización de datos:</label
              >
              Solo vamos a solicitar datos estrictamente necesarios en relación
              con los fines para los que los requerimos. Los mínimos posibles.
            </li>
            <li>
              <label class="listElementTitle"
                >Principio de limitación del plazo de conservación:</label
              >
              los datos serán mantenidos durante no más tiempo del necesario
              para los fines del tratamiento, en función a la finalidad, te
              informaremos del plazo de conservación correspondiente, en el caso
              de suscripciones, periódicamente revisaremos nuestras listas y
              eliminaremos aquellos registros inactivos durante un tiempo
              considerable.
            </li>
            <li>
              <label class="listElementTitle"
                >Principio de integridad y confidencialidad:</label
              >
              Tus datos serán tratados de tal manera que se garantice una
              seguridad adecuada de los datos personales y se garantice
              confidencialidad. Debes saber que tomamos todas las precauciones
              necesarias para evitar el acceso no autorizado o uso indebido de
              los datos de nuestros usuarios por parte de terceros.
            </li>
          </ul>

          <p class="subregionTitle">¿Cómo hemos obtenido tus datos?</p>
          <p>
            Los datos personales que tratamos en Skylife Engineering S.L
            proceden de:
          </p>
          <p class="subregionTitle">
            ¿Cuáles son tus derechos cuando nos facilitas tus datos?
          </p>
          <p>
            Cualquier persona tiene derecho a obtener confirmación sobre si en
            Skylife Engineering S.L estamos tratando datos personales que me
            concierne, o no.
          </p>
          <p>Las personas interesadas tienen derecho a:</p>

          <ul>
            <li>
              Solicitar el acceso a los datos personales relativos al interesado
            </li>
            <li>Solicitar su rectificación o supresión</li>
            <li>Solicitar la limitación de su tratamiento</li>
            <li>Oponerse al tratamiento</li>
            <li>Solicitar la portabilidad de los datos</li>
          </ul>

          <p>
            Los interesados podrán acceder a sus datos personales, así como a
            solicitar la rectificación de los datos inexactos o, en su caso,
            solicitar su supresión cuando, entre otros motivos, los datos ya no
            sean necesarios para los fines que fueron recogidos. En determinadas
            circunstancias, los interesados podrán solicitar la limitación del
            tratamiento de sus datos, en cuyo caso únicamente los conservaré
            para el ejercicio o la defensa de reclamaciones.
          </p>
          <p>
            En determinadas circunstancias y por motivos relacionados con su
            situación particular, los interesados podrán oponerse al tratamiento
            de sus datos. Skylife Engineering S.L dejará de tratar los datos,
            salvo por motivos legítimos imperiosos, o el ejercicio o la defensa
            de posibles reclamaciones. Como interesado, tienes derecho a recibir
            los datos personales que te incumban, que nos hayas facilitado y en
            un formato estructurado, de uso común y lectura mecánica, y a
            transmitirlos a otro responsable del tratamiento cuando:
          </p>

          <ul>
            <li>El tratamiento está basado en el consentimiento</li>
            <li>Los datos hayan sido facilitados por la persona interesada.</li>
            <li>El tratamiento se efectúe por medios automatizados.</li>
          </ul>

          <p>
            Al ejercer tu derecho a la portabilidad de los datos, tendrás
            derecho a que los datos personales se transmitan directamente de
            responsable a responsable cuando sea técnicamente posible.
          </p>
          <p>
            Los interesados también tendrán derecho a la tutela judicial
            efectiva y a presentar una reclamación ante la autoridad de control,
            en este caso, la Agencia Española de Protección de Datos, si
            consideran que el tratamiento de datos personales que le conciernen
            infringe el Reglamento.
          </p>
          <p class="subregionTitle">
            ¿Con qué finalidad tratamos tus datos personales?
          </p>
          <p>
            Cuando un usuario se conecta con esta web por ejemplo para comentar
            un post, mandar un correo al titular, suscribirse o realizar alguna
            contratación, está facilitando información de carácter personal de
            la que es responsable Skylife Engineering S.L. Esa información puede
            incluir datos de carácter personal como pueden ser tu dirección IP,
            nombre, dirección física, dirección de correo electrónico, número de
            teléfono, y otra información. Al facilitar esta información, el
            usuario da su consentimiento para que su información sea recopilada,
            utilizada, gestionada y almacenada por https://skylife-eng.com/,
            sólo como se describe en el Aviso Legal y en la presente Política de
            Privacidad.
          </p>
          <p>
            En Skylife Engineering S.L existen diferentes sistemas de captura de
            información personal y trato la información que nos facilitan las
            personas interesadas con el siguiente fin por cada sistema de
            captura (formularios).
          </p>
          <p>
            Existen otras finalidades por las que tratamos tus datos personales:
          </p>

          <ul>
            <li>
              Para garantizar el cumplimiento de las condiciones de uso y la ley
              aplicable. Esto puede incluir el desarrollo de herramientas y
              algoritmos que ayudan a esta web a garantizar la confidencialidad
              de los datos personales que recoge.
            </li>
            <li>Para apoyar y mejorar los servicios que ofrece esta web.</li>
            <li>
              También se recogen otros datos no identificativos que se obtienen
              mediante algunas cookies que se descargan en el ordenador del
              usuario cuando navega en esta web que detallo en la política de
              cookies.
            </li>
            <li>
              Para gestionar las redes sociales. Skylife Engineering S.L puede
              tener presencia en redes sociales. El tratamiento de los datos que
              se lleve a cabo de las personas que se hagan seguidoras en las
              redes sociales de las páginas oficiales de
              https://skylife-eng.com/, se regirá por este apartado. Así como
              por aquellas condiciones de uso, políticas de privacidad y
              normativas de acceso que pertenezcan a la red social que proceda
              en cada caso y aceptadas previamente por el usuario de Skylife
              Engineering S.L. Tratará sus datos con las finalidades de
              administrar correctamente su presencia en la red social,
              informando de actividades, productos o servicios de Skylife
              Engineering S.L. Así como para cualquier otra finalidad que las
              normativas de las redes sociales permitan. En ningún caso
              utilizaremos los perfiles de seguidores en redes sociales para
              enviar publicidad de manera individual.
            </li>
          </ul>

          <p>
            De acuerdo a lo establecido en el reglamento general de protección
            de datos europeo (RGPD) 2016/679, Skylife Engineering S.L (Skylife
            Engineering S.L) con domicilio en Calle Américo Vespucio 5 Bloque 1
            Local A 8-12. 41092 Sevilla (España), será responsable del
            tratamiento de los datos correspondientes a Usuarios de la web y
            suscriptores.
          </p>
          <p>
            Skylife Engineering S.L, no vende, alquila ni cede datos de carácter
            personal que puedan identificar al usuario, ni lo hará en el futuro,
            a terceros sin el consentimiento previo. Sin embargo, en algunos
            casos se pueden realizar colaboraciones con otros profesionales, en
            esos casos, se requerirá consentimiento a los usuarios informando
            sobre la identidad del colaborador y la finalidad de la
            colaboración. Siempre se realizará con los más estrictos estándares
            de seguridad. Podemos seleccionar y publicar testimonios de clientes
            en nuestros Servicios, que pueden contener información de
            identificación personal, como su nombre y / o la ciudad, estado o
            país en el que vive. Obtendremos su consentimiento antes de publicar
            cualquier testimonio con su nombre. Si se publica dicho contenido,
            estará disponible para otros usuarios de los Servicios. Si deseas
            que eliminemos o borremos tu nombre o testimonio de nuestra web,
            puedes comunicarte con https://skylife-eng.com/ en nuestro
            formulario de contacto.
          </p>
          <p>
            Podemos publicar reseñas de los clientes en nuestras web o
            Servicios, que pueden contener información de identificación
            personal, como tu nombre y / o la ciudad, estado o país en el que
            vive. Al enviar su opinión nos estás dando permiso para publicar tu
            opinión en nuestros Sitios o Servicios. Si publicas dicho contenido,
            estará disponible para otros usuarios de los Sitios o Servicios. Su
            publicación también puede hacerse pública y no podemos evitar que
            dicha información se use de una manera que pueda violar esta
            Política o la ley. Si desea que eliminemos tu nombre o revisión de
            nuestros Sitios o Servicios, puedes comunicarte con
            https://skylife-eng.com/ en nuestro formulario de contacto.
          </p>
          <p class="subregionTitle">
            Legitimación para el tratamiento de tus datos
          </p>
          <p>
            La base legal para el tratamiento de sus datos es: el
            consentimiento.
          </p>
          <p>
            Para contactar o realizar comentarios en esta web se requiere el
            consentimiento con esta política de privacidad.
          </p>
          <p>
            La oferta prospectiva o comercial de productos y servicios está
            basada en el consentimiento que se le solicita, sin que en ningún
            caso la retirada de este consentimiento condicione la ejecución del
            contrato de suscripción.
          </p>
          <p class="subregionTitle">Categoría de datos</p>
          <p>
            Las categorías de datos que se tratan son datos identificativos.
          </p>
          <p>No se tratan categorías de datos especialmente protegidos.</p>
          <p class="subregionTitle">
            ¿Por cuánto tiempo conservaremos tus datos?
          </p>
          <p>Los datos personales proporcionados se conservarán:</p>

          <ul>
            <li>Mientras se mantenga la relación mercantil.</li>
            <li>Hasta que se solicite su supresión por el interesado</li>
            <li>
              Periodo a partir de la última confirmación de interés: 2 años.
            </li>
          </ul>

          <p class="subregionTitle">
            ¿A qué destinatarios se comunicarán tus datos?
          </p>
          <p>
            No está prevista ninguna comunicación de datos personales a terceros
            salvo, si fuese necesario para el desarrollo y ejecución de las
            finalidades del tratamiento, a nuestros proveedores de servicios
            relacionados con comunicaciones, con los cuales el RESPONSABLE tiene
            suscritos los contratos de confidencialidad y de encargado de
            tratamiento exigidos por la normativa vigente de privacidad.
          </p>
          <p>
            Salvo obligación legal, solo se comunicarán sus datos a las
            siguientes categorías de destinatarios:
          </p>

          <ul>
            <li>
              Juzgados y Tribunales, así como otros posibles órganos de
              resolución de conflictos.
            </li>
            <li>Fuerzas y Cuerpos de Seguridad del Estado.</li>
            <li>Notarios y registradores.</li>
          </ul>

          <p class="subregionTitle">Navegación</p>
          <p>
            Al navegar por https://skylife-eng.com/ se pueden recoger datos no
            identificables, que pueden incluir, direcciones IP, ubicación
            geográfica (aproximadamente), un registro de cómo se utilizan los
            servicios y sitios, y otros datos que no pueden ser utilizados para
            identificar al usuario. Entre los datos no identificativos están
            también los relacionados a tus hábitos de navegación a través de
            servicios de terceros. Esta web utiliza los siguientes servicios de
            análisis de terceros:
          </p>

          <ul>
            <li>Google analytics</li>
            <li>Twitter analytics</li>
            <li>Semrush</li>
          </ul>

          <p>
            Utilizamos esta información para analizar tendencias, administrar el
            sitio, rastrear los movimientos de los usuarios alrededor del sitio
            y para recopilar información demográfica sobre nuestra base de
            usuarios en su conjunto.
          </p>
          <p class="subregionTitle">Secreto y seguridad de los datos</p>
          <p>
            https://skylife-eng.com/ se compromete en el uso y tratamiento de
            los datos incluidos personales de los usuarios, respetando su
            confidencialidad y a utilizarlos de acuerdo con la finalidad del
            mismo, así como a dar cumplimiento a su obligación de guardarlos y
            adaptar todas las medidas para evitar la alteración, pérdida,
            tratamiento o acceso no autorizado, de conformidad con lo
            establecido en la normativa vigente de protección de datos.
          </p>
          <p>
            Esta web incluye un certificado SSL. Se trata de un protocolo de
            seguridad que hace que tus datos viajen de manera íntegra y segura,
            es decir, la transmisión de los datos entre un servidor y usuario
            web, y en retroalimentación, es totalmente cifrada o encriptada.
          </p>
          <p>
            https://skylife-eng.com/ no puede garantizar la absoluta
            inexpugnabilidad de la red Internet y por tanto la violación de los
            datos mediante accesos fraudulentos a ellos por parte de terceros.
          </p>
          <p>
            Con respecto a la confidencialidad del procesamiento, Skylife
            Engineering S.L se asegurará de que cualquier persona que esté
            autorizada por Skylife Engineering S.L para procesar los datos del
            cliente (incluido su personal, colaboradores y prestadores), estará
            bajo la obligación apropiada de confidencialidad (ya sea un deber
            contractual o legal).
          </p>
          <p>
            Cuando se presente algún incidente de seguridad, al darse cuenta
            Skylife Engineering S.L, deberá notificar al Cliente sin demoras
            indebidas y deberá proporcionar información oportuna relacionada con
            el Incidente de Seguridad tal como se conozca o cuando el Cliente lo
            solicite razonablemente.
          </p>
          <p class="subregionTitle">Exactitud y veracidad de los datos</p>
          <p>
            Como usuario, eres el único responsable de la veracidad y corrección
            de los datos que remitas a https://skylife-eng.com/ exonerando a
            Skylife Engineering S.L (Skylife Engineering S.L), de cualquier
            responsabilidad al respecto.
          </p>
          <p>
            Los usuarios garantizan y responden, en cualquier caso, de la
            exactitud, vigencia y autenticidad de los datos personales
            facilitados, y se comprometen a mantenerlos debidamente
            actualizados. El usuario acepta proporcionar información completa y
            correcta en el formulario de contacto o suscripción.
          </p>
          <p class="subregionTitle">Aceptación y consentimiento</p>
          <p>
            El usuario declara haber sido informado de las condiciones sobre
            protección de datos de carácter personal, aceptando y consintiendo
            el tratamiento de los mismos por parte de Skylife Engineering S.L
            (Skylife Engineering S.L) en la forma y para las finalidades
            indicadas en esta política de privacidad.
          </p>
          <p class="subregionTitle">Revocabilidad</p>
          <p>
            El consentimiento prestado, tanto para el tratamiento como para la
            cesión de los datos de los interesados, es revocable en cualquier
            momento comunicándolo a Skylife Engineering S.L (Skylife Engineering
            S.L) en los términos establecidos en esta Política para el ejercicio
            de los derechos. Esta revocación en ningún caso tendrá carácter
            retroactivo.
          </p>
          <p class="subregionTitle">Cambios en la política de privacidad</p>
          <p>
            Skylife Engineering S.L se reserva el derecho a modificar la
            presente política para adaptarla a novedades legislativas o
            jurisprudenciales, así como a prácticas de la industria. En dichos
            supuestos, Skylife Engineering S.L anunciará en esta página los
            cambios introducidos con razonable antelación a su puesta en
            práctica.
          </p>
          <p class="subregionTitle">Correos comerciales</p>
          <p>
            De acuerdo con la LSSICE, https://skylife-eng.com/ no realiza
            prácticas de SPAM, por lo que no envía correos comerciales por vía
            electrónica que no hayan sido previamente solicitados o autorizados
            por el usuario. En consecuencia, en cada uno de los formularios
            habidos en la web, el usuario tiene la posibilidad de dar su
            consentimiento expreso para recibir el boletín, con independencia de
            la información comercial puntualmente solicitada.
          </p>
          <p>
            Conforme a lo dispuesto en la Ley 34/2002 de Servicios de la
            Sociedad de la Información y de comercio electrónico,
            https://skylife-eng.com/ se compromete a no enviar comunicaciones de
            carácter comercial sin identificarlas debidamente.
          </p>
          <p class="subregionTitle">
            Dudas o consultas sobre esta política o tus derechos
          </p>
          <p>
            Si tienes alguna pregunta o comentario sobre esta política, o si
            tienes alguna inquietud sobre la forma en que se ha podido gestionar
            alguna información personal que nos hayas proporcionado, puedes
            contactarnos por correo postal o correo electrónico a:
            skylife@skylife-eng.com
          </p>
          <p>Documento revisado el 02-11-2021.</p>
          <button
            mat-flat-button
            color="primary"
            class="goBackButton"
            (click)="goBack()"
            i18n
          >
            Go back
          </button>
        </div>
      </div>
    </div>
  </body>
</html>
