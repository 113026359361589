import {
  Component,
  HostListener,
  OnInit,
  ViewChild,
  OnDestroy,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { CustomMatPaginatorIntl } from 'src/app/custom-mat-paginator-int';
import { User } from 'src/app/Interfaces/userInterface';
import { UserSessionCheckerService } from 'src/app/Services/UserManagement/UserSessionChecker/user-session-checker.service';
import { UserManagementAPIService } from '../../../../../Services/UserManagement/user-management-api.service';
import { SessionsDatePickerFilterDialogComponent } from '../../../sessions/sessions-date-picker-filter-dialog/sessions-date-picker-filter-dialog.component';
import { UserTypeNotAllowedDialogComponent } from '../../../../common/common-error-dialogs/user-type-not-allowed-dialog/user-type-not-allowed-dialog.component';

import { ChangeUserPassComponent } from '../../modification/admin-change-user-pass/admin-change-user-pass.component';

@Component({
  selector: 'app-show-disabled-dialog',
  templateUrl: './show-disabled-users.component.html',
  styleUrls: ['./show-disabled-users.component.css'],
})
export class ShowDisabledUsersDialogComponent implements OnInit, OnDestroy {
  constructor(
    public userManagementAPIService: UserManagementAPIService,
    public dialog: MatDialog,
    public router: Router,
    private userSessionCheckerService: UserSessionCheckerService
  ) {}

  private userSessionCheckerServiceSubscription: any;

  @ViewChild(CustomMatPaginatorIntl)
  public customPaginator: CustomMatPaginatorIntl;
  @ViewChild(MatPaginator) public paginator: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;

  public EmptyUserList: Array<User> = [];
  public dataSource = new MatTableDataSource<User>();
  public disable = true;
  public disableUserList = new Array<string>();
  public displayedColumns: string[] = [
    'username',
    'creationDate',
    'userType',
    'activateUser',
  ];
  public displayedColumnsInitial: string[] = [
    'username',
    'creationDate',
    'userType',
    'activateUser',
  ];
  public endDate = '';
  public endDateFormated = '';
  public filterLabels = new Map<string, string>([
    [$localize`Username`, 'username'],
    [$localize`User Type`, 'userType'],
  ]);
  public filterOptionList: string[] = [
    $localize`Username`,
    $localize`User Type`,
  ];
  public filterOptions = new FormControl<string[]>([$localize`Username`]);
  public filterOptionsByDefault: string[] = [$localize`Username`];
  public filterSelect = '';
  public filtering = false;
  public filteringByStartDate = false;
  public filteringDate = '';
  public isFiltered = false;
  public removing = false;
  public smallScreenMode = false;
  public startDate = '';
  public startDateFormated = '';
  public token = '';
  public userCapabilities: string[] = JSON.parse(
    localStorage.getItem('userCapabilities')
  );
  public userList: Array<User> = [];
  public userNameList: Array<string> = [];

  @HostListener('window:resize', ['$event'])
  public onResize(event: UIEvent) {
    const w = event.target as Window;
    this.setTableColumnsDependingOnWidth(w.innerWidth);
  }

  public applyFilter(event: Event) {
    this.filteringByStartDate = false;
    this.filtering = false;

    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.data = [];
    var userListFiltered: Array<User> = [];
    this.filterOptions.value?.forEach((element) => {
      var filterId = this.filterLabels.get(element) as string;
      filterId ??= '';
      this.userList.filter((user) => {
        if (
          user[filterId].toLowerCase().includes(filterValue) &&
          !userListFiltered.includes(user)
        ) {
          userListFiltered.push(user);
        }
      });
    });
    this.generateDataSource(userListFiltered);
  }

  public backToUserManagement() {
    this.router.navigateByUrl('dashboard/user-management');
  }

  public filterByStartDates() {
    var dialogRef = this.dialog.open(SessionsDatePickerFilterDialogComponent, {
      height: '250px',
      width: '400px',
    });

    dialogRef.afterClosed().subscribe((result) => {
      var date = Object.values(result);

      this.startDate = date[0] as string;
      this.endDate = date[1] as string;

      this.startDateFormated = this.startDate.replace('T', ' ');
      this.endDateFormated = this.endDate.replace('T', ' ');

      var cancel = date[2] as string;

      if (!cancel) {
        var userListFiltered = this.userList.filter((user) => {
          var startDateParsed = new Date(this.startDate);
          var endDateParsed = new Date(this.endDate);
          var userStartDateParsed = new Date(user.creationDate);
          if (
            userStartDateParsed.getTime() >= startDateParsed.getTime() &&
            userStartDateParsed.getTime() <= endDateParsed.getTime()
          ) {
            return true;
          }

          return false;
        });
        this.generateDataSource(userListFiltered);

        this.filtering = true;
        this.filteringByStartDate = true;

        this.dataSource.filterPredicate = function (record, filter) {
          const matchFilter: boolean[] = [];
          const filters = JSON.parse(filter);
          filters.forEach((element: { id: string; value: string }) => {
            const val = record[element.id] === null ? '' : record[element.id];
            matchFilter.push(
              val.toLowerCase().includes(element.value.toLowerCase())
            );
          });
          return matchFilter.every(Boolean);
        };
      }
    });
  }

  public getDisabledUserList() {
    this.userManagementAPIService.getUserList('', '', '', '', '').subscribe({
      next: (data) => {
        var json = JSON.stringify(data);
        var jsonParsed = JSON.parse(json);
        var type: string;

        this.userList = [];
        this.dataSource.data = [];

        for (var key of Object.keys(jsonParsed['Users'])) {
          var user = <User>{};

          if (jsonParsed['Users'][key].disabled == 1) {
            user.username = jsonParsed['Users'][key].Username;
            user.creationDate = jsonParsed['Users'][key].creationDate;
            type = jsonParsed['Users'][key].userType;

            if (type == 'NONE') {
              user.userType = $localize`None`;
            } else if (type == 'ADMIN') {
              user.userType = $localize`Admin`;
            } else if (type == 'USER_N1') {
              user.userType = $localize`Operator`;
            } else if (type == 'USER_N2') {
              user.userType = $localize`Expert`;
            } else if (type == '') {
              user.userType = $localize`User type not setted`;
            }

            this.userList.push(user);
          }
        }
        this.userList.forEach((element) => {
          this.dataSource.data.push(element);
        });

        this.dataSource = new MatTableDataSource<User>(this.dataSource.data);

        this.paginator._intl = this.customPaginator;
        this.customPaginator.setTotalItems(this.userList.length);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;

        this.dataSource.filterPredicate = function (record, filter) {
          const matchFilter: boolean[] = [];
          const filters = JSON.parse(filter);
          filters.forEach((element: { id: string; value: string }) => {
            const val = record[element.id] === null ? '' : record[element.id];
            matchFilter.push(
              val.toLowerCase().includes(element.value.toLowerCase())
            );
          });
          return matchFilter.every(Boolean);
        };
      },
      error: (error) => {
        console.error('There was an error!', error);
      },
    });
  }

  public ngOnDestroy(): void {
    this.userSessionCheckerServiceSubscription.unsubscribe();
  }

  public ngOnInit(): void {
    this.userSessionCheckerService.checkUserSession();

    this.userSessionCheckerServiceSubscription =
      this.userSessionCheckerService.currentUserStatus.subscribe((status) => {
        if (status == true) {
          document.getElementById('hiddenContainer').classList.remove('hidden');
          this.token = window.localStorage.getItem('Authorization')!;

          this.setTableColumnsDependingOnWidth(window.innerWidth);

          if (!this.userCapabilities.includes('VU')) {
            console.log('SHOW DISABLED USER: User type not allowed');
            var dialogRef = this.dialog.open(
              UserTypeNotAllowedDialogComponent,
              {
                height: '15em',
                width: '40em',
              }
            );

            dialogRef.afterClosed().subscribe(() => {
              this.router.navigate(['/dashboard/home']);
            });
          } else {
            this.getDisabledUserList();
          }
        }
      });
  }

  public reactivateUser(username: string, userType: string) {
    var dialogRef = this.dialog.open(ChangeUserPassComponent, {
      height: '30em',
      width: '30em',
    });

    if (userType == 'None') {
      dialogRef.componentInstance.userType = 'NONE';
    } else if (userType == $localize`Admin`) {
      dialogRef.componentInstance.userType = 'ADMIN';
    } else if (userType == $localize`Expert`) {
      dialogRef.componentInstance.userType = 'USER_N2';
    } else if (userType == $localize`Operator`) {
      dialogRef.componentInstance.userType = 'USER_N1';
    }

    dialogRef.componentInstance.username = username;
    dialogRef.componentInstance.reactivate = true;
  }

  public reload(): void {
    window.location.reload();
  }

  public setTableColumnsDependingOnWidth(width: number) {
    if (width < 600) {
      this.smallScreenMode = true;
    } else if (width >= 600) {
      this.displayedColumns = this.displayedColumnsInitial;
      this.smallScreenMode = false;
    }
  }

  private generateDataSource(userList: Array<User>) {
    this.dataSource.data = [];

    userList.forEach((element) => {
      this.dataSource.data.push(element);
    });
    this.paginator._intl = this.customPaginator;
    this.customPaginator.setTotalItems(userList.length);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }
}
