import { Component, OnInit } from '@angular/core';
import { ArrayType } from '@angular/compiler';
import { UserManagementAPIService } from 'src/app/Services/UserManagement/user-management-api.service';

@Component({
  selector: 'app-documents-downloads',
  templateUrl: './documents-downloads.component.html',
  styleUrls: ['./documents-downloads.component.css'],
})
export class DocumentsDownloadsComponent implements OnInit {
  constructor(private userManegement: UserManagementAPIService) {}

  public downloadables: Map<string, JSON> = new Map<string, JSON>();
  public fieldDownloables = 5;
  public showDivider: boolean;
  public lastValue = 0;
  public userType = window.localStorage.getItem('userType');

  public getLast(): string {
    return Array.from(this.downloadables).sort().pop()[0];
  }

  public ngOnInit(): void {
    this.lastValue = 0;
    this.userManegement
      .getDownloableFilesByProfileType(this.userType)
      .subscribe({
        next: (data) => {
          var json = JSON.stringify(data);
          var jsonParsed = JSON.parse(json);
          var usersTypes;
          var filesGroup;
          var file;
          for (usersTypes in jsonParsed) {
            for (filesGroup in jsonParsed[usersTypes]) {
              file = jsonParsed[usersTypes][filesGroup];
              if (
                file.type == 'docx' ||
                file.type == 'pdf' ||
                file.type == 'txt'
              ) {
                this.downloadables.set(file.title, file);
              }
            }
          }
        },
        error: () => {},
      });
  }
}
