import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { UserDataApiService } from 'src/app/Services/UserDataService/user-data-api.service';

@Component({
  selector: 'app-ac-qrlogin',
  templateUrl: './ac-qrlogin.component.html',
  styleUrls: ['./ac-qrlogin.component.css'],
})
export class AcQRLoginComponent implements OnInit {
  public dataString: string;
  constructor(
    public userDataService: UserDataApiService,
    public dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.userDataService.getUserQRAccessCode().subscribe({
      next: (data) => {
        var json = JSON.stringify(data);
        var jsonParsed = JSON.parse(json);

        this.dataString = jsonParsed.accessQrCode;
      },
      error: (err) => {
        console.error(err);
      },
    });
  }

  public closeDialog() {
    this.dialog.closeAll();
  }
}
