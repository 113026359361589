// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

const baseUrl = 'https://p.xsp.ieselargar.skylifeinfinity.com';

export const environment = {
  WebLanguages: {
    Spanish: 'https://ieselargar.skylifeinfinity.com/es',
    English: 'https://ieselargar.skylifeinfinity.com/en',
  },
  name: 'dev',
  platform: 'web',
  production: true,
  antonio: false,
  apiKey: 'lgmvtGlCwf5eNR7kJE2d39fQpImniN7t8Rl3tsR4',
  secret: '7d&PRxzr!W2w1tj5',
  URLloginweb: baseUrl + '/loginweb',
  URLchangepassword: baseUrl + '/changepassword',
  URLfileupload: baseUrl + '/fileupload',
  URLaddsessionreport: baseUrl + '/addsessionreport',
  URLgetsessions: baseUrl + '/getsessions',
  URLgetsessionslastmonth: baseUrl + '/getsessionslastmonth',
  URLuserlistweb: baseUrl + '/userlistweb',
  URLloginsecondfactor: baseUrl + '/loginsecondfactor',
  URLupdatesessionreport: baseUrl + '/updatesessionreport',
  URLuploadFile: baseUrl + '/generatefileurlweb',
  URLupdateshowsession: baseUrl + '/updateshowsession',
  URLupdatedisabledstatus: baseUrl + '/updatedisabledstatus',
  URLaddnewuserdb: baseUrl + '/setnewuserdb',
  URLaddnewusercognito: baseUrl + '/setnewusercognito',
  URLsetnewuserpasswordcognito: baseUrl + '/setnewuserpasswordcognito',
  URLcurrentuserinfo: baseUrl + '/currentuserinfoweb',
  URLforcechangeuserpassword: baseUrl + '/forcechangeuserpassword',
  URLcheckuseravailability: baseUrl + '/checkuseravailability',
  URLlogLogin: baseUrl + '/loglogin',
  URLrefreshtoken: baseUrl + '/refreshtoken',
  URLremoveToken: baseUrl + '/removetoken',
  URLremoveusercognito: baseUrl + '/removeusercognito',
  URLreactivateusercognito: baseUrl + '/reactivateusercognito',
  URLdisableusercognito: baseUrl + '/disableusercognito',
  URLforgotpassword: baseUrl + '/forgotpassword',
  URLforgotpasswordconfirmation: baseUrl + '/forgotpasswordconfirmation',
  URLAsociateSoftwareToken: baseUrl + '/associatesoftwaretoken',
  URLVerifySoftwareToken: baseUrl + '/verifysoftwaretoken',
  URLgetuserslicensesnumber: baseUrl + '/userLicences',
  URLupdatenewuserstatus: baseUrl + '/updatenewuserstatus',
  URLgetcurrentusermfastatus: baseUrl + '/getcurrentusermfastatus',
  URLupdatecurrentusermfastatus: baseUrl + '/updatecurrentusermfastatus',
  URLdisablemfa: baseUrl + '/changeusermfastatus',
  URLgetcurrentuserdata: baseUrl + '/getcurrentuserdata',
  URLupdatecurrentuserdata: baseUrl + '/updatecurrentuserdata',
  URLgetdownloablefilesbyprofiletype:
    baseUrl + '/getdownloablefilesbyprofiletype',
  URLgetcurrentuseraccessqrcode: baseUrl + '/getcurrentuseraccessqrcode',
  URLupdatecurrentuseraccessqrcode: baseUrl + '/updatecurrentuseraccessqrcode',
  URLgetdepartments: baseUrl + '/getdepartments',
  URLaddnewdepartment: baseUrl + '/insertnewdepartment',
  URLdeletedepartments: baseUrl + '/removedepartment',
  URLgetuserlistfromdepartment: baseUrl + '/getuserlistfromdepartment',
  URLaddusertodepartment: baseUrl + '/addusertodepartment',
  URLremoveuserfromdepartment: baseUrl + '/removeuserfromdepartment',
};
