import { Component, OnInit, ViewChild } from '@angular/core';
import { SelectionModel } from '@angular/cdk/collections';
import { MatTableDataSource } from '@angular/material/table';
import { DepartmentApiService } from 'src/app/Services/DepartmentManagement/department-api.service';
import { ActivatedRoute } from '@angular/router';
import { CustomMatPaginatorIntl } from 'src/app/custom-mat-paginator-int';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatDialog } from '@angular/material/dialog';
import { NewUserToDepartmentDialogComponent } from './new-user-to-department-dialog/new-user-to-department-dialog.component';

import { ConfirmationRemoveUserFromDepartmentComponent } from './remove-user-from-department-dialog/confirmation-remove-user-from-department/confirmation-remove-user-from-department.component';

@Component({
  selector: 'app-department-users',
  templateUrl: './department-users.component.html',
  styleUrls: ['./department-users.component.css'],
})
export class DepartmentUsersComponent implements OnInit {
  constructor(
    public departmentApiService: DepartmentApiService,
    public route: ActivatedRoute,
    public dialog: MatDialog
  ) {}

  @ViewChild(CustomMatPaginatorIntl)
  public customPaginator: CustomMatPaginatorIntl;
  @ViewChild(MatPaginator) public paginator: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;

  public removing = false;
  public idDepartment: string;

  public selection = new SelectionModel<UserDepartment>(true, []);
  public selectedIdDepartmentUser = Array<string>();
  public UserList: Array<UserDepartment> = [];
  public userNamesList = [];
  public dataSource = new MatTableDataSource(this.UserList);

  public displayedColumnsToDisable: string[] = ['select', 'username'];
  public displayedColumnsInitial: string[] = ['username'];
  public displayedColumns: string[] = ['username'];

  ngOnInit(): void {
    this.route.params.subscribe((params) => {
      this.idDepartment = params['departmentId'];

      this.departmentApiService
        .getUserListFromDepartment(this.idDepartment)
        .subscribe({
          next: (data) => {
            var json = JSON.stringify(data);
            var jsonParsed = JSON.parse(json);

            console.log(jsonParsed);

            jsonParsed.userlist.forEach((userData) => {
              var user = <UserDepartment>{};

              user.username = userData.username;
              user.idDepartmentUser = userData.iddepartmentUser;

              this.UserList.push(user);
              this.userNamesList.push(user.username);
            });

            this.dataSource = new MatTableDataSource<UserDepartment>(
              this.dataSource.data
            );

            this.paginator._intl = this.customPaginator;
            this.customPaginator.setTotalItems(this.UserList.length);
            this.dataSource.paginator = this.paginator;
            this.dataSource.sort = this.sort;
          },
          error: (error) => {
            console.error(error);
          },
        });
    });
  }

  public deleteSelectedUsers() {
    var usernameList = [];

    this.selection.selected.forEach((element) => {
      this.selectedIdDepartmentUser.push(element.idDepartmentUser);
      usernameList.push(element.username);
    });

    this.departmentApiService
      .removeUserFromDepartment(this.selectedIdDepartmentUser)
      .subscribe({
        next: () => {
          var dialog = this.dialog.open(
            ConfirmationRemoveUserFromDepartmentComponent,
            {
              width: '30em',
              height: '18em',
            }
          );

          dialog.componentInstance.userNames = usernameList;
        },
      });
  }
  public showCheckBox() {
    this.removing = true;
    this.displayedColumns = this.displayedColumnsToDisable;
  }

  public usersChecked(): Array<UserDepartment> {
    var userList = [];
    this.selection.selected.forEach((element) => {
      userList.push(element.username);
    });

    return userList;
  }

  public addNewUserToDepartment() {
    var refDialog = this.dialog.open(NewUserToDepartmentDialogComponent, {
      height: '20em',
      width: '20em',
    });

    refDialog.componentInstance.idDepartment = this.idDepartment;
    refDialog.componentInstance.departmentUsers = this.userNamesList;
  }

  public cancelRemoveUserFromDepartment() {
    this.selection.clear();
    this.removing = false;
    this.displayedColumns = this.displayedColumnsInitial;
  }

  public toggleAllRows() {
    if (this.isAllSelected()) {
      this.selection.clear();
      return;
    }

    this.selection.select(...this.dataSource.data);
  }

  public isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  public checkboxLabel(row?: UserDepartment): string {
    if (!row) {
      return `${this.isAllSelected() ? 'deselect' : 'select'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'}`;
  }
}

export interface UserDepartment {
  username: string;
  idDepartmentUser: string;
}
