<head>
  <link
    rel="stylesheet"
    href="https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@48,400,0,0"
  />
  <link
    rel="stylesheet"
    href="https://fonts.googleapis.com/css2?family=Material+Symbols+Rounded:opsz,wght,FILL,GRAD@48,400,1,0"
  />
</head>
<body class="userManagementBodyComponent">
  <div class="sessions-container hidden" id="hiddenContainer">
    <h1 class="userHeader" i18n>DISABLED USERS</h1>
    <div class="filter-buttons">
      <button
        mat-flat-button
        class="filterButton"
        color="primary"
        (click)="filterByStartDates()"
        i18n
      >
        <span class="material-symbols-rounded iconButton"> schedule </span>
        Filter by creation date
      </button>

      <button
        mat-flat-button
        class="filterButton"
        color="primary"
        (click)="backToUserManagement()"
        i18n
      >
        <mat-icon>recent_actors</mat-icon>
        Back to User management
      </button>

      <button
        mat-icon-button
        color="primary"
        *ngIf="filtering"
        (click)="reload()"
      >
        <mat-icon>refresh</mat-icon>
      </button>
      <label class="filterLabel" *ngIf="filteringByStartDate" i18n
        >filtering creation date between
      </label>
      <b class="filterLabel" *ngIf="filteringByStartDate">{{
        startDateFormated
      }}</b>
      <label class="filterLabel" *ngIf="filteringByStartDate" i18n> and </label>
      <b class="filterLabel" *ngIf="filteringByStartDate">{{
        endDateFormated
      }}</b>
    </div>
    <div class="filter-bar">
      <mat-form-field appearance="fill" class="filterUserManagementInput">
        <mat-label i18n>Filter</mat-label>
        <input
          matInput
          (keyup)="applyFilter($event)"
          placeholder="Search by selected fields"
          i18n-placeholder="Search by selected fields placeholder"
          #input
          class="filterUserManagementInput"
          autocomplete="new-password"
        />
      </mat-form-field>
      <mat-form-field appearance="fill" class="filter-options">
        <mat-label i18n class="filterByTitleLabel">Filter by</mat-label>
        <mat-select
          [formControl]="filterOptions"
          [value]="filterSelect"
          multiple
        >
          <mat-select-trigger>
            {{filterOptions.value?.[0] || ''}}
            <span
              *ngIf="(filterOptions.value?.length || 0) > 1"
              class="filter-option-additional-selection"
            >
              (+{{ (filterOptions.value?.length || 0) - 1 }}
              {{ filterOptions.value?.length === 2 ? "other" : "others" }})
            </span>
          </mat-select-trigger>
          <mat-option
            *ngFor="let filterOption of filterOptionList"
            [value]="filterOption"
            >{{ filterOption }}</mat-option
          >
        </mat-select>
      </mat-form-field>
    </div>
    <div class="table-container">
      <table
        mat-table
        [dataSource]="dataSource"
        matSort
        matSortActive="creationDate"
        matSortDirection="desc"
      >
        <ng-container matColumnDef="username">
          <th mat-header-cell *matHeaderCellDef mat-sort-header i18n>
            Username
          </th>
          <td mat-cell *matCellDef="let element">{{ element.username }}</td>
        </ng-container>
        <ng-container matColumnDef="creationDate">
          <th mat-header-cell *matHeaderCellDef mat-sort-header i18n>
            Creation Date
          </th>
          <td mat-cell *matCellDef="let element">
            <label *ngIf="element.creationDate != null">{{
              element.creationDate
            }}</label
            ><label *ngIf="element.creationDate == null" i18n
              >Not defined</label
            >
          </td>
        </ng-container>

        <ng-container matColumnDef="userType">
          <th mat-header-cell *matHeaderCellDef mat-sort-header i18n>
            User type
          </th>
          <td mat-cell *matCellDef="let element">{{ element.userType }}</td>
        </ng-container>

        <ng-container matColumnDef="activateUser">
          <th mat-header-cell *matHeaderCellDef mat-sort-header i18n>
            Reactivate user
          </th>
          <td mat-cell *matCellDef="let element">
            <button
              mat-button
              class="activateButton"
              (click)="reactivateUser(element.username, element.userType)"
            >
              <mat-icon alt="Activate user">how_to_reg</mat-icon>
            </button>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      </table>
    </div>
    <div class="paginator">
      <mat-paginator
        [pageSizeOptions]="[5, 10, 20]"
        showFirstLastButtons
        aria-label="Select page"
      >
      </mat-paginator>
    </div>
  </div>
</body>
