import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-remove-departments-confirmation-response',
  templateUrl: './remove-departments-confirmation-response.component.html',
  styleUrls: ['./remove-departments-confirmation-response.component.css'],
})
export class RemoveDepartmentsConfirmationResponseComponent implements OnInit {
  constructor(public dialog: MatDialog) {}

  departmentsRemoved: boolean;

  ngOnInit(): void {}

  public close() {
    this.dialog.closeAll();
    window.location.reload();
  }
}
