import {
  Component,
  OnInit,
  ViewChild,
  ViewEncapsulation,
  OnDestroy,
} from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { SessionsManagementAPIService } from '../../../Services/CallsManagement/sessions-management-api.service';
import { Session } from '../../../Interfaces/sessionInterface';
import { DatePipe } from '@angular/common';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { SessionsDatePickerFilterDialogComponent } from './sessions-date-picker-filter-dialog/sessions-date-picker-filter-dialog.component';
import { HostListener } from '@angular/core';
import { FormControl } from '@angular/forms';
import { NoReportAvailableDialogComponent } from './sessions-error-dialogs/no-report-available-dialog/no-report-available-dialog.component';
import { SelectionModel } from '@angular/cdk/collections';
import { DeleteSessionConfirmationComponent } from './delete-session-confirmation/delete-session-confirmation.component';
import { ErrorDeleteSessionDialogComponent } from './sessions-error-dialogs/error-delete-session-dialog/error-delete-session-dialog.component';
import { UserSessionCheckerService } from '../../../Services/UserManagement/UserSessionChecker/user-session-checker.service';
import '@angular/localize/init';
import { CustomMatPaginatorIntl } from 'src/app/custom-mat-paginator-int';

import { UserTypeNotAllowedDialogComponent } from '../../common/common-error-dialogs/user-type-not-allowed-dialog/user-type-not-allowed-dialog.component';

@Component({
  selector: 'app-sessions',
  templateUrl: './sessions.component.html',
  styleUrls: ['./sessions.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class SessionsComponent implements OnInit, OnDestroy {
  constructor(
    private sessionsManagementAPIService: SessionsManagementAPIService,
    private dialog: MatDialog,
    private router: Router,
    private datePipe: DatePipe,
    private userSessionCheckerService: UserSessionCheckerService
  ) {}

  @ViewChild(CustomMatPaginatorIntl)
  public customPaginator: CustomMatPaginatorIntl;
  @ViewChild(MatPaginator) public paginator: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;

  public EmptySessionList: Array<Session> = [];
  public SessionList: Array<Session> = [];
  public cancelRemoveSessions: boolean;
  public checkColumnsToDelete: string[] = [
    'select',
    'localId',
    'remoteId',
    'status',
    'start',
    'end',
    'quality',
    'reportContents',
  ];
  public dataSource = new MatTableDataSource(this.SessionList);
  public deleteConfirm: boolean;
  public displayedColumns: string[] = [
    'localId',
    'remoteId',
    'status',
    'start',
    'end',
    'quality',
    'reportContents',
  ];
  public displayedColumnsInitial: string[] = [
    'localId',
    'remoteId',
    'status',
    'start',
    'end',
    'quality',
    'reportContents',
  ];
  public endDate = '';
  public endDateFormated = '';
  public filterLabels = new Map<string, string>([
    [$localize`Local ID`, 'localId'],
    [$localize`Remote ID`, 'remoteId'],
    [$localize`Status`, 'status'],
    [$localize`Quality`, 'quality'],
    [$localize`Contents`, 'reportContents'],
  ]);
  public filterOptionList: string[] = [
    $localize`Local ID`,
    $localize`Remote ID`,
    $localize`Status`,
    $localize`Quality`,
    $localize`Contents`,
  ];
  public filterOptions = new FormControl<string[]>([$localize`Local ID`]);
  public filterOptionsByDefault: string[] = [$localize`Local ID`];
  public filterSelect = '';
  public filtering = false;
  public filteringByEndDate = false;
  public filteringByStartDate = false;
  public filteringDate = '';
  public isFiltered = false;
  public paginatorSessionNumber = 0;
  public removeSessionsList = new Array<string>();
  public removing = false;
  public selection = new SelectionModel<Session>(true, []);
  public showSession: Map<string, Array<string>>;
  public smallScreenMode = false;
  public startDate = '';
  public startDateFormated = '';
  public token = '';
  private userSessionCheckerServiceSubscription: any;

  public userCapabilities: string[] = JSON.parse(
    localStorage.getItem('userCapabilities')
  );

  @HostListener('window:resize', ['$event'])
  public onResize(event: UIEvent) {
    const w = event.target as Window;
    this.setTableColumnsDependingOnWidth(w.innerWidth);
  }

  public applyFilter(event: Event) {
    this.cancelDeleteSession();
    this.filteringByStartDate = false;
    this.filteringByEndDate = false;
    this.filtering = false;

    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.data = [];
    var sessionListFiltered: Array<Session> = [];
    this.filterOptions.value?.forEach((element) => {
      var filterId = this.filterLabels.get(element) as string;
      filterId ??= '';

      this.SessionList.filter((session) => {
        if (
          session[filterId].includes(filterValue) &&
          !sessionListFiltered.includes(session)
        ) {
          sessionListFiltered.push(session);
        }
      });
    });
    this.generateDataSource(sessionListFiltered, sessionListFiltered.length);
  }

  public cancelDeleteSession() {
    this.selection.clear();
    this.removing = false;
    this.displayedColumns = this.displayedColumnsInitial;
  }

  public checkSessionDate(date: Date): boolean {
    var errorDate = new Date('2000-01-01 00:00:00');

    if (date.toDateString() == errorDate.toDateString()) {
      return true;
    } else {
      return false;
    }
  }

  public checkboxLabel(row?: Session): string {
    if (!row) {
      return `${this.isAllSelected() ? 'deselect' : 'select'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'}`;
  }

  public filterByEndDates() {
    var dialogRef = this.dialog.open(SessionsDatePickerFilterDialogComponent, {
      height: '400px',
      width: '500px',
    });

    dialogRef.afterClosed().subscribe((result) => {
      this.cancelDeleteSession();
      var date = Object.values(result);
      this.startDate = date[0] as string;
      this.endDate = date[1] as string;
      var cancel = date[2] as string;
      if (!cancel) {
        this.filtering = false;
        this.filteringByEndDate = true;
        if (this.filteringByStartDate == true) {
          this.filteringByStartDate = false;
        }

        var SessionListTest = this.SessionList.filter((session) => {
          var startDateParsed = new Date(this.startDate);

          var endDateParsed = new Date(this.endDate);

          var sessionEndDateParsed = new Date(session.end);

          if (
            sessionEndDateParsed.getTime() >= startDateParsed.getTime() &&
            sessionEndDateParsed.getTime() <= endDateParsed.getTime()
          ) {
            return true;
          }
          return false;
        });

        this.startDateFormated = this.startDate.replace('T', ' ');
        this.endDateFormated = this.endDate.replace('T', ' ');

        this.generateDataSource(SessionListTest, SessionListTest.length);

        this.filtering = true;

        this.dataSource.filterPredicate = function (record, filter) {
          const matchFilter: boolean[] = [];
          const filters = JSON.parse(filter);
          filters.forEach((element: { id: string; value: string }) => {
            const val = record[element.id] === null ? '' : record[element.id];
            matchFilter.push(
              val.toLowerCase().includes(element.value.toLowerCase())
            );
          });
          return matchFilter.every(Boolean);
        };
      }
    });
  }

  public filterByStartDates() {
    var dialogRef = this.dialog.open(SessionsDatePickerFilterDialogComponent, {
      height: '400px',
      width: '500px',
    });

    dialogRef.afterClosed().subscribe((result) => {
      this.cancelDeleteSession();
      var date = Object.values(result);
      this.startDate = date[0] as string;
      this.endDate = date[1] as string;
      var cancel = date[2] as string;

      if (!cancel) {
        this.filtering = false;
        this.filteringByStartDate = true;
        if (this.filteringByEndDate == true) {
          this.filteringByEndDate = false;
        }
        var SessionListFiltered = this.SessionList.filter((session) => {
          var startDateParsed = new Date(this.startDate);
          var endDateParsed = new Date(this.endDate);

          var sessionStartDateParsed = new Date(session.start);

          if (
            sessionStartDateParsed.getTime() >= startDateParsed.getTime() &&
            sessionStartDateParsed.getTime() <= endDateParsed.getTime()
          ) {
            return true;
          }
          return false;
        });

        this.generateDataSource(
          SessionListFiltered,
          SessionListFiltered.length
        );

        this.startDateFormated = this.startDate.replace('T', ' ');
        this.endDateFormated = this.endDate.replace('T', ' ');

        this.filtering = true;

        this.dataSource.filterPredicate = function (record, filter) {
          const matchFilter: boolean[] = [];
          const filters = JSON.parse(filter);
          filters.forEach((element: { id: string; value: string }) => {
            const val = record[element.id] === null ? '' : record[element.id];
            matchFilter.push(
              val.toLowerCase().includes(element.value.toLowerCase())
            );
          });
          return matchFilter.every(Boolean);
        };
      }
    });
  }

  public getSessions(): void {
    this.sessionsManagementAPIService
      .getSessions('', '', '', '', '')
      .subscribe({
        next: (data: string) => {
          var json = JSON.stringify(data);
          var jsonParsed = JSON.parse(json);

          this.cancelDeleteSession();

          for (var key of Object.keys(jsonParsed)) {
            var session = <Session>{};
            if (jsonParsed[key].showSession == 1) {
              session.id = key;
              session.localId = jsonParsed[key].localId;
              session.remoteId = jsonParsed[key].remoteId;
              session.status = jsonParsed[key].status;
              session.start = this.transformUTCDateToLocalDate(
                jsonParsed[key].start
              );
              session.end = this.transformUTCDateToLocalDate(
                jsonParsed[key].end
              );
              session.quality =
                jsonParsed[key].quality == null
                  ? $localize`Not added`
                  : jsonParsed[key].quality;
              session.reportContents =
                jsonParsed[key].report == null
                  ? $localize`Not added`
                  : jsonParsed[key].report;

              this.SessionList.push(session);
            }
          }

          this.generateDataSource(this.SessionList, this.SessionList.length);

          this.dataSource.filterPredicate = function (record, filter) {
            const matchFilter: boolean[] = [];
            const filters = JSON.parse(filter);
            filters.forEach((element: { id: string; value: string }) => {
              const val = record[element.id] === null ? '' : record[element.id];
              matchFilter.push(
                val.toLowerCase().includes(element.value.toLowerCase())
              );
            });
            return matchFilter.every(Boolean);
          };
        },
        error: (error) => {
          console.error('There was an error!', error);
        },
      });
  }

  public getNotAddedMessage() {
    return $localize`Not added`;
  }

  public isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  public navigateToReport(ses: Session) {
    if (!this.removing) {
      this.selection.clear();

      if (
        ses.reportContents == null ||
        ses.reportContents == this.getNotAddedMessage()
      ) {
        this.dialog.open(NoReportAvailableDialogComponent, {
          height: '150px',
          width: '220px',
        });
      } else {
        this.router.navigateByUrl('dashboard/report/' + ses.id);
      }
    }
  }
  ngOnDestroy(): void {
    this.userSessionCheckerServiceSubscription.unsubscribe();
  }

  public ngOnInit(): void {
    this.userSessionCheckerService.checkUserSession();

    this.userSessionCheckerServiceSubscription =
      this.userSessionCheckerService.currentUserStatus.subscribe((status) => {
        if (status == true) {
          document.getElementById('hiddenContainer').classList.remove('hidden');

          if (!this.userCapabilities.includes('VS')) {
            var dialogRef = this.dialog.open(
              UserTypeNotAllowedDialogComponent,
              {
                height: '15em',
                width: '40em',
              }
            );

            dialogRef.afterClosed().subscribe(() => {
              this.router.navigate(['/dashboard/profile']);
            });
          } else {
            this.setTableColumnsDependingOnWidth(window.innerWidth);
            this.cancelDeleteSession();
            this.getSessions();
          }
        }
      });
  }

  public reload(): void {
    window.location.reload();
  }

  public removeSessions() {
    this.sessionsChecked();
    var dialogRef = this.dialog.open(DeleteSessionConfirmationComponent, {
      height: '250px',
      width: '400px',
    });
    dialogRef.componentInstance.sessionsNum = this.removeSessionsList.length;

    dialogRef.afterClosed().subscribe((result) => {
      var res = Object.values(result);
      if (res[0]) {
        if (this.removeSessionsList.length != 0) {
          if (this.checkUserSession()) {
            this.sessionsManagementAPIService
              .removeSessions(this.removeSessionsList)
              .subscribe((result) => {
                var dialogRef = this.dialog.open(
                  ErrorDeleteSessionDialogComponent
                );
                if (result['status'] == 'success') {
                  dialogRef.componentInstance.errorDeleteSession = true;
                } else {
                  dialogRef.componentInstance.errorDeleteSession = false;
                }
              });

            for (var i = 0; i < this.SessionList.length; i++) {
              if (this.isInRemoveSessionList(this.SessionList[i])) {
                this.SessionList.splice(i, 1);
                i--;
              }
            }

            this.generateDataSource(this.SessionList, this.SessionList.length);

            this.cancelRemoveSessions = true;
          } else {
            dialogRef.close();
          }
        }
      } else {
        this.showCheckBox();
      }
      if (this.cancelRemoveSessions == true) {
        this.cancelDeleteSession();
      }
    });
  }

  //replace / with - in the given string and return it
  public replaceSlash(date: string): string {
    var dateArray = date.split('/');
    var dateString = dateArray[2] + '-' + dateArray[1] + '-' + dateArray[0];
    return dateString;
  }

  public sessionsChecked() {
    this.removeSessionsList = [];
    this.selection.selected.forEach((element) => {
      this.removeSessionsList.push(element.id);
    });
  }

  public setTableColumnsDependingOnWidth(width: number) {
    if (width < 600) {
      this.smallScreenMode = true;
    } else if (width >= 600) {
      this.displayedColumns = this.displayedColumnsInitial;
      this.smallScreenMode = false;
    }
  }

  public showCheckBox() {
    this.removing = true;
    this.displayedColumns = this.checkColumnsToDelete;
  }

  public showSessionDate(date: string): string {
    if (date != '0000-00-00 00:00:00') {
      return date;
    } else {
      return $localize`Not defined`;
    }
  }

  public toggleAllRows() {
    if (this.isAllSelected()) {
      this.selection.clear();
      return;
    }

    this.selection.select(...this.dataSource.data);
  }

  //transform the date to dd-MM-yyyy string
  public transformDate(date: Date): string {
    return this.datePipe.transform(date, 'yyyy-MM-dd')!;
  }

  public transformUTCDateToLocalDate(date: string): Date {
    var dateTest = new Date(date);

    var newDate = new Date(
      dateTest.getTime() + dateTest.getTimezoneOffset() * 60 * 1000
    );

    var offset = dateTest.getTimezoneOffset() / 60;
    var hours = dateTest.getHours();

    newDate.setHours(hours - offset);

    if (newDate.toString() != 'Invalid Date') {
      return newDate;
    } else {
      var dateError = new Date('2000-01-01 00:00:00');
      return dateError;
    }
  }

  private checkUserSession(): boolean {
    this.userSessionCheckerService.checkUserSession();
    return this.userSessionCheckerService.check;
  }

  private generateDataSource(SessionListTest, length: number) {
    this.dataSource.data = [];

    SessionListTest.forEach((element) => {
      this.dataSource.data.push(element);
    });

    this.paginator._intl = this.customPaginator;
    this.customPaginator.setTotalItems(length);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  private isInRemoveSessionList(Sess: Session) {
    for (var i = 0; i < this.removeSessionsList.length; i++) {
      if (Sess.id == this.removeSessionsList[i]) {
        return true;
      }
    }
    return false;
  }
}
