<div class="container">
  <mat-label>{{ secretCode }} </mat-label>
  <div class="tooltip">
    <button
      (click)="copySecretCodeTooltip()"
      (mouseout)="outFunc()"
      class="copyButton"
    >
      <span class="tooltiptext" id="myTooltip">Copy to clipboard</span>
      <mat-icon>content_copy</mat-icon>
    </button>
  </div>
</div>
<div class="buttonsContainer">
  <button mat-button color="primary" (click)="confirmMFACode()" i18n>
    Confirm
  </button>
  <button mat-button color="error" (click)="closeDialog()" i18n>Cancel</button>
</div>
