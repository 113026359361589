import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-no-report-available-dialog',
  templateUrl: './no-report-available-dialog.component.html',
  styleUrls: ['./no-report-available-dialog.component.css'],
})
export class NoReportAvailableDialogComponent implements OnInit {
  constructor(public dialog: MatDialogRef<NoReportAvailableDialogComponent>) {}
  closeDialog(): void {
    this.dialog.close();
  }

  ngOnInit(): void {}
}
