import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { BehaviorSubject } from 'rxjs';
import { InvalidTokenComponent } from '../../../Components/common/common-error-dialogs/invalid-token/invalid-token.component';
import { UserManagementAPIService } from '../user-management-api.service';

@Injectable({
  providedIn: 'root',
})
export class UserSessionCheckerService {
  constructor(
    private userManagementAPIService: UserManagementAPIService,
    private dialog: MatDialog,
    private router: Router,
    private jwtHelperService: JwtHelperService
  ) {}

  public check: boolean;

  private userStatus = new BehaviorSubject(false);
  currentUserStatus = this.userStatus.asObservable();

  updateUserStatus(isValid: boolean) {
    this.userStatus.next(isValid);
  }

  checkUserSession() {
    var token = window.localStorage.getItem('Authorization')!;

    if (this.jwtHelperService.isTokenExpired(token)) {
      // token expired
      var dialogRef = this.dialog.open(InvalidTokenComponent, {
        height: '15em',
        width: '30em',
      });
      dialogRef.componentInstance.revoked = false;

      dialogRef.afterClosed().subscribe(() => {
        this.router.navigateByUrl('/login');
      });
      this.check = false;
      this.updateUserStatus(false);
    } else {
      this.userManagementAPIService.refreshToken().subscribe({
        next: () => {
          //the token could be refreshed successfully
          this.check = true;
          this.updateUserStatus(true);
        },
        error: () => {
          //the token could not be refreshed, threrefore the user is logged in ahother device
          var dialogRef = this.dialog.open(InvalidTokenComponent, {
            height: '15em',
            width: '30em',
          });

          dialogRef.componentInstance.revoked = true;
          this.check = false;
          dialogRef.afterClosed().subscribe(() => {
            this.router.navigateByUrl('/login');
          });
        },
      });
    }
  }
}
