import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MfaConfirmationDialogComponent } from '../mfa-confirmation-dialog/mfa-confirmation-dialog.component';

@Component({
  selector: 'app-secret-code-dialog',
  templateUrl: './secret-code-dialog.component.html',
  styleUrls: ['./secret-code-dialog.component.css'],
})
export class SecretCodeDialogComponent implements OnInit {
  secretCode: string;

  constructor(
    public dialogRef: MatDialogRef<SecretCodeDialogComponent>,
    public dialog: MatDialog
  ) {}

  copySecretCodeTooltip() {
    navigator.clipboard.writeText(this.secretCode);
    var tooltip = document.getElementById('myTooltip');
    tooltip.innerHTML = 'Copied!';
  }

  outFunc() {
    var tooltip = document.getElementById('myTooltip');
    tooltip.innerHTML = 'Copy to clipboard';
  }

  public confirmMFACode() {
    this.dialog.open(MfaConfirmationDialogComponent, {
      height: '17em',
      width: '25em',
    });
  }

  public closeDialog() {
    this.dialogRef.close();
  }

  ngOnInit(): void {}
}
