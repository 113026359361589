<body>
  <div class="container hidden" id="hiddenContainer">
    <div class="buttonsHead">
      <button
        mat-button
        [routerLinkActive]="['active']"
        [routerLink]="['./videos-downloads']"
        class="downloadsButton"
        color="primary"
        i18n
      >
        Videos
      </button>
      <button
        mat-button
        [routerLinkActive]="['active']"
        [routerLink]="['./documents-downloads']"
        class="downloadsButton"
        color="primary"
        i18n
      >
        Documents
      </button>
      <button
        mat-button
        [routerLinkActive]="['active']"
        [routerLink]="['./executables-downloads']"
        class="downloadsButton"
        color="primary"
        i18n
      >
        Executables
      </button>
    </div>
    <div class="profileContent">
      <router-outlet></router-outlet>
    </div>
  </div>
</body>
