import { Component, OnInit } from '@angular/core';
import { UserManagementAPIService } from 'src/app/Services/UserManagement/user-management-api.service';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MfaConfirmationResponseDialogComponent } from './mfa-confirmation-response-dialog/mfa-confirmation-response-dialog.component';

@Component({
  selector: 'app-mfa-confirmation-dialog',
  templateUrl: './mfa-confirmation-dialog.component.html',
  styleUrls: ['./mfa-confirmation-dialog.component.css'],
})
export class MfaConfirmationDialogComponent implements OnInit {
  constructor(
    public userManagementAPIService: UserManagementAPIService,
    public dialogRef: MatDialogRef<MfaConfirmationDialogComponent>,
    public dialog: MatDialog
  ) {}

  public mfaForWeb: boolean;

  verifyUser(userCode: string, friendlyDeviceName: string): void {
    this.userManagementAPIService
      .verifySoftwareToken(userCode, friendlyDeviceName)
      .subscribe({
        next: () => {
          localStorage.setItem('UserLoginMethod', 'SOFTWARE_TOKEN_MFA');
          var responseDialog = this.dialog.open(
            MfaConfirmationResponseDialogComponent,
            {
              height: '10em',
              width: '25em',
            }
          );

          responseDialog.componentInstance.mfaIsEnabled = true;

          responseDialog.afterClosed().subscribe(() => {
            location.reload();
          });
        },
        error: () => {
          var responseDialog = this.dialog.open(
            MfaConfirmationResponseDialogComponent,
            {
              height: '10em',
              width: '25em',
            }
          );

          responseDialog.componentInstance.errorInActivation = true;
        },
      });
  }

  public closeDialog() {
    this.dialogRef.close();
  }

  ngOnInit(): void {}
}
