import { Component, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { UserManagementAPIService } from '../../../../../Services/UserManagement/user-management-api.service';
import { UserTypeNotAllowedDialogComponent } from '../../../../common/common-error-dialogs/user-type-not-allowed-dialog/user-type-not-allowed-dialog.component';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { UserDataApiService } from 'src/app/Services/UserDataService/user-data-api.service';
import { environment } from 'src/environments/environment';
import { ConfirmationActivateUserComponent } from '../../disable/show-disabled-users/confirmation-activate-user/confirmation-activate-user.component';
import { ChangePasswordConfirmationDialogComponent } from 'src/app/Components/user/profile/security/change-password/change-password-confirmation-dialog/change-password-confirmation-dialog.component';

@Component({
  selector: 'app-admin-change-user-pass',
  templateUrl: './admin-change-user-pass.component.html',
  styleUrls: ['./admin-change-user-pass.component.css'],
})
export class ChangeUserPassComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<ChangeUserPassComponent>,
    private dialog: MatDialog,
    private userDataAPIService: UserDataApiService,
    public userManagementAPIService: UserManagementAPIService,
    private router: Router
  ) {}
  public reactivate: boolean;
  public myControl = new FormControl('');
  public newPassword = new FormControl('', [
    Validators.required,
    Validators.minLength(8),
  ]);
  public newRepeatedPassword = new FormControl('', [Validators.required]);
  public options: string[];
  public spinner = false;
  public token = '';
  public userList: Observable<string[]>;
  public username: string;
  public userType: string;
  public userCapabilities: string[] = JSON.parse(
    localStorage.getItem('userCapabilities')
  );

  public cancelChangeUserPass() {
    this.dialogRef.close();
  }
  public cmpNewPassword() {
    if (this.newPassword.value!.length < 8) {
      return 0;
    } else if (
      this.newPassword.value!.replace(/\w/g, '').length ==
      this.newPassword.value!.length
    ) {
      return 0;
    } else if (
      this.newPassword.value!.replace(/\d/g, '').length ==
      this.newPassword.value!.length
    ) {
      return 0;
    } else if (
      this.newPassword.value!.replace(/\W||\D/g, '').length ==
      this.newPassword.value!.length
    ) {
      return 0;
    } else if (
      this.newPassword.value!.replace(/[A-Z]/g, '').length ==
      this.newPassword.value!.length
    ) {
      return 0;
    }
    return 1;
  }
  private reactivateUser(newPass: string) {
    var userReactivateList = [this.username];

    console.log('this.userType: ' + this.userType);
    this.userManagementAPIService
      .checkUserAvailability(this.username, this.userType)
      .subscribe({
        next: () => {
          this.userManagementAPIService
            .changeUserPasswordCognito(this.username, newPass)
            .subscribe({
              next: () => {
                this.userManagementAPIService
                  .reactivateUserCognito(this.username)
                  .subscribe({
                    next: () => {
                      this.userManagementAPIService
                        .updateDisabledStatus(userReactivateList, 'enable')
                        .subscribe({
                          next: () => {
                            var qrData =
                              'skylifeinfinity' +
                              '[SIS]' +
                              this.username +
                              '[SIS]' +
                              newPass;
                            var accessQrCode = this.userDataAPIService.encrypt(
                              environment.secret,
                              qrData
                            );
                            this.userDataAPIService
                              .updateUserQRAccessCode(
                                this.username,
                                accessQrCode
                              )
                              .subscribe({
                                next: () => {
                                  var dialog = this.dialog.open(
                                    ConfirmationActivateUserComponent
                                  );
                                  dialog.componentInstance.activateUserSuccess =
                                    true;
                                  this.router.navigateByUrl(
                                    'dashboard/user-management'
                                  );
                                },
                                error: (err) => {
                                  console.error(err);
                                },
                              });
                          },
                          error: () => {
                            var dialog = this.dialog.open(
                              ConfirmationActivateUserComponent
                            );
                            dialog.componentInstance.activateUserSuccess =
                              false;
                          },
                        });
                    },
                    error: () => {
                      var dialog = this.dialog.open(
                        ConfirmationActivateUserComponent
                      );
                      dialog.componentInstance.activateUserSuccess = false;
                    },
                  });
              },
            });
        },
        error: () => {
          var dialogRef = this.dialog.open(ConfirmationActivateUserComponent);
          dialogRef.componentInstance.activateUserSuccess = false;
          dialogRef.componentInstance.userTypeFull = true;
        },
      });
  }
  private changeUserPass(newPass: string) {
    this.userManagementAPIService
      .changeUserPasswordCognito(this.username, newPass)
      .subscribe({
        next: () => {
          var qrData =
            'skylifeinfinity' + '[SIS]' + this.username + '[SIS]' + newPass;
          var accessQrCode = this.userDataAPIService.encrypt(
            environment.secret,
            qrData
          );
          this.userDataAPIService
            .updateUserQRAccessCode(this.username, accessQrCode)
            .subscribe({
              next: () => {
                var dialog = this.dialog.open(
                  ChangePasswordConfirmationDialogComponent,
                  {
                    height: '15em',
                    width: '25em',
                  }
                );
                dialog.componentInstance.passwordChanged = true;
              },
              error: (err) => {
                alert('error encrypting pass');
                console.error(err);
              },
            });
        },
        error: () => {
          var dialog = this.dialog.open(
            ChangePasswordConfirmationDialogComponent,
            {
              height: '40em',
              width: '30em',
            }
          );
          dialog.componentInstance.passwordChanged = false;
        },
      });
  }

  public confirm(newPass: string) {
    this.spinner = true;
    if (this.reactivate == true) {
      this.reactivateUser(newPass);
    } else if (this.reactivate == false) {
      this.changeUserPass(newPass);
    }
  }
  public getNewPasswordError() {
    if (this.newPassword.invalid) {
      return $localize`The password is too short`;
    } else if (
      this.newPassword.value!.replace(/\w/g, '').length ==
      this.newPassword.value!.length
    ) {
      return $localize`The password must contain letters`;
    } else if (
      this.newPassword.value!.replace(/\d/g, '').length ==
      this.newPassword.value!.length
    ) {
      return $localize`The password must contain numbers`;
    } else if (
      this.newPassword.value!.replace(/[A-Z]/g, '').length ==
      this.newPassword.value!.length
    ) {
      return $localize`The password must contain capital letters`;
    } else {
      return $localize`The password must contain special characters`;
    }
  }
  public ngOnInit(): void {
    this.options = this.userManagementAPIService.getUsersNameList();

    if (!this.userCapabilities.includes('EU')) {
      console.log('ADMIN CHANGE USER PASS: User type not allowed');
      var dialog = this.dialog.open(UserTypeNotAllowedDialogComponent, {
        height: '15em',
        width: '40em',
      });
      dialog.afterClosed().subscribe(() => {
        this.router.navigate(['/dashboard/home']);
      });
    }
  }
  public repeatPasswordError() {
    if (
      this.newRepeatedPassword.value != this.newPassword.value &&
      this.newRepeatedPassword.value != ''
    ) {
      return $localize`The password must be the same`;
    }
    return '';
  }
  public sameName(userName: string) {
    return window.localStorage.getItem('SessionUsername') == userName;
  }
  public sameUser(userName: string): boolean {
    return userName == window.localStorage.getItem('SessionUsername');
  }
  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.options.filter((option) =>
      option.toLowerCase().startsWith(filterValue)
    );
  }
}
