import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-remove-user-from-department-response',
  templateUrl: './remove-user-from-department-response.component.html',
  styleUrls: ['./remove-user-from-department-response.component.css'],
})
export class RemoveUserFromDepartmentResponseComponent implements OnInit {
  constructor(
    private dialogRef: MatDialogRef<RemoveUserFromDepartmentResponseComponent>
  ) {}
  public userRemoved: boolean;

  close() {
    this.dialogRef.close();
    window.location.reload();
  }

  ngOnInit(): void {}
}
