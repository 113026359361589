import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Department } from 'src/app/Interfaces/departmentInterface';

@Component({
  selector: 'app-changes-user-data-dialog',
  templateUrl: './changes-user-data-dialog.component.html',
  styleUrls: ['./changes-user-data-dialog.component.css'],
})
export class ChangesUserDataDialogComponent implements OnInit {
  constructor(
    private dialogRef: MatDialogRef<ChangesUserDataDialogComponent>
  ) {}

  public hidden = true;

  public changePersonalData = {
    email: '',
    name: '',
    usertype: '',
    surname: '',
    language: '',
    departments: Array<Department>(),
    deleteDepartments: Array<Department>(),
  };

  public currentUserData = {
    name: '',
    surname: '',
    language: '',
  };

  public close(edit: boolean) {
    this.dialogRef.close({ edit: edit });
  }

  ngOnInit(): void {}
}
