<div>
  <div class="twoFactorInformation" i18n>
    Two factor authentication: please insert the security code provided by your
    Authenticator App
  </div>
  <mat-form-field class="example-full-width" appearance="fill">
    <mat-label i18n>Verification Code</mat-label>
    <input
      #mfaCodeNumber
      matInput
      (keydown.enter)="
        mfaCodeNumber.value.length == 6 ? confirm(mfaCodeNumber.value) : ''
      "
      type="number"
      [formControl]="mfaCode"
      placeholder="123456"
      i18n-placeholder="123456 placeholder"
      required
      i18n
    />
  </mat-form-field>
  <label
    class="errorMessage"
    *ngIf="mfaCodeNumber.value.length != 6 && mfaCodeNumber.value.length != 0"
    i18n
    >Please, enter a valid 6-digit code</label
  >

  <button
    [disabled]="mfaCodeNumber.value.length != 6"
    class="confirmButton"
    mat-button
    (click)="confirm(mfaCodeNumber.value)"
    i18n
  >
    Confirm
  </button>
</div>
