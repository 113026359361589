<div class="container">
  <div class="infoContainer" i18n>
    Scan the QR code with your Authenticator App and click on the 'Confirm'
    button to insert the code generated by the app.
  </div>
  <qrcode
    *ngIf="dataString != null"
    [qrdata]="dataString"
    [width]="256"
    [errorCorrectionLevel]="'M'"
  ></qrcode>
  <div class="qrCodePlaceholder" *ngIf="dataString == null">
    <div class="loader"></div>
  </div>

  <div class="buttonsContainer">
    <button mat-button color="primary" (click)="confirmMFACode()" i18n>
      CONFIRM
    </button>
    <button mat-button color="error" (click)="closeDialog()" i18n>
      CANCEL
    </button>
  </div>
  <div class="secretCodeButtonContainer" i18n>
    If you can't scan the QR code, copy and paste the key into your application
    with the following button: <br />
    <button
      class="insertManuallyButton"
      mat-button
      color="primary"
      (click)="showSecretCode()"
    >
      SHOW TEXT CODE
    </button>
  </div>
</div>
