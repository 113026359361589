export class DashboardCard {
  icon: string;
  title: string;
  description: string;
  buttonText: string;
  url: string;
  capability: string;
  hasMethodCall: boolean;
  methodCall: string;

  constructor(
    icon: string,
    title: string,
    description: string,
    buttonText: string,
    url: string,
    capability: string,
    hasMethodCall: boolean,
    methodCall: string
  ) {
    this.icon = icon;
    this.title = title;
    this.description = description;
    this.buttonText = buttonText;
    this.url = url;
    this.capability = capability;
    this.hasMethodCall = hasMethodCall;
    this.methodCall = methodCall;
  }
}

export const dashboardCards: DashboardCard[] = [
  new DashboardCard(
    'qr_code',
    $localize`Access`,
    $localize`Use your identityQR to log into our service application`,
    $localize`SHOW THE IDENTITY QR CODE`,
    '../sessions',
    '',
    true,
    'openHoloLensAccessDialog()'
  ),
  new DashboardCard(
    'headset_mic',
    $localize`Sessions`,
    $localize`Manage your sessions here`,
    $localize`GO TO SESSIONS MANAGEMENT`,
    '../sessions',
    'VS',
    false,
    ''
  ),
  new DashboardCard(
    'people',
    $localize`Users`,
    $localize`Manage your users here`,
    $localize`GO TO USERS MANAGEMENT`,
    '../user-management',
    'VU',
    false,
    ''
  ),
  new DashboardCard(
    'group',
    $localize`Departments`,
    $localize`Manage your departments here`,
    $localize`GO TO DEPARTMENTS MANAGEMENT`,
    '../departments-management',
    'VD',
    false,
    ''
  ),

  new DashboardCard(
    'person',
    $localize`Profile`,
    $localize`Manage your profile here`,
    $localize`GO TO PROFILE `,
    '..//profile/personal-data',
    '',
    false,
    ''
  ),
];
