import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { SafeResourceUrl } from '@angular/platform-browser';

@Component({
  selector: 'app-direct-download-component',
  templateUrl: './direct-download-component.component.html',
  styleUrls: ['./direct-download-component.component.css'],
})
export class DirectDownloadComponentComponent implements OnInit {
  constructor(public dialog: MatDialog) {}

  public qrUrl: SafeResourceUrl;

  ngOnInit(): void {
    console.log(this.qrUrl.toString());
  }

  public closeDialog() {
    this.dialog.closeAll();
  }
}
