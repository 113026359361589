import { Component, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { UserManagementAPIService } from 'src/app/Services/UserManagement/user-management-api.service';
import { environment } from 'src/environments/environment';
import { UserDataApiService } from 'src/app/Services/UserDataService/user-data-api.service';
import { ChangePasswordConfirmationDialogComponent } from '../../user/profile/security/change-password/change-password-confirmation-dialog/change-password-confirmation-dialog.component';

@Component({
  selector: 'app-forget-password-dialog',
  templateUrl: './forget-password-dialog.component.html',
  styleUrls: ['./forget-password-dialog.component.scss'],
})
export class ForgetPasswordDialogComponent implements OnInit {
  constructor(
    public dialog: MatDialogRef<ForgetPasswordDialogComponent>,
    public userManagmentAPIService: UserManagementAPIService,
    public matDialog: MatDialog,
    public userDataAPIService: UserDataApiService
  ) {}

  public confirmationCode = new FormControl('', [Validators.required]);
  public emailSend = false;
  public notValid = false;

  public newPass = new FormControl('', [
    Validators.required,
    Validators.minLength(8),
  ]);
  public newRepeatedPassword = new FormControl('', [
    Validators.required,
    Validators.minLength(8),
  ]);
  public userName = new FormControl('', [
    Validators.required,
    Validators.email,
  ]);

  public cancelForgotPassword() {
    this.dialog.close();
  }

  public changePassword(
    userName: string,
    confirmationCode: string,
    newPassword: string
  ) {
    this.userManagmentAPIService
      .forgotPasswordConfirmation(userName, confirmationCode, newPassword)
      .subscribe({
        next: () => {
          var qrData =
            'skylifeinfinity' + '[SIS]' + userName + '[SIS]' + newPassword;

          var accessQrCode = this.userDataAPIService.encrypt(
            environment.secret,
            qrData
          );

          this.userDataAPIService
            .updateUserQRAccessCode(userName, accessQrCode)
            .subscribe({
              next: () => {
                var dialogRef = this.matDialog.open(
                  ChangePasswordConfirmationDialogComponent
                );
                dialogRef.componentInstance.passwordChanged = true;
                dialogRef.componentInstance.incorrectPassword = false;

                dialogRef.afterClosed().subscribe({
                  next: () => {
                    this.dialog.close();
                  },
                });
              },
              error: (err) => {
                alert('error encrypting pass');
                console.error(err);
              },
            });
        },
        error: () => {
          var dialogRef = this.matDialog.open(
            ChangePasswordConfirmationDialogComponent
          );
          dialogRef.componentInstance.passwordChanged = false;
          dialogRef.componentInstance.incorrectPassword = false;
        },
      });
  }

  public cmpNewPassword() {
    if (this.newPass.value!.length < 8) {
      return 0;
    } else if (
      this.newPass.value!.replace(/\w/g, '').length ==
      this.newPass.value!.length
    ) {
      return 0;
    } else if (
      this.newPass.value!.replace(/\d/g, '').length ==
      this.newPass.value!.length
    ) {
      return 0;
    } else if (
      this.newPass.value!.replace(/\W||\D/g, '').length ==
      this.newPass.value!.length
    ) {
      return 0;
    } else if (
      this.newPass.value!.replace(/[A-Z]/g, '').length ==
      this.newPass.value!.length
    ) {
      return 0;
    }
    return 1;
  }

  public getNewPasswordError() {
    if (this.newPass.invalid) {
      return $localize`The password is too short`;
    } else if (
      this.newPass.value!.replace(/\w/g, '').length ==
      this.newPass.value!.length
    ) {
      return $localize`The password must contain letters`;
    } else if (
      this.newPass.value!.replace(/\d/g, '').length ==
      this.newPass.value!.length
    ) {
      return $localize`The password must contain numbers`;
    } else if (
      this.newPass.value!.replace(/[A-Z]/g, '').length ==
      this.newPass.value!.length
    ) {
      return $localize`The password must contain capital letters`;
    } else {
      return $localize`The password must contain special characters`;
    }
  }

  public ngOnInit(): void {}

  public repeatPasswordError() {
    if (
      this.newRepeatedPassword.value != this.newPass.value &&
      this.newRepeatedPassword.value != ''
    ) {
      return $localize`The password must be the same`;
    }
    return '';
  }

  public sendConfirmationCode(userName: string) {
    this.userManagmentAPIService.forgotPassword(userName).subscribe({
      next: () => {
        this.emailSend = true;
        this.notValid = false;
      },
      error: () => {
        this.notValid = true;
      },
    });
  }
}
