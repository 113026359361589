<div (keyup.enter)="confirmDeleteSessions(true)" class="content">
  <Label class="tokenLabel" i18n
    >Are you sure that you want to disable the selected user(s)? Users selected:
    {{ usersNum }}
  </Label>
</div>
<div>
  <button
    class="confirmButton"
    mat-button
    (click)="confirmDeleteSessions(true)"
    i18n
  >
    Confirm
  </button>
  <button
    class="confirmButton"
    mat-button
    (click)="confirmDeleteSessions(false)"
    i18n
  >
    Back
  </button>
</div>
