import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-mfa-confirmation-response-dialog',
  templateUrl: './mfa-confirmation-response-dialog.component.html',
  styleUrls: ['./mfa-confirmation-response-dialog.component.css'],
})
export class MfaConfirmationResponseDialogComponent implements OnInit {
  mfaIsEnabled = false;
  errorInActivation = false;
  errorInPassword = false;

  constructor(
    public dialogRef: MatDialogRef<MfaConfirmationResponseDialogComponent>
  ) {}

  ngOnInit(): void {}

  public closeDialog() {
    this.dialogRef.close();
  }
}
