import { Component, HostListener, OnInit, OnDestroy } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { UserSessionCheckerService } from '../../../Services/UserManagement/UserSessionChecker/user-session-checker.service';
import { UserTypeNotAllowedDialogComponent } from '../../common/common-error-dialogs/user-type-not-allowed-dialog/user-type-not-allowed-dialog.component';

@Component({
  selector: 'app-user-help',
  templateUrl: './user-help.component.html',
  styleUrls: ['./user-help.component.css'],
})
export class UserHelpComponent implements OnInit, OnDestroy {
  constructor(
    private userSessionCheckerService: UserSessionCheckerService,
    private dialog: MatDialog,
    private router: Router
  ) {}

  public small = this.smallScreen(window.innerWidth);
  public userStatus = false;
  private userSessionCheckerServiceSubscription: any;

  public userCapabilities: string[] = JSON.parse(
    localStorage.getItem('userCapabilities')
  );

  @HostListener('window:resize', ['$event'])
  public onResize(event: UIEvent) {
    const w = event.target as Window;
    this.small = this.smallScreen(w.innerWidth);
  }

  ngOnDestroy(): void {
    this.userSessionCheckerServiceSubscription.unsubscribe();
  }

  public ngOnInit(): void {
    this.userSessionCheckerService.checkUserSession();

    this.userSessionCheckerServiceSubscription =
      this.userSessionCheckerService.currentUserStatus.subscribe((status) => {
        if (status == true) {
          document.getElementById('hiddenContainer').classList.remove('hidden');

          if (!this.userCapabilities.includes('VH')) {
            console.log('USER MANAGEMENT: User type not allowed');
            var dialogRef = this.dialog.open(
              UserTypeNotAllowedDialogComponent,
              {
                height: '15em',
                width: '40em',
              }
            );

            dialogRef.afterClosed().subscribe(() => {
              this.router.navigate(['/dashboard/profile']);
            });
          }
        }
      });
  }

  public smallScreen(screenWidth: number) {
    return screenWidth < 850;
  }
}
