<html>
  <body #primary class="homeBodyComponent">
    <div class="page-container hidden" id="hiddenContainer">
      <div class="card" *ngFor="let visibleCard of visibleCards">
        <div class="cardContent">
          <div class="cardHeader">
            <mat-icon class="cardIcon">{{ visibleCard.icon }}</mat-icon>
            <h2>{{ visibleCard.title }}</h2>
          </div>
          <div class="dividerContainer">
            <div class="horizontalDivider"></div>
          </div>
          <div class="cardBody">
            {{ visibleCard.description }}
          </div>
          <div class="cardLink">
            <button
              *ngIf="!visibleCard.hasMethodCall"
              routerLink="{{ visibleCard.url }}"
              class="cardButton"
            >
              {{ visibleCard.buttonText }}
            </button>
            <button
              *ngIf="visibleCard.hasMethodCall"
              (click)="callMethod(visibleCard.methodCall)"
              class="cardButton"
            >
              {{ visibleCard.buttonText }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </body>
</html>
