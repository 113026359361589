import { Component, OnInit } from '@angular/core';
import { UserManagementAPIService } from 'src/app/Services/UserManagement/user-management-api.service';
import { User } from 'src/app/Interfaces/userInterface';
import { FormControl } from '@angular/forms';
import { map, Observable, startWith } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { NewUserToDepartmentResponseComponent } from './new-user-to-department-response/new-user-to-department-response.component';
import { DepartmentApiService } from 'src/app/Services/DepartmentManagement/department-api.service';

@Component({
  selector: 'app-new-user-to-department-dialog',
  templateUrl: './new-user-to-department-dialog.component.html',
  styleUrls: ['./new-user-to-department-dialog.component.css'],
})
export class NewUserToDepartmentDialogComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<NewUserToDepartmentDialogComponent>,
    public userManagementAPIService: UserManagementAPIService,
    public departmentApiService: DepartmentApiService,
    public route: ActivatedRoute,
    public dialog: MatDialog
  ) {}

  public idDepartment: string;
  public departmentUsers: Array<string>;
  public userList: Array<string> = new Array<string>();
  public userListForm = new FormControl('');
  public options: string[] = ['One', 'Two', 'Three'];
  public filteredOptions: Observable<string[]>;

  ngOnInit(): void {
    this.userManagementAPIService.getUserList('', '', '', '', '').subscribe({
      next: (data) => {
        var json = JSON.stringify(data);
        var jsonParsed = JSON.parse(json);

        for (var key of Object.keys(jsonParsed['Users'])) {
          if (jsonParsed['Users'][key].showUser == 1) {
            this.userList.push(jsonParsed['Users'][key].Username);
          }
        }

        this.filteredOptions = this.userListForm.valueChanges.pipe(
          startWith(''),
          map((value) => this._filter(value || ''))
        );
      },
    });
  }

  checkUserSelected(user: string) {
    if (this.departmentUsers.includes(user)) {
      return false;
    } else if (user == '') {
      return false;
    } else if (!this.userList.includes(user)) {
      return false;
    } else {
      return true;
    }
  }

  public addUserToDepartment() {
    var departments = [this.idDepartment];

    this.departmentApiService
      .addUserToDepartment(this.userListForm.value, departments)
      .subscribe({
        next: () => {
          var refDialog = this.dialog.open(
            NewUserToDepartmentResponseComponent,
            {
              width: '20em',
              height: '10em',
            }
          );

          refDialog.componentInstance.isUserAdded = true;
        },
        error: () => {
          var refDialog = this.dialog.open(
            NewUserToDepartmentResponseComponent,
            {
              width: '20em',
              height: '10em',
            }
          );

          refDialog.componentInstance.isUserAdded = false;
        },
      });
  }

  public close() {
    this.dialogRef.close();
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();

    if (this.departmentUsers.length > 0) {
      for (var i = 0; i < this.departmentUsers.length; i++) {
        if (this.userList.includes(this.departmentUsers[i])) {
          this.userList.splice(
            this.userList.indexOf(this.departmentUsers[i]),
            1
          );
        }
      }
    }

    return this.userList.filter((option) =>
      option.toLowerCase().includes(filterValue)
    );
  }
}
