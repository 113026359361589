<div class="container">
  <div class="section">
    <div class="sectionContent">
      <div class="subSection changePassSection">
        <div class="subsectionTitle" i18n>Change your password</div>
        <div class="subsectionContent">
          <button (click)="changePassword()" mat-raised-button i18n>
            <span class="material-symbols-outlined"> key </span> Change password
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
