import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { UserSessionCheckerService } from '../../../Services/UserManagement/UserSessionChecker/user-session-checker.service';
import { UserTypeNotAllowedDialogComponent } from '../../common/common-error-dialogs/user-type-not-allowed-dialog/user-type-not-allowed-dialog.component';

@Component({
  selector: 'app-faqs',
  templateUrl: './faqs.component.html',
  styleUrls: ['./faqs.component.css'],
})
export class FaqsComponent implements OnInit {
  constructor(
    private userSessionCheckerService: UserSessionCheckerService,
    private dialog: MatDialog,
    private router: Router
  ) {}

  panelOpenState = false;
  public userCapabilities: string[] = JSON.parse(
    localStorage.getItem('userCapabilities')
  );

  ngOnInit(): void {
    this.userSessionCheckerService.checkUserSession();
    if (!this.userCapabilities.includes('VH')) {
      console.log('FAQS: User type not allowed');
      var dialogRef = this.dialog.open(UserTypeNotAllowedDialogComponent, {
        height: '15em',
        width: '40em',
      });

      dialogRef.afterClosed().subscribe(() => {
        this.router.navigate(['/dashboard/profile']);
      });
    }
  }
}
