<head>
  <link
    href="https://cdn.jsdelivr.net/css-toggle-switch/latest/toggle-switch.css"
    rel="stylesheet"
  />
  <link
    rel="stylesheet"
    href="https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@40,400,1,0"
  />
</head>
<body id="body">
  <div class="dashboard-container">
    <mat-toolbar class="headerMenu" #toolbar>
      <div class="headerMenuLeft">
        <button
          mat-button
          class="menuButton"
          type="button"
          mat-button
          (click)="sidenav.toggle()"
          i18n-title="Open side menu"
          title="Open side menu"
        >
          <mat-icon>menu</mat-icon>
        </button>

        <img
          src="assets/img/skleLogos/Skylife_Infinity_web_negro.svg"
          class="menuLogo"
        />
      </div>
      <div class="headerMenuRight">
        <button
          mat-icon-button
          class="userButton"
          aria-label="Profile button"
          i18n-title="User options"
          title="User options"
          [matMenuTriggerFor]="menu"
        >
          <mat-icon class="userButtonIcon">person</mat-icon>
          <mat-menu #menu="matMenu" class="userImageMenu">
            <div class="topHalfMenu">
              <label class="usernameTopRightMenu">{{ username }}</label>
              <button
                mat-button
                class="logoutButton"
                color="primary"
                (click)="logout()"
                i18n
              >
                <mat-icon>exit_to_app</mat-icon>Logout
              </button>
            </div>
            <div class="bottomHalfMenu">
              <button
                mat-button
                color="primary"
                class="changePasswordButton"
                [routerLinkActive]="['active']"
                [routerLink]="['./profile/personal-data']"
                i18n
              >
                <mat-icon>person</mat-icon> Profile
              </button>
              <button
                mat-button
                color="primary"
                class="changePasswordButton"
                (click)="openHoloLensAccessDialog()"
                i18n
              >
                <mat-icon>qr_code_2</mat-icon> QR Access
              </button>
              <button
                *ngIf="currentLanguage == 'Spanish'"
                mat-button
                color="primary"
                class="changePasswordButton"
                (click)="changeLanguage('English')"
              >
                <img
                  src="assets/img/spain.png"
                  alt="Spanish flag"
                  class="flagIcon"
                />
              </button>
              <button
                *ngIf="currentLanguage == 'English'"
                mat-button
                color="primary"
                class="changePasswordButton"
                (click)="changeLanguage('Spanish')"
              >
                <img
                  src="assets/img/uk.png"
                  alt="British flag"
                  class="flagIcon"
                />
              </button>
            </div>
          </mat-menu>
        </button>
      </div>
    </mat-toolbar>

    <mat-sidenav-container class="sidenav-container">
      <mat-sidenav #sidenav mode="side" class="sidenav">
        <div class="menuHeader"></div>
        <mat-action-list>
          <button
            class="sideMenuButton"
            mat-list-item
            [routerLink]="['./home']"
            (click)="sidenav.toggle()"
            i18n
          >
            <mat-icon class="menuItemIcon">dashboard</mat-icon>Dashboard
          </button>
          <button
            class="sideMenuButton"
            mat-list-item
            [routerLink]="['sessions']"
            (click)="sidenav.toggle()"
            *ngIf="userCapabilities.includes('VS')"
          >
            <mat-icon class="menuItemIcon">headset_mic</mat-icon
            ><label i18n>Sessions</label>
          </button>
          <button
            class="sideMenuButton"
            mat-list-item
            [routerLink]="['user-management']"
            (click)="sidenav.toggle()"
            *ngIf="userCapabilities.includes('VU')"
          >
            <mat-icon class="menuItemIcon">people</mat-icon
            ><label i18n>User Management</label>
          </button>

          <button
            class="sideMenuButton"
            mat-list-item
            [routerLink]="['./profile/personal-data']"
            (click)="sidenav.toggle()"
          >
            <mat-icon class="menuItemIcon">person</mat-icon
            ><label i18n>Profile</label>
          </button>
          <button
            class="sideMenuButton"
            mat-list-item
            (click)="sidenav.toggle()"
            *ngIf="userCapabilities.includes('DF')"
            [routerLink]="['downloadable-files']"
          >
            <span class="material-symbols-outlined menuItemIcon">
              download
            </span>
            <label class="text" i18n>Downloads</label>
          </button>

          <button
            class="sideMenuButton"
            mat-list-item
            [matMenuTriggerFor]="menu2"
            *ngIf="userCapabilities.includes('VH')"
            i18n
          >
            <mat-icon class="menuItemIcon">info</mat-icon>Help
            <mat-menu #menu2="matMenu" class="helpMenu">
              <mat-action-list>
                <button
                  class="sideMenuButton"
                  mat-list-item
                  class="col-9"
                  [routerLink]="['user-help']"
                  (click)="sidenav.toggle()"
                >
                  <span class="material-symbols-outlined menuItemIcon">
                    settings
                  </span>
                  <label class="text">Common actions</label>
                </button>
              </mat-action-list>
            </mat-menu>
          </button>

          <div class="legalDiv">
            <span class="copyrightText copyrightTextDashboard" i18n
              >Copyright &copy; Skylife Engineering S.L. {{ currentYear }}</span
            >

            <span class="policyText">
              <a routerLink="/privacy-policy" class="policyTextDashboard" i18n
                >Privacy policy</a
              >
              <br />
              <a routerLink="/legal-policy" class="policyTextDashboard" i18n
                >Legal policy</a
              >
            </span>
          </div>
        </mat-action-list>
      </mat-sidenav>
      <mat-sidenav-content>
        <router-outlet></router-outlet>
      </mat-sidenav-content>
    </mat-sidenav-container>
  </div>
</body>
