import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-replace-file-confirmation',
  templateUrl: './replace-file-confirmation.component.html',
  styleUrls: ['./replace-file-confirmation.component.css'],
})
export class ReplaceFileConfirmationComponent implements OnInit {
  constructor(public dialog: MatDialogRef<ReplaceFileConfirmationComponent>) {}

  public fileName: string;

  public close(confirm: boolean) {
    this.dialog.close(confirm);
  }

  public ngOnInit(): void {}
}
