<html>
  <head>
    <link
      rel="stylesheet"
      href="https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@48,400,0,0"
    />
    <link
      rel="stylesheet"
      href="https://fonts.googleapis.com/css2?family=Material+Symbols+Rounded:opsz,wght,FILL,GRAD@48,400,1,0"
    />
  </head>
  <body class="userManagementBodyComponent hidden" id="hiddenContainer">
    <div class="sessions-container">
      <h1 class="userHeader" i18n>ACTIVATED USERS</h1>
      <div class="filter-buttons">
        <button
          mat-flat-button
          class="filterButton"
          color="primary"
          (click)="filterByStartDates()"
          i18n
        >
          <span class="material-symbols-rounded iconButton"> schedule </span>
          Filter by creation date
        </button>
        <button
          mat-flat-button
          class="filterButton"
          color="primary"
          (click)="openNewUserDialog()"
          i18n
        >
          <span class="material-symbols-rounded iconButton">
            person_add_alt
          </span>
          Add new user
        </button>
        <button
          mat-flat-button
          class="filterButton"
          color="primary"
          (click)="showCheckBox()"
          i18n
        >
          <span class="material-symbols-rounded iconButton">
            person_remove
          </span>
          Disable users
        </button>
        <button
          mat-flat-button
          class="filterButton"
          color="primary"
          (click)="showDisabledUsers()"
          i18n
        >
          <span class="material-symbols-rounded iconButton"> no_accounts </span
          >Show disabled users
        </button>
        <button
          mat-icon-button
          color="primary"
          *ngIf="filtering"
          (click)="reload()"
        >
          <mat-icon>refresh</mat-icon>
        </button>
        <label class="filterLabel" *ngIf="filteringByStartDate" i18n
          >filtering creation date between
        </label>
        <b class="filterLabel" *ngIf="filteringByStartDate">{{
          startDateFormated
        }}</b>
        <label class="filterLabel" *ngIf="filteringByStartDate" i18n>
          and
        </label>
        <b class="filterLabel" *ngIf="filteringByStartDate">{{
          endDateFormated
        }}</b>
      </div>
      <div class="filter-bar">
        <mat-form-field appearance="fill" class="filterUserManagementInput">
          <mat-label i18n>Filter</mat-label>
          <input
            matInput
            (keyup)="applyFilter($event)"
            placeholder="Search by selected fields"
            i18n-placeholder="Search by selected fields placeholder"
            #input
            type="text"
            class="filterInput"
          />
        </mat-form-field>
        <mat-form-field
          appearance="fill"
          class="filter-options selectTextBlack"
        >
          <mat-label i18n class="filterByTitleLabel">Filter by</mat-label>
          <mat-select
            [formControl]="filterOptions"
            [value]="filterSelect"
            multiple
          >
            <mat-select-trigger>
              {{filterOptions.value?.[0] || ''}}
              <span
                *ngIf="(filterOptions.value?.length || 0) > 1"
                class="filter-option-additional-selection"
              >
                (+{{ (filterOptions.value?.length || 0) - 1 }}
                {{ filterOptions.value?.length === 2 ? "other" : "others" }})
              </span>
            </mat-select-trigger>
            <mat-option
              *ngFor="let filterOption of filterOptionList"
              [value]="filterOption"
              >{{ filterOption }}</mat-option
            >
          </mat-select>
        </mat-form-field>
      </div>
      <div class="table-container">
        <table
          mat-table
          [dataSource]="dataSource"
          matSort
          matSortActive="creationDate"
          matSortDirection="desc"
        >
          <ng-container class="select" matColumnDef="select">
            <th mat-header-cell *matHeaderCellDef>
              <mat-checkbox
                (change)="$event ? toggleAllRows() : null"
                [checked]="selection.hasValue() && isAllSelected()"
                [indeterminate]="selection.hasValue() && !isAllSelected()"
                [aria-label]="checkboxLabel()"
              >
              </mat-checkbox>
            </th>
            <td mat-cell *matCellDef="let row">
              <mat-checkbox
                class="checkBox"
                (click)="$event.stopPropagation()"
                (change)="$event ? selection.toggle(row) : null"
                [checked]="selection.isSelected(row)"
                [aria-label]="checkboxLabel(row)"
              >
              </mat-checkbox>
            </td>
          </ng-container>
          <ng-container matColumnDef="username">
            <th
              class="usernameCell"
              mat-header-cell
              *matHeaderCellDef
              mat-sort-header
              i18n
            >
              Username
            </th>
            <td class="usernameCell" mat-cell *matCellDef="let element">
              {{ element.username }}
            </td>
          </ng-container>
          <ng-container matColumnDef="creationDate">
            <th
              class="creationDateCell"
              mat-header-cell
              *matHeaderCellDef
              mat-sort-header
              i18n
            >
              Creation Date
            </th>
            <td class="creationDateCell" mat-cell *matCellDef="let element">
              <label *ngIf="!checkSessionDate(element.creationDate)">
                {{ element.creationDate | date : "dd/MM/yyyy HH:mm" }}
              </label>
              <label *ngIf="checkSessionDate(element.creationDate)" i18n>
                Date not added
              </label>
            </td>
          </ng-container>

          <ng-container matColumnDef="userType">
            <th mat-header-cell *matHeaderCellDef mat-sort-header i18n>
              User type
            </th>
            <td mat-cell *matCellDef="let element">{{ element.userType }}</td>
          </ng-container>

          <ng-container matColumnDef="changePass">
            <th
              class="changeUserPassContainer"
              mat-header-cell
              *matHeaderCellDef
              mat-sort-header
              i18n
            >
              Change password
            </th>
            <td mat-cell *matCellDef="let element" class="changeUserPass">
              <button
                mat-button
                (mouseover)="disableRow()"
                (mouseout)="disableRowOut()"
                (click)="changeUserPass(element.username)"
              >
                <span class="material-symbols-outlined"> key </span>
              </button>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr
            mat-row
            (click)="editUser(row)"
            *matRowDef="let row; columns: displayedColumns"
          ></tr>
        </table>
      </div>
      <div class="paginator">
        <mat-paginator
          [pageSizeOptions]="[5, 10, 20]"
          showFirstLastButtons
          aria-label="Select page"
        >
        </mat-paginator>
      </div>

      <div class="footerButtons">
        <button
          mat-flat-button
          class="disableButton"
          *ngIf="!selection.isEmpty() && removing"
          color="primary"
          (click)="disableUsers()"
          i18n
        >
          Disable
        </button>
        <button
          mat-flat-button
          class="disableButton"
          *ngIf="removing"
          color="primary"
          (click)="cancelDisablingUsers()"
          i18n
        >
          Cancel
        </button>
      </div>
    </div>
  </body>
</html>
