<div class="mc" (keyup.enter)="this.globalFile ? uploadFile() : ''">
  <div class="selectFile">
    <input
      [formControl]="file"
      (change)="onFileSelected($event)"
      type="file"
      placeholder="File"
      i18n-placeholder="File placeholder"
    />
    <label>(max:{{ this.maxSize }}MB)</label>
  </div>

  <mat-error *ngIf="this.overhead" i18n
    >The maximum file size is {{ this.maxSize }}MB</mat-error
  >
  <br />
  <button
    style="margin-top: 20px"
    [disabled]="!this.globalFile || this.overhead"
    (click)="uploadFile()"
    mat-raised-button
    color="primary"
    i18n
  >
    Upload
  </button>
  <button
    style="margin-top: 20px"
    class="buttonCancel"
    (click)="cancelUploadFile()"
    mat-raised-button
    color="primary"
    i18n
  >
    Cancel
  </button>

  <div class="progressBar" *ngIf="isfileUploading" i18n>
    Uploading - {{ progress }}%
    <br />
    <mat-progress-bar
      class="example-margin"
      [color]="color"
      mode="determinate"
      [value]="value"
    >
    </mat-progress-bar>
  </div>
</div>
