import { Component, HostListener, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { UserManagementAPIService } from '../../../../../Services/UserManagement/user-management-api.service';
import { ConfirmationAddNewUser } from '../confirmation-add-new-user/confirmation-add-new-user.component';
import { TypeErrorsAddUserEmailComponent } from '../creation-error-dialogs/type-errors-add-user-email/type-errors-add-user-email.component';
import { UserSessionCheckerService } from '../../../../../Services/UserManagement/UserSessionChecker/user-session-checker.service';
import { UserTypeNotAllowedDialogComponent } from '../../../../common/common-error-dialogs/user-type-not-allowed-dialog/user-type-not-allowed-dialog.component';
import { UserDataApiService } from 'src/app/Services/UserDataService/user-data-api.service';
import { Department } from 'src/app/Interfaces/departmentInterface';
import { map, Observable, startWith } from 'rxjs';
import { FormControl, Validators } from '@angular/forms';

@Component({
  selector: 'app-add-new-user',
  templateUrl: './add-new-user.component.html',
  styleUrls: ['./add-new-user.component.scss'],
})
export class AddNewUserComponent implements OnInit {
  constructor(
    public userManagementAPIService: UserManagementAPIService,
    public userDataApiService: UserDataApiService,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<AddNewUserComponent>,
    public router: Router,
    private userSessionCheckerService: UserSessionCheckerService
  ) {}

  public USEREXISTBUTDISABLED = 'User exists but is disabled';
  public adminLicenses: number;
  public currentAdminLicenses: number;
  public currentExpertLicenses: number;
  public currentOperatorLicenses: number;

  public email = new FormControl('', [Validators.required, Validators.email]);
  public name = new FormControl('', [Validators.required]);
  public surname = new FormControl('', [Validators.required]);
  public userTypes = new FormControl('', [Validators.required]);
  public userLanguages = new FormControl('', [Validators.required]);
  public selected = 'Spanish';

  public expertLicenses: number;
  public operatorLicenses: number;
  public spinner = false;
  public token = '';
  public advanced = false;
  public edit = false;
  public departmentsAdded = new Array<Department>();
  public departmentsOwner = new Array<Department>();
  public departmentsName = new Array<string>();
  public departmentsID = new Array<string>();
  public avaliableDepartmentsName = new Array<string>();
  public departmentsOption: Observable<string[]>;
  public form = new FormControl('');

  public userCapabilities: string[] = JSON.parse(
    localStorage.getItem('userCapabilities')
  );

  @HostListener('window:resize', ['$event'])
  public onResize(event: UIEvent) {
    const w = event.target as Window;
    this.setDialogSizeDependingOnWidth(w.innerWidth);
  }

  private setDialogSizeDependingOnWidth(width: number) {
    if (this.advanced) {
      if (width < 730) {
        this.dialogRef.updateSize('23em', '60em');
        document.getElementById('container').style.overflowY = 'scroll';
      } else {
        this.dialogRef.updateSize('60em', '34em');
        document.getElementById('container').style.overflowY = 'hidden';
      }
    }
  }

  public cancelAddNewUser() {
    this.dialogRef.close();
  }

  public checkEmail(eMail: string): boolean {
    // eslint-disable-next-line no-useless-escape
    if (eMail.match(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g)) {
      return true;
    }
    return false;
  }

  public updateScroll() {
    var departmentList = document.querySelector('#departmentList');

    setTimeout(() => {
      departmentList.scrollTop =
        departmentList.scrollHeight - departmentList.clientHeight;
    }, 10);
  }

  private setScrollOnSmallScreen(smallScreen: boolean) {
    if (smallScreen) {
      if (this.advanced) {
        document.getElementById('container').style.overflowY = 'scroll';
      } else {
        document.getElementById('container').style.overflowY = 'hidden';
      }
    }
  }

  private filterDepartmetsOptions() {
    this.avaliableDepartmentsName = this.departmentsName.filter(
      (department) => !this.checkDepartmentNameRepeat(department)
    );

    this.departmentsOption = this.form.valueChanges.pipe(
      startWith(''),
      map((value) => this._filter(value || ''))
    );
  }

  public noMoreDepartments() {
    if (this.avaliableDepartmentsName.length == 0) {
      (<HTMLInputElement>(
        document.getElementById('newDepartInput')
      )).value = $localize`There are no more departments`;
    }
  }

  private _filter(value: string): string[] {
    if (value != '') {
      const filterValue = value.toLowerCase();

      return this.avaliableDepartmentsName.filter((option) =>
        option.toLowerCase().includes(filterValue)
      );
    } else {
      return this.avaliableDepartmentsName;
    }
  }

  private checkDepartmentNameRepeat(name: string) {
    var exist = false;
    this.departmentsOwner.forEach((element) => {
      if (element.departmentName == name) {
        exist = this.checkDepartmentRepeat(element);
      }
    });

    return exist;
  }

  public checkDepartmentAvailable(newDepart: string) {
    var depart = <Department>{};
    this.departmentsOwner.forEach((element) => {
      if (element.departmentName == newDepart) {
        depart = element;
      }
    });

    if (!this.checkDepartmentOwner(depart)) {
      return false;
    } else if (this.checkDepartmentRepeat(depart)) {
      return false;
    } else {
      return true;
    }
  }

  private checkDepartmentOwner(depart: Department): boolean {
    return this.departmentsOwner.includes(depart);
  }

  private checkDepartmentRepeat(depart: Department): boolean {
    return this.departmentsAdded.includes(depart);
  }

  public addDepart(newDepart: string) {
    this.departmentsOwner.forEach((depart) => {
      if (depart.departmentName == newDepart) {
        this.departmentsAdded.push(depart);
        this.departmentsID.push(depart.iddepartment);
      }
    });

    this.filterDepartmetsOptions();
    (<HTMLInputElement>document.getElementById('newDepartInput')).value = '';
  }

  public editProfile() {
    this.edit = true;
  }

  public deleteDepart(dep: Department) {
    this.removeNewDepart(dep);

    this.filterDepartmetsOptions();
  }

  private removeNewDepart(dep: Department) {
    var index = 0;
    this.departmentsAdded.forEach((department) => {
      if (department.iddepartment == dep.iddepartment) {
        this.departmentsID.splice(index, 1);
        this.departmentsAdded.splice(index, 1);
      }
      index++;
    });
  }

  private getDepartmentsOwn() {
    this.userDataApiService.getDepartmentsOwner().subscribe({
      next: (result) => {
        var json = JSON.stringify(result);
        var jsonParsed = JSON.parse(json);

        var depart;

        for (depart in jsonParsed) {
          this.departmentsOwner.push(jsonParsed[depart]);
          this.departmentsName.push(jsonParsed[depart].departmentName);
        }

        this.filterDepartmetsOptions();
      },
      error: (err) => {
        console.error(err);
      },
    });
  }

  public ngOnInit(): void {
    if (!this.userCapabilities.includes('EU')) {
      console.log('ADD NEW USER: User type not allowed');
      var dialogRef = this.dialog.open(UserTypeNotAllowedDialogComponent, {
        height: '15em',
        width: '40em',
      });

      dialogRef.afterClosed().subscribe(() => {
        this.router.navigate(['/dashboard/home']);
      });
    } else {
      this.userManagementAPIService.getLicensesNumber().subscribe({
        next: (result) => {
          var json = JSON.stringify(result);
          var jsonParsed = JSON.parse(json);

          this.adminLicenses = parseInt(jsonParsed.adminLimit, 10);
          this.expertLicenses = parseInt(jsonParsed.expertLimit, 10);
          this.operatorLicenses = parseInt(jsonParsed.operatorLimit, 10);
          this.currentAdminLicenses = parseInt(jsonParsed.currentAdmins, 10);
          this.currentExpertLicenses = parseInt(jsonParsed.currentExperts, 10);
          this.currentOperatorLicenses = parseInt(
            jsonParsed.currentOperators,
            10
          );
        },
        error: () => {},
      });
      this.getDepartmentsOwn();
    }
  }

  public onAddNewUser(
    username: string,
    userType: string,
    name: string,
    surname: string,
    language: string
  ): void {
    this.spinner = true;
    if (this.checkUserSession()) {
      this.userManagementAPIService
        .checkUserAvailability(username, userType)
        .subscribe({
          next: () => {
            this.userManagementAPIService
              .addNewUserCognito(username)
              .subscribe({
                next: () => {
                  this.userManagementAPIService
                    .addNewUserDB(
                      username,
                      userType,
                      name,
                      surname,
                      this.departmentsID,
                      language
                    )
                    .subscribe({
                      next: () => {
                        this.dialogRef.close();
                        var refDialog = this.dialog.open(
                          ConfirmationAddNewUser
                        );
                        refDialog.componentInstance.addUserSuccess = true;
                      },
                      error: () => {
                        this.spinner = false;
                        this.dialogRef.close();
                        this.userManagementAPIService
                          .removeUserCognito(username)
                          .subscribe({
                            next: () => {},
                            error: (err) => {
                              console.error(err);
                            },
                          });
                        this.userManagementAPIService
                          .removeUserCognitoWeb(username)
                          .subscribe({
                            next: () => {},
                            error: (err) => {
                              console.error(err);
                            },
                          });
                        var refDialog = this.dialog.open(
                          ConfirmationAddNewUser
                        );
                        refDialog.componentInstance.addUserSuccess = false;
                      },
                    });
                },
                error: (error) => {
                  this.spinner = false;
                  console.error(error);
                  this.userManagementAPIService
                    .removeUserCognitoWeb(username)
                    .subscribe({
                      next: () => {},
                      error: (err) => {
                        console.error(err);
                      },
                    });
                  var refDialog = this.dialog.open(ConfirmationAddNewUser);
                  refDialog.componentInstance.addUserSuccess = false;
                },
              });
          },
          error: (error: Response) => {
            this.spinner = false;
            var json = JSON.stringify(error);
            var jsonParsed = JSON.parse(json);
            console.error(error);
            var refDialog = this.dialog.open(TypeErrorsAddUserEmailComponent);
            refDialog.componentInstance.errorType = jsonParsed.error.status;
            refDialog.componentInstance.numberOfUsers =
              jsonParsed.error.numberOfUsers;
            refDialog.componentInstance.userLimit = jsonParsed.error.userLimit;
          },
        });
    }
  }

  private checkUserSession(): boolean {
    this.userSessionCheckerService.checkUserSession();
    return this.userSessionCheckerService.check;
  }
}
