<div class="container">
  <div class="form-field">
    <label class="forgotPassHeader" i18n>FORGOT PASSWORD</label>
    <label *ngIf="!this.emailSend" i18n
      >Enter your email to change your password:
    </label>
    <mat-form-field
      *ngIf="!this.emailSend"
      class="example-full-width"
      appearance="fill"
    >
      <mat-label i18n>User name</mat-label>
      <input
        placeholder="User email"
        i18n-placeholder="User email placeholder"
        [formControl]="userName"
        matInput
        (keyup.enter)="
          !userName.invalid ? sendConfirmationCode(userName.value) : ''
        "
        required
        i18n
      />
    </mat-form-field>
    <mat-form-field
      *ngIf="this.emailSend"
      class="example-full-width"
      appearance="fill"
    >
      <mat-label i18n>Confirmation Code</mat-label>
      <input
        placeholder="Code"
        i18n-placeholder="Code placeholder"
        [formControl]="confirmationCode"
        matInput
        (keyup.enter)="
          !confirmationCode.invalid
            ? changePassword(
                userName.value,
                confirmationCode.value,
                newPass.value
              )
            : ''
        "
        required
        i18n
      />
    </mat-form-field>
    <mat-form-field
      *ngIf="this.emailSend"
      class="example-full-width"
      appearance="fill"
    >
      <mat-label i18n>New password:</mat-label>
      <input
        type="password"
        placeholder="new password"
        i18n-placeholder="new password placeholder"
        [formControl]="newPass"
        matInput
        (keyup.enter)="
          !confirmationCode.invalid
            ? changePassword(
                userName.value,
                confirmationCode.value,
                newPass.value
              )
            : ''
        "
        required
        i18n
      />
    </mat-form-field>
    <mat-form-field
      *ngIf="this.emailSend"
      class="example-full-width"
      appearance="fill"
    >
      <mat-label i18n>Repeat new password:</mat-label>
      <input
        type="password"
        placeholder="new password"
        i18n-placeholder="new password placeholder"
        [formControl]="newRepeatedPassword"
        (keyup.enter)="
          !confirmationCode.invalid
            ? changePassword(
                userName.value,
                confirmationCode.value,
                newPass.value
              )
            : ''
        "
        matInput
        required
        i18n
      />
    </mat-form-field>
    <label *ngIf="this.emailSend" class="errorPassw">{{
      repeatPasswordError()
    }}</label>
  </div>

  <label *ngIf="this.emailSend" class="information" i18n
    >A confirmation code has been sent to your email.
  </label>
  <label class="errorMessage" *ngIf="this.notValid" i18n
    >Please, enter a valid user.
  </label>
  <button
    *ngIf="this.emailSend"
    class="confirmButton"
    [disabled]="confirmationCode.invalid"
    mat-flat-button
    (click)="
      changePassword(userName.value, confirmationCode.value, newPass.value)
    "
    i18n
  >
    Change password
  </button>
  <button
    *ngIf="!this.emailSend"
    class="confirmButton"
    [disabled]="userName.invalid"
    mat-flat-button
    (click)="sendConfirmationCode(userName.value)"
    i18n
  >
    Send code
  </button>
  <button
    class="confirmButton"
    mat-flat-button
    (click)="cancelForgotPassword()"
    i18n
  >
    Cancel
  </button>
</div>
