import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { map, Observable, startWith } from 'rxjs';
import { DepartmentApiService } from 'src/app/Services/DepartmentManagement/department-api.service';
import { UserManagementAPIService } from 'src/app/Services/UserManagement/user-management-api.service';
import { ConfirmationRemoveUserFromDepartmentComponent } from './confirmation-remove-user-from-department/confirmation-remove-user-from-department.component';

@Component({
  selector: 'app-remove-user-from-department-dialog',
  templateUrl: './remove-user-from-department-dialog.component.html',
  styleUrls: ['./remove-user-from-department-dialog.component.css'],
})
export class RemoveUserFromDepartmentDialogComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<RemoveUserFromDepartmentDialogComponent>,
    public userManagementAPIService: UserManagementAPIService,
    public departmentApiService: DepartmentApiService,
    public route: ActivatedRoute,
    public dialog: MatDialog
  ) {}

  public idDepartment: string;
  public departmentUsers: Array<string>;
  public userList: Array<UserDepartment> = new Array<UserDepartment>();
  public userListNames: Array<string> = new Array<string>();
  public userListForm = new FormControl('');
  public filteredOptions: Observable<string[]>;

  ngOnInit(): void {
    this.departmentApiService
      .getUserListFromDepartment(this.idDepartment)
      .subscribe({
        next: (data) => {
          var json = JSON.stringify(data);
          var jsonParsed = JSON.parse(json);

          jsonParsed.userlist.forEach((userData) => {
            var user = <UserDepartment>{};

            user.username = userData.username;
            user.idDepartmentUser = userData.iddepartmentUser;
            this.userList.push(user);
          });

          this.filteredOptions = this.userListForm.valueChanges.pipe(
            startWith(''),
            map((value) => this._filter(value || ''))
          );
        },
      });
  }

  checkUserSelected(user: string) {
    if (!this.departmentUsers.includes(user)) {
      return false;
    } else if (user == '') {
      return false;
    } else {
      return true;
    }
  }

  public removeUserFromDepartment(username: string) {
    var idDepartmentUsers = [];
    this.userList.forEach((element) => {
      if (element.username == username) {
        idDepartmentUsers.push(element.idDepartmentUser);
        this.userListNames.push(element.username);
      }
    });

    var dialog = this.dialog.open(
      ConfirmationRemoveUserFromDepartmentComponent,
      {
        width: '30em',
        height: '18em',
      }
    );

    dialog.componentInstance.idDepartmentUsers = idDepartmentUsers;
    dialog.componentInstance.userNames = this.userListNames;

    this.dialogRef.close();

    this.userList = [];
  }

  public close() {
    this.dialogRef.close();
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.departmentUsers.filter((option) =>
      option.toLowerCase().includes(filterValue)
    );
  }
}
export interface UserDepartment {
  username: string;
  idDepartmentUser: string;
}
