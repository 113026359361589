import { Component, OnInit } from '@angular/core';
import { Session } from '../../../../Interfaces/sessionInterface';
import { SessionsManagementAPIService } from '../../../../Services/CallsManagement/sessions-management-api.service';
import { ActivatedRoute } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { RemovalMediaConfirmationComponent } from './removal-media-confirmation/removal-media-confirmation.component';
import '@angular/localize/init';

import { AddMediaComponent } from './add-media/add-media.component';
import { UserSessionCheckerService } from '../../../../Services/UserManagement/UserSessionChecker/user-session-checker.service';

@Component({
  selector: 'app-sessions-report',
  templateUrl: './sessions-report.component.html',
  styleUrls: ['./sessions-report.component.scss'],
})
export class SessionsReportComponent implements OnInit {
  constructor(
    private sessionsManagementAPIService: SessionsManagementAPIService,
    private route: ActivatedRoute,
    public dialog: MatDialog,
    private userSessionCheckerService: UserSessionCheckerService
  ) {}

  public arrayOfImages = new Array<string>();
  public arrayOfOtherMedia = new Array<string>();
  public jsonValuesModel: Array<string> = [];
  public mediaArray = new Array<string>();
  public notEnabled = true;
  public reportJsonObject: any = {};
  public reportJsonObjectArray: Array<any> = [];
  public reportJsonObjectMap = new Map<string, string>();
  public session = <Session>{};
  public values = '';
  public videoArray = new Array<string>();

  public cancelEditReportFields(): void {
    window.location.reload();
  }

  //check if key has an array inside
  public checkIfArray(key: string): boolean {
    var keyValue = this.reportJsonObjectMap.get(key);
    var keyValueAny: string | any = keyValue;

    if (keyValueAny instanceof Array) {
      return true;
    } else {
      return false;
    }
  }

  public checkItemValueTypeIMG(value: string): boolean {
    //check if value is a url

    if (
      value.includes('.png') ||
      value.includes('.jpg') ||
      value.includes('.jpeg') ||
      value.includes('.gif') ||
      value.includes('.bmp') ||
      value.includes('.tiff') ||
      value.includes('.svg') ||
      value.includes('.jfif')
    ) {
      return true;
    } else {
      return false;
    }
  }

  public checkItemValueTypeVideo(value: string): boolean {
    //check if value is a url

    if (
      value.includes('.mp4') ||
      value.includes('.mov') ||
      value.includes('.avi') ||
      value.includes('.wmv') ||
      value.includes('.flv') ||
      value.includes('.mkv') ||
      value.includes('.mpeg4')
    ) {
      return true;
    } else {
      return false;
    }
  }

  public clearArrays() {
    this.arrayOfImages = [];
    this.arrayOfOtherMedia = [];
    this.videoArray = [];
  }

  public editReportFields(): void {
    this.notEnabled = false;
  }

  public getSession(): void {
    this.clearArrays();

    var sessionId = this.route.snapshot.paramMap.get('sessionId')!;

    this.sessionsManagementAPIService
      .getSessions(sessionId, '', '', '', '')
      .subscribe({
        next: (data: string) => {
          var json = JSON.stringify(data);
          var jsonParsed = JSON.parse(json);

          this.reportJsonObject = JSON.parse(jsonParsed[sessionId].report);

          for (var key in this.reportJsonObject) {
            this.reportJsonObjectMap.set(key, this.reportJsonObject[key]);
          }

          //get the values of the key imagenes from the map
          this.reportJsonObjectMap.forEach((value, key) => {
            if (this.checkIfArray(key)) {
              this.values = value;
            }
          });

          //split values variable by comma and push to a new variable
          var valuesArray = this.values.toString().split(',');
          valuesArray.forEach((element) => {
            if (this.checkItemValueTypeVideo(element.toLowerCase())) {
              this.videoArray.push(element);
            } else if (this.checkItemValueTypeIMG(element.toLowerCase())) {
              this.arrayOfImages.push(element);
            } else {
              this.arrayOfOtherMedia.push(element);

              this.arrayOfOtherMedia = this.arrayOfOtherMedia.filter(
                (element) => {
                  return element !== '';
                }
              );
            }
          });
          this.session.id = sessionId;
          this.session.localId = jsonParsed[sessionId].localId;
          this.session.remoteId = jsonParsed[sessionId].remoteId;
          this.session.status = jsonParsed[sessionId].status;
          this.session.start = jsonParsed[sessionId].start;
          this.session.end = jsonParsed[sessionId].end;
          this.session.report = jsonParsed[sessionId].report;
        },
        error: (error) => {
          console.error('There was an error!', error);
        },
      });
  }

  //method that check if reportJsonObjectMap has any key and return true if it has
  public hasKey(): boolean {
    if (this.reportJsonObjectMap.size > 0) {
      return true;
    } else {
      return false;
    }
  }

  public ngOnInit(): void {
    this.userSessionCheckerService.checkUserSession();

    this.getSession();
    var sessionId = this.route.snapshot.paramMap.get('sessionId')!;
    window.localStorage.setItem('sessionId', sessionId);
  }

  public openAddMediaDialog(): void {
    var dialogRef = this.dialog.open(AddMediaComponent, {
      height: '300px',
      width: '400px',
    });

    dialogRef.afterClosed().subscribe(() => {});
  }

  public openDialog(id: string): void {
    const dialogRef = this.dialog.open(RemovalMediaConfirmationComponent, {
      height: '200px',
      width: '400px',
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result == 'remove') {
        //primero eliminamos la imagen visualmente
        document.getElementById(id)!.remove();

        //obtenemos los valores de imagenes y lo metemos en una array

        this.reportJsonObjectMap.forEach((value, key) => {
          if (this.checkIfArray(key)) {
            this.values = value;
          }
        });

        //split values variable by comma and push to a new variable
        var valuesArray = this.values.toString().split(',');
        valuesArray.forEach((element) => {
          this.mediaArray.push(element);
        });
        //comprobar si alguna parte del id está dentro de la array
        this.mediaArray.forEach((element) => {
          id.toString().includes(element)
            ? this.mediaArray.splice(this.mediaArray.indexOf(element), 1)
            : null;
        });

        for (var i = 0; i < Object.keys(this.reportJsonObject).length; i++) {
          if (Object.values(this.reportJsonObject)[i] instanceof Array) {
            var aux = Object.values(this.reportJsonObject)[i] as Array<string>;
            var j;
            for (j = 0; j < aux.length; j++) {
              if (id == aux[j]) {
                break;
              }
            }
            aux.splice(j, 1);
            Object.values(this.reportJsonObject)[i] = aux;
            break;
          }
        }

        for (var key in this.reportJsonObject) {
          this.reportJsonObjectMap.set(key, this.reportJsonObject[key]);
        }

        var sessionId = this.route.snapshot.paramMap.get('sessionId')!;

        this.sessionsManagementAPIService
          .updateSessionReport(sessionId, this.reportJsonObjectMap)
          .subscribe({
            next: () => {
              window.location.reload();
            },
            error: (error) => {
              console.error('There was an error!', error);
            },
          });
      }
    });
  }

  public saveReportFields(): void {
    var sessionId = this.route.snapshot.paramMap.get('sessionId')!;
    window.localStorage.setItem('sessionId', sessionId);

    this.sessionsManagementAPIService
      .updateSessionReport(sessionId, this.reportJsonObjectMap)
      .subscribe({
        next: () => {
          window.location.reload();
        },
        error: (error) => {
          console.error(error);
        },
      });
  }

  //method that given a key and a new value for that key, update the value of the key
  public updateValue(key: string, newValue: string): void {
    this.reportJsonObjectMap.set(key, newValue);
  }
}
