import { SelectionModel } from '@angular/cdk/collections';
import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-welcome-dialog',
  templateUrl: './welcome-dialog.component.html',
  styleUrls: ['./welcome-dialog.component.css'],
})
export class WelcomeDialogComponent implements OnInit {
  constructor(private dialog: MatDialogRef<WelcomeDialogComponent>) {}

  public pageNumber = 0;
  public selected = false;
  public selection = new SelectionModel<string>(true, []);
  public welcomePage = true;

  public advance() {
    this.welcomePage = false;
    this.pageNumber++;
  }

  public close() {
    this.dialog.close({ selected: this.selected });
  }

  public closeForever() {
    this.selected = true;
    this.close();
  }

  public goBack() {
    this.pageNumber--;
  }

  public ngOnInit(): void {}
}
