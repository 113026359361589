<div class="content">
  <Label class="tokenLabel" *ngIf="errorType == 'User type is full'" i18n
    >The user cannot be created.<br />The limit of users of this type ({{
      userLimit
    }}) has been reached
  </Label>
  <Label class="tokenLabel" *ngIf="errorType == 'User already exists'" i18n
    >User already exists, please choose a different username
  </Label>
  <Label class="tokenLabel" *ngIf="errorType == 'Invalid domain'" i18n
    >The email's domain is not valid, please provide an email with a valid one
  </Label>
</div>
<div>
  <button class="confirmButton" mat-button (click)="closeDialog()" i18n>
    Back
  </button>
</div>
