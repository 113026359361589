<head>
  <link
    rel="stylesheet"
    href="https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@24,400,1,0"
  />
  <link
    rel="stylesheet"
    href="https://fonts.googleapis.com/css2?family=Material+Symbols+Rounded:opsz,wght,FILL,GRAD@48,400,1,0"
  />
</head>
<body>
  <div class="content">
    <div class="textContent">
      <div class="welcomePage" *ngIf="this.pageNumber == 0">
        <h2 i18n>BIENVENIDO A</h2>
        <img
          class="mainLogo"
          src="../../../../assets/img/LogoSkylife.png"
          alt=""
        />
        <h1 class="skylifeTitle" i18n>SKYLIFE INFINITY MENTOR</h1>
        <label class="welcomeQuestion" i18n
          >¿Desea recibir una guía tutorial sobre la instalación de SKYLIFE
          MENTOR?</label
        >
        <div class="welcomeButtons">
          <button (click)="advance()" mat-button i18n>SI</button>
          <button (click)="close()" mat-button i18n>NO</button>
        </div>

        <div i18n>
          <input
            type="checkbox"
            [checked]="selected"
            (change)="selected = !selected"
          />No volver a mostrar
        </div>
      </div>

      <div class="frontPage" *ngIf="this.pageNumber == 1">
        <div class="skylifeImage">
          <img
            class="mainLogo"
            src="../../../../assets/img/LogoSkylife.png"
            alt=""
          />
          <h1 class="skylifeTitle">SKYLIFE INFINITY MENTOR</h1>
          <label>Guía de instalación</label>
          <label>APP Hololens</label>
          <img
            class="hololensImage"
            src="../../../../assets/img/hololens.png"
            alt=""
          />
        </div>
        <div class="info">
          <label class="infoItem" i18n
            >DOCUMENTO: Guía de instalación APP HoloLens</label
          >
          <label class="infoItem" i18n>REFERENCIA: SKLE-G-00000 </label>
          <label class="infoItem" i18n>PRODUCTO: INFINITY MENTOR</label>
          <label class="infoItem" i18n>VERSIÓN: 0.2</label>
          <label class="infoItem" i18n>FECHA VERSIÓN: 22/12/2022</label>

          <label class="notice"
            >*Este documento incluye información de precios y también puede
            contener información privilegiada y confidencial. Es para uso
            exclusivo del destinatario/s.</label
          >
        </div>
      </div>

      <div class="index" *ngIf="this.pageNumber == 2">
        <h1 i18n>ÍDICE</h1>
        <ol class="index">
          <label i18n>Índce <label class="numberPage">2</label> </label>
          <li i18n>Introducción<label class="numberPage">3</label></li>
          <li i18n>Preparación previa<label class="numberPage">3</label></li>
          <li i18n>Descarga de ficheros<label class="numberPage">3</label></li>
          <ol>
            <li class="idexItem" i18n>
              Descarga directa desde Hololens<label class="numberPage">3</label>
            </li>
            <li class="idexItem" i18n>
              Descarga a través de PC<label class="numberPage">4</label>
            </li>
          </ol>
          <li class="idexItem" i18n>
            Instalación de los ficheros<label class="numberPage">5</label>
          </li>
          <ol>
            <li class="idexItem" i18n>
              Instalación de certificado<label class="numberPage">5</label>
            </li>
            <li i18n>
              <label class="indexItem"
                >Ejecución de instalador de INFINITY MENTOR</label
              >
              <label class="numberPage">5</label>
            </li>
          </ol>
        </ol>
      </div>

      <div class="thirdPage" *ngIf="this.pageNumber == 3">
        <h1 i18n>1 Introducción</h1>
        <label i18n
          >Esta guía define los pasos necesarios para instalar en las gafas
          inteligentes HOLOLENS 2 de Microsoft el producto
          <b>SKYLIFE INFINITY MENTOR</b>.</label
        >
        <h1 i18n>2 Preparación previa</h1>
        <label i18n
          >Si usted ya ha usado previamente estas gafas, puede saltar
          directamente a la sección 3-Descarga de ficheros</label
        >
        <ul>
          <li i18n>
            Revise el manual suministrado en la caja para la colocación correcta
            el dispositivo y el ajuste necesario
          </li>
          <li i18n>
            Encienda las gafas, colóqueselas y siga las instrucciones que
            aparecen en pantalla
          </li>
          <li i18n>
            El departamento TIC de su empresa le facilitará credenciales para
            entrar como entorno empresa o como entorno particular
          </li>
          <li i18n>
            No se aconseja activar el reconocimiento IRIS; La protección con
            contraseña es suficiente
          </li>
          <li i18n>
            No se recomienda activar el reconocimiento por voz ya que esta
            funcionalidad suele interferir con las aplicaciones que incluyen
            conversaciones, activando comandos no deseados
          </li>
          <li i18n>
            Se recomienda hacer el recorrido completo de los asistentes de
            Microsoft para el conocimiento del dispositivo. En particular el
            asistente de uso, en el icono “Recomendaciones” del menú de Inicio
          </li>
        </ul>
        <h1 i18n>3 Descarga de ficheros</h1>
        <label i18n
          >La descarga de los ficheros necesarios para la instalación de
          INFINITY MENTOR puede llevarse a cabo directamente en las HoloLens o a
          través de un PC con Windows.</label
        >
        <h2>3.1 Descarga directa desde Hololens</h2>
        <label i18n
          >Es un requisito importante tener actualizado el sistema operativo de
          las HoloLens a su última versión. Para instalar INFINITY MENTOR en las
          gafas inteligentes se necesitan los dos archivos abajo indicados, que
          se pueden descargar directamente desde las hololens con el siguiente
          enlace:</label
        >
        <ul>
          <li>
            Enlace:
            <link
              rel="stylesheet"
              href="https://skylifeeng.sharepoint.com/:f:/s/SOFTWARE/En4xA-ffttxIkXIau3fT9cMBDi-1fUSjCe0vjhdbkIVZWQ?e=lgbOPy"
            />
          </li>
          <ul>
            <li i18n>
              Skylife_Digital_Assistant_x.x.x.x_ARM.appx (Instalable)
            </li>
            <li i18n>
              Skylife_Digital_Assistant_x.x.x.x_ARM.cer (Certificado)
            </li>
          </ul>
        </ul>
      </div>

      <div class="lastPage" *ngIf="this.pageNumber == 4">
        <h2>3.2 Descarga a través de PC</h2>
        <label i18n
          >También puede descargar los ficheros desde un PC y luego copiarlos a
          las HoloLens a través del cable de carga o de una memoria USB. Para
          ello, desde un PC con Windows, descargue los ficheros de la sección
          anterior.</label
        >
        <ol type="a">
          <li i18n>Para copiar archivos a través del cable de carga.</li>
          <img
            class="hololensImage"
            src="../../../../assets/img/cableHololens.png"
            alt=""
          />
          <ul>
            <li i18n>
              Conecte las gafas al Pc a través del cable de carga, con conector
              USB-C. Si su ordenador no dispone de este tipo de puerto
              necesitará un adaptador
            </li>
            <li i18n>
              Nos aseguramos de que las HoloLens están encendidas y hemos
              introducido la contraseña de dispositivo, para que nos deje
              acceder desde el PC
            </li>
            <li i18n>
              En el explorador aparecerá un dispositivo llamado
              HOLOLENS-(######) y en su interior un disco "INTERNAL STORAGE"
            </li>
            <li i18n>
              Lo abrimos y en la carpeta DOCUMENTOS copiamos los 2 ficheros
              descargados
            </li>
            <li i18n>
              Desconectamos el dispositivo, y desenchufamos el cable; nos
              colocamos las gafas
            </li>
          </ul>
          <br />
          <li i18n>Para copiar archivos a través de memoria USB</li>
          <ul>
            <li i18n>
              En el PC, copie los ficheros descargados a la memoria USB
            </li>
            <li i18n>Conecte la memoria USB en las Hololens</li>
            <li i18n>Colóquese las gafas</li>
            <li i18n>
              Abra el explorador de archivos y copie los dos archivos desde la
              memoria USB a la carpeta DOCUMENTOS
            </li>
          </ul>
          <img
            class="usbImage"
            src="../../../../assets/img/penDrive.jpg"
            alt=""
          />
        </ol>
      </div>

      <div class="lastPage" *ngIf="this.pageNumber == 5">
        <h1 i18n>4 Instalación de los ficheros</h1>
        <label i18n
          >Antes de ejecutar el programa de instalación de
          <b>INFINITY MENTOR</b> es necesario instalar un certificado de
          confianza:</label
        >
        <h2 i18n>4.2 Instalación de certificado:</h2>
        <ul>
          <li i18n>
            En el menú de inicio, pulsamos en "configuración" y luego entramos
            en "Actualización y seguridad"
          </li>
          <li i18n>
            Seleccionamos la pestaña "certificados" y pulsamos en "instalar un
            certificado"
          </li>
          <li i18n>
            Entramos en "Importar un archivo". Seleccionamos el certificado
            descargado, que está en DOCUMENTOS, con icono:
            <mat-icon>file</mat-icon>
          </li>
          <li i18n>Como ubicación del almacén marcamos "equipo local"</li>
          <li i18n>
            Y en el desplegable "Almacén de certificados" seleccionamos "root" y
            terminamos con "Instalar"
          </li>
          <li i18n>Podemos cerrar la ventana de configuración</li>
        </ul>
        <h2>4.2 Ejecución de instalador de INFINITY MENTOR:</h2>
        <ul>
          <li i18n>
            Abrimos el menú de inicio y a su derecha, pulsamos sobre "Todas"
          </li>
          <li i18n>
            Veremos todas las aplicaciones instaladas y entramos en el
            "Explorador de archivos"
          </li>
          <li i18n>
            Entramos en la carpeta DOCUMENTOS. Si no vemos las carpetas,
            pulsamos el icono con forma de carpeta de la izquierda
          </li>
          <li i18n>
            Ejecutamos el Instalador
            "Skylife_Digital_Assistant_1.2.0.0_ARM.appx” y aceptamos "instalar"
          </li>
          <li i18n>
            Si lo hemos marcado, la aplicación se iniciará tras terminar la
            instalación.
          </li>
          <li i18n>
            Aceptamos los correspondientes permisos que necesita la aplicación
            para funcionar
          </li>
          <li i18n>
            Es necesario reiniciar la aplicación para que los permisos surtan
            efectos
          </li>
          <li i18n>
            En cualquier momento podemos acceder a la aplicación a través de
            "todas las aplicaciones" del menú de inicio
          </li>
          <li i18n>
            Para mejor interacción al introducir el usuario y la contraseña, se
            recomienda colocar abajo la ventana de la aplicación y arriba el
            teclado
          </li>
        </ul>
      </div>
    </div>
    <div class="top">
      <div class="buttons">
        <button
          (click)="goBack()"
          *ngIf="this.pageNumber != 1 && !welcomePage"
          class="goBack"
          mat-button
          i18n
        >
          <mat-icon class="material-symbols-rounded"> arrow_back </mat-icon>
        </button>
        <div *ngIf="this.pageNumber > 1">
          <mat-icon *ngIf="this.pageNumber == 2" class="bigCircle">
            fiber_manual_record
          </mat-icon>
          <mat-icon *ngIf="this.pageNumber != 2" class="smallCircle">
            fiber_manual_record
          </mat-icon>
          <mat-icon *ngIf="this.pageNumber == 3" class="bigCircle">
            fiber_manual_record
          </mat-icon>
          <mat-icon *ngIf="this.pageNumber != 3" class="smallCircle">
            fiber_manual_record
          </mat-icon>
          <mat-icon *ngIf="this.pageNumber == 4" class="bigCircle">
            fiber_manual_record
          </mat-icon>
          <mat-icon *ngIf="this.pageNumber != 4" class="smallCircle">
            fiber_manual_record
          </mat-icon>
          <mat-icon *ngIf="this.pageNumber == 5" class="bigCircle">
            fiber_manual_record
          </mat-icon>
          <mat-icon *ngIf="this.pageNumber != 5" class="smallCircle">
            fiber_manual_record
          </mat-icon>
        </div>
        <button
          (click)="advance()"
          *ngIf="this.pageNumber != 5 && this.pageNumber != 1 && !welcomePage"
          class="advance"
          mat-button
          i18n
        >
          <span class="material-symbols-rounded advance"> arrow_forward </span>
        </button>
        <button
          (click)="advance()"
          *ngIf="this.pageNumber == 1"
          class="advance"
          color="primary"
          mat-button
          i18n
        >
          START!
        </button>

        <button
          (click)="closeForever()"
          *ngIf="this.pageNumber == 5"
          class="advance"
          mat-button
          i18n
        >
          CLOSE
        </button>
      </div>

      <p class="foot">
        Skylife Engineering SL. C.I.F.: B91936997 Parque Científico y
        Tecnológico la Cartuja c/ Américo Vespucio 5, Bloque 1 Local A 8-12.
        41092 Seville
      </p>

      <button mat-raised-button [matMenuTriggerFor]="menu">
        Archivos descargables
        <mat-menu #menu="matMenu">
          <div class="fullContainer">
            <div class="titleLicensing">
              <h1 i18n>Archivos</h1>
            </div>
            <div class="contentMenu">
              <ul>
                <li>Archivo 1</li>
                <li>Archivo 2:</li>
                <li>Archivo 3:</li>
              </ul>
            </div>
          </div>
        </mat-menu>
      </button>
    </div>
  </div>
</body>
