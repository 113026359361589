import { MatDialogRef } from '@angular/material/dialog';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-dialog-disable-user-confirm',
  templateUrl: './dialog-disable-user-confirm.component.html',
  styleUrls: ['./dialog-disable-user-confirm.component.css'],
})
export class DialogDisableUserConfirmComponent implements OnInit {
  constructor(public dialog: MatDialogRef<DialogDisableUserConfirmComponent>) {}

  public usersNum: number;

  public confirmDeleteSessions(res: boolean) {
    this.dialog.close({ deleteConfirm: res });
  }

  public ngOnInit(): void {}
}
