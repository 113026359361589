<body>
  <div class="content">
    <div class="title" i18n>DOCUMENTS</div>
  </div>
  <div class="continer">
    <div class="sectionDownloadables">
      <table class="alternate_color">
        <div *ngFor="let downloadable of downloadables | keyvalue">
          <tr class="tableRow">
            <td>
              <div class="subTitle">
                {{ downloadable.key }}
              </div>
              <ul>
                <li i18n>
                  <b>Description:</b> {{ downloadable.value["description"] }}
                </li>
                <li i18n><b>Size:</b> {{ downloadable.value["size"] }}</li>
              </ul>
            </td>

            <a [href]="downloadable.value['URL']">
              <button
                mat-raised-button
                color="primary"
                class="downloadButton"
                i18n
              >
                <span class="material-symbols-outlined"> download </span>
              </button>
            </a>
          </tr>

          <div class="divider">
            <mat-divider *ngIf="downloadable.key != getLast()"></mat-divider>
          </div>
        </div>
      </table>
    </div>
  </div>
</body>
