import { Component, OnInit } from '@angular/core';
import { UserManagementAPIService } from '../../Services/UserManagement/user-management-api.service';
import { Router } from '@angular/router';
import { LoginMFADialogComponent } from './login-mfadialog/login-mfadialog.component';
import { MatDialog } from '@angular/material/dialog';
import { InvalidPasswordComponent } from '../common/common-error-dialogs/invalid-password/invalid-password.component';
import { ForceChangePasswordComponent } from './force-change-password/force-change-password.component';
import { ConfirmForceChangePasswordComponent } from './force-change-password/confirm-force-change-password/confirm-force-change-password.component';
import { UserNoExistDialogComponent } from '../common/common-error-dialogs/user-no-exist-dialog/user-no-exist-dialog.component';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ForgetPasswordDialogComponent } from './forget-password-dialog/forget-password-dialog.component';
import { WelcomeDialogComponent } from '../dashboard/welcome-dialog/welcome-dialog.component';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  constructor(
    private userManagementAPIService: UserManagementAPIService,
    private router: Router,
    private dialog: MatDialog
  ) {}
  currentYear: number = new Date().getFullYear();
  public accesstoken = '';
  public activateOper = false;
  public passwordType = 'password';
  public loginSpinner = false;
  public responseData: string[] = [];
  public language: string;
  public token: string[] = [];
  public disableFieldsAndButtons = false;

  public togglePassword(): void {
    if (this.passwordType == 'password') {
      this.passwordType = 'text';
    } else {
      this.passwordType = 'password';
    }
  }

  public getBearerToken(
    username: string,
    password: string,
    newPass?: string
  ): void {
    this.userManagementAPIService.currentUserInfo(username).subscribe({
      next: (data) => {
        var json = JSON.stringify(data);
        var jsonParsedWithCurrentUserInfo = JSON.parse(json);
        var userType = jsonParsedWithCurrentUserInfo.userType;
        var userCapabilities = jsonParsedWithCurrentUserInfo.capabilities;
        var newUser = jsonParsedWithCurrentUserInfo.newUser;
        this.language = jsonParsedWithCurrentUserInfo.language;

        this.userManagementAPIService.getBearer(username, password).subscribe({
          next: (data) => {
            var json = JSON.stringify(data);
            var jsonParsed = JSON.parse(json);
            var token = jsonParsed.token;
            window.localStorage.setItem('userType', userType);
            window.localStorage.setItem(
              'userCapabilities',
              JSON.stringify(userCapabilities)
            );

            console.log('userCapabilities', userCapabilities);
            window.localStorage.setItem('SessionUsername', username);

            if (token != '') {
              //Login común
              this.setTokens(jsonParsed, token);
              this.loginSpinner = false;
            } else {
              var challengeName = jsonParsed.ChallengeName;
              var session = jsonParsed.Session;
              window.localStorage.setItem('SessionToken', session);
              this.loginSpinner = false;

              if (challengeName == 'SOFTWARE_TOKEN_MFA') {
                //entra si el usuario tiene el MFA activado

                localStorage.setItem('UserLoginMethod', challengeName);

                this.loginWithMFA();
                this.loginSpinner = false;
                this.disableFieldsAndButtons = false;
              } else if (challengeName == 'NEW_PASSWORD_REQUIRED') {
                //entra cuando es un usuario nuevo y sólo tiene contraseña temporal
                this.changeAutomaticPassword();
                this.loginSpinner = false;
                this.disableFieldsAndButtons = false;
              }
            }

            this.openWelcomeDialog(newUser, userType, username, challengeName);

            this.userManagementAPIService
              .logLogin(username, window.navigator.userAgent)
              .subscribe({
                error: (error) => {
                  console.error(error);
                  this.loginSpinner = false;
                },
              });
          },
          error: (error) => {
            console.error('There was an error!', error);
            this.loginSpinner = false;
            const invalidPasswordDialog = this.dialog.open(
              InvalidPasswordComponent,
              {
                height: '200px',
                width: '400px',
              }
            );

            invalidPasswordDialog.afterOpened().subscribe(() => {
              this.loginSpinner = false;
            });

            invalidPasswordDialog.afterClosed().subscribe(() => {
              this.disableFieldsAndButtons = false;
              this.router.navigate(['/login']);
            });
          },
        });
      },
      error: (error) => {
        this.dialog.open(UserNoExistDialogComponent);
        this.dialog.afterOpened.subscribe(() => {
          this.loginSpinner = false;
        });
        this.dialog.afterAllClosed.subscribe(() => {
          this.disableFieldsAndButtons = false;
        });

        console.error(error);
      },
    });
  }

  public openWelcomeDialog(
    newUser: number,
    userType: string,
    username: string,
    challengeName: string
  ) {
    if (
      newUser &&
      userType == 'USER_N2' &&
      challengeName != 'NEW_PASSWORD_REQUIRED'
    ) {
      var dialogRef = this.dialog.open(WelcomeDialogComponent, {
        width: '97%',
        height: '97%',
      });

      dialogRef.afterClosed().subscribe({
        next: (result) => {
          if (result.selected == true) {
            this.userManagementAPIService
              .updateNewUserStatus(username)
              .subscribe({
                error: (err) => {
                  console.error(err);
                },
              });
          }
        },
        error: (err) => {
          console.error(err);
        },
      });
    }
  }

  public globalSignOut(username: string, password: string): void {
    this.userManagementAPIService.removeToken(username, password).subscribe({
      next: () => {},
      error: (error) => {
        console.error(error);
      },
    });
  }

  public login(username: string, password: string): void {
    this.disableFieldsAndButtons = true;
    this.globalSignOut(username, password);
    this.loginSpinner = true;
    setTimeout(() => {
      this.getBearerToken(username, password);
    }, 1500);
  }

  public ngOnInit(): void {
    //remove capabilities from local storage
    window.localStorage.removeItem('userCapabilities');
  }

  public openForgotPassword() {
    var dialogRef = this.dialog.open(ForgetPasswordDialogComponent);
    dialogRef.afterClosed().subscribe({
      next: () => {},
    });
  }

  private changeAutomaticPassword() {
    this.loginSpinner = false;
    var dialogRefForce = this.dialog.open(ForceChangePasswordComponent, {
      height: '400px',
      width: '600px',
    });
    dialogRefForce.afterClosed().subscribe((result) => {
      var res = Object.values(result);
      if (res[0]) {
        var dialogRef = this.dialog.open(ConfirmForceChangePasswordComponent);
        dialogRef.componentInstance.confirmation = true;
        (<HTMLInputElement>document.getElementById('password')).value = '';
      } else {
        var dialogRef = this.dialog.open(ConfirmForceChangePasswordComponent);
        dialogRef.componentInstance.confirmation = false;
      }
    });
  }

  private loginWithMFA() {
    const dialogRef = this.dialog.open(LoginMFADialogComponent, {
      height: '24em',
      width: '20em',
    });
    dialogRef.afterClosed().subscribe({
      next: () => {
        var url = window.location.href;
        var urlSplitted = url.split('/');
        var urlLanguage = urlSplitted[3];

        //in development es or en is not visible in the url, so we need to check if the url has it, in the case it has it, we redirect to the correct url
        //in the case it doesn't have it, we redirect to the default language and doesn't crash into the 404 page

        if (urlLanguage == 'es' || urlLanguage == 'en') {
          if (this.language == 'Spanish') {
            window.location.href =
              environment.WebLanguages.Spanish + '/dashboard/home';
          } else if (this.language == 'English') {
            window.location.href =
              environment.WebLanguages.English + '/dashboard/home';
          } else {
            window.location.href =
              environment.WebLanguages.English + '/dashboard/home';
          }
        } else {
          this.router.navigate(['/dashboard/home']);
        }
      },
      error: () => {},
    });
  }

  private setTokens(jsonParsed, token) {
    window.localStorage.setItem('Authorization', 'Bearer ' + token);
    window.localStorage.setItem('AccessToken', jsonParsed.AccessToken);
    window.localStorage.setItem('RefreshToken', jsonParsed.RefreshToken);
    window.localStorage.setItem('Session', 'Authorized');
    window.localStorage.setItem('UserLoginMethod', '');
    window.localStorage.setItem('UserLanguage', this.language);
    window.localStorage.setItem('WebLanguage', this.language);

    var url = window.location.href;
    var urlSplitted = url.split('/');
    var urlLanguage = urlSplitted[3];

    //in development es or en is not visible in the url, so we need to check if the url has it, in the case it has it, we redirect to the correct url
    //in the case it doesn't have it, we redirect to the default language and doesn't crash into the 404 page

    if (urlLanguage == 'es' || urlLanguage == 'en') {
      if (this.language == 'Spanish') {
        window.location.href =
          environment.WebLanguages.Spanish + '/dashboard/home';
      } else if (this.language == 'English') {
        window.location.href =
          environment.WebLanguages.English + '/dashboard/home';
      } else {
        window.location.href =
          environment.WebLanguages.English + '/dashboard/home';
      }
    } else {
      this.router.navigate(['/dashboard/home']);
    }
  }
}
