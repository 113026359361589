import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { FormControl, Validators } from '@angular/forms';

@Component({
  selector: 'app-sessions-date-picker-filter-dialog',
  templateUrl: './sessions-date-picker-filter-dialog.component.html',
  styleUrls: ['./sessions-date-picker-filter-dialog.component.css'],
})
export class SessionsDatePickerFilterDialogComponent implements OnInit {
  constructor(
    public dialog: MatDialogRef<SessionsDatePickerFilterDialogComponent>
  ) {}

  public eDate = new FormControl('', [
    Validators.required,
    Validators.minLength(16),
    Validators.maxLength(16),
  ]);
  public sDate = new FormControl('', [
    Validators.required,
    Validators.minLength(16),
    Validators.maxLength(16),
  ]);

  public cancelDateFilter() {
    this.dialog.close({ startDate: '', endDate: '', cancel: true });
  }

  public cmpDates(startDate: string, endDate: string) {
    var startDateFormated = new Date(startDate);
    var endDateFormated = new Date(endDate);

    if (endDateFormated < startDateFormated) {
      return false;
    }
    return true;
  }

  public confirm(startDate: string, endDate: string) {
    this.dialog.close({
      startDate: startDate,
      endDate: endDate,
      cancel: false,
    });
  }

  public ngOnInit(): void {}
}
