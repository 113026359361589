<div (keyup.enter)="confirm()" class="content">
  <Label *ngIf="!passwordChanged && !incorrectPassword" class="tokenLabel" i18n
    >The password was not changed</Label
  >
  <Label *ngIf="!passwordChanged && incorrectPassword" class="tokenLabel" i18n
    >The old password was not correct</Label
  >
  <Label *ngIf="!passwordChanged && tooManyAttempts" class="tokenLabel" i18n
    >You have attempted too many password changes, please, try again
    later</Label
  >
  <Label *ngIf="passwordChanged" class="tokenLabel" i18n
    >Password changed successfully</Label
  >
  <button class="confirmButton" mat-button (click)="confirm()" i18n>Ok</button>
</div>
