import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-new-user-to-department-response',
  templateUrl: './new-user-to-department-response.component.html',
  styleUrls: ['./new-user-to-department-response.component.css'],
})
export class NewUserToDepartmentResponseComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<NewUserToDepartmentResponseComponent>
  ) {}

  isUserAdded: boolean;

  ngOnInit(): void {}

  public closeDialog() {
    this.dialogRef.close();
  }

  public closeDialogAndRefresh() {
    this.dialogRef.close();
    window.location.reload();
  }
}
