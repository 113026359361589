import { MatDialogRef } from '@angular/material/dialog';
import { Component, OnInit } from '@angular/core';
import { Dialog } from '@angular/cdk/dialog';

@Component({
  selector: 'app-disabled-user-confirmation',
  templateUrl: './disabled-user-confirmation.component.html',
  styleUrls: ['./disabled-user-confirmation.component.css'],
})
export class DisabledUserConfirmationComponent implements OnInit {
  constructor(public dialog: MatDialogRef<DisabledUserConfirmationComponent>) {}

  public disableUserSuccess: boolean;

  public backToUserList() {
    this.dialog.close();
  }

  ngOnInit(): void {}
}
