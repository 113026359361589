<div class="container">
  <div class="title" i18n>Do you want to save this data?</div>
  <div class="changesUserData">
    <table>
      <tr>
        <td i18n>Name:</td>
        <td>-></td>
        <td>
          <label *ngIf="changePersonalData.name == currentUserData.name" i18n
            >(Without changes)</label
          >
          <b *ngIf="changePersonalData.name != currentUserData.name">{{
            changePersonalData.name
          }}</b>
        </td>
      </tr>
      <tr>
        <td i18n>Surname:</td>
        <td>-></td>
        <td>
          <label
            *ngIf="changePersonalData.surname == currentUserData.surname"
            i18n
            >(Without changes)</label
          >
          <b *ngIf="changePersonalData.surname != currentUserData.surname">{{
            changePersonalData.surname
          }}</b>
        </td>
      </tr>
      <tr>
        <td i18n>Language:</td>
        <td>-></td>
        <td>
          <label
            *ngIf="changePersonalData.language == currentUserData.language"
            i18n
            >(Without changes)</label
          >
          <b *ngIf="changePersonalData.language != currentUserData.language">{{
            changePersonalData.language
          }}</b>
        </td>
      </tr>
    </table>
    <div class="departmentsAdd" *ngIf="hidden == false">
      <label i18n>Departments added:</label> <br />
      <label *ngIf="changePersonalData.departments.length == 0" i18n>
        (Without changes)
      </label>
      <div
        class="department addDepartBloc"
        *ngFor="let dep of changePersonalData.departments"
        i18n
      >
        <b>{{ dep.departmentName }} </b>
      </div>
    </div>

    <div class="departmentsDelete" *ngIf="hidden == false">
      <label i18n>Departments deleted:</label> <br />
      <label *ngIf="changePersonalData.deleteDepartments.length == 0" i18n>
        (Without changes)
      </label>
      <div
        class="department deleteDepartBloc"
        *ngFor="let dep of changePersonalData.deleteDepartments"
        i18n
      >
        <b>{{ dep.departmentName }}</b>
      </div>
    </div>
  </div>
  <div class="confirmation">
    <button
      mat-button
      (click)="close(true)"
      color="primary"
      class="confirmationButton yes"
    >
      YES
    </button>
    <button mat-button (click)="close(false)" class="confirmationButton no">
      NO
    </button>
  </div>
</div>
