import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-new-department-confirmation',
  templateUrl: './new-department-confirmation.component.html',
  styleUrls: ['./new-department-confirmation.component.css'],
})
export class NewDepartmentConfirmationComponent implements OnInit {
  constructor(public dialog: MatDialog) {}

  departmentCreated: boolean;

  ngOnInit(): void {}

  public closeDialog() {
    this.dialog.closeAll();
    window.location.reload();
  }
}
