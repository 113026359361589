<div
  (keyup.enter)="
    cmpNewPassword() != 0 && newRepeatedPassword.value == newPassword.value
  "
>
  <label i18n>
    A temporary password is being used. Please enter the new password:</label
  >
  <mat-form-field class="example-full-width" appearance="fill">
    <mat-label i18n>New password</mat-label>
    <input [formControl]="newPassword" matInput type="password" required />
  </mat-form-field>
  <label
    class="errorMessage"
    *ngIf="cmpNewPassword() == 0 && newPassword.value != ''"
    >{{ getNewPasswordError() }}</label
  >
  <mat-form-field class="example-full-width" appearance="fill">
    <mat-label i18n>Introduce the password again</mat-label>
    <input
      [formControl]="newRepeatedPassword"
      matInput
      type="password"
      required
    />
  </mat-form-field>
  <label class="errorMessage">{{ repeatPasswordError() }}</label>
  <br />
  <label i18n>
    Must be at least 8 characters long, with numbers, letters and symbols</label
  >
  <button
    [disabled]="
      cmpNewPassword() == 0 || newRepeatedPassword.value != newPassword.value
    "
    class="confirmButton"
    mat-button
    (click)="confirm()"
    i18n
  >
    Confirm
  </button>
</div>
