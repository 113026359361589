import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DeactivateUserMFAConfirmationResponseComponent } from './deactivate-user-mfaconfirmation-response/deactivate-user-mfaconfirmation-response.component';
import { UserManagementAPIService } from 'src/app/Services/UserManagement/user-management-api.service';

@Component({
  selector: 'app-deactivate-user-mfaconfirmation',
  templateUrl: './deactivate-user-mfaconfirmation.component.html',
  styleUrls: ['./deactivate-user-mfaconfirmation.component.css'],
})
export class DeactivateUserMFAConfirmationComponent implements OnInit {
  username: string;

  constructor(
    public dialog: MatDialog,
    public userManagementAPIService: UserManagementAPIService
  ) {}

  ngOnInit(): void {}

  public confirmUser2FADeactivation() {
    this.dialog.closeAll();

    this.userManagementAPIService.adminDisableMFA(this.username).subscribe({
      next: () => {
        var confirmationResponseDialog = this.dialog.open(
          DeactivateUserMFAConfirmationResponseComponent,
          {
            height: '11em',
            width: '25em',
          }
        );
        confirmationResponseDialog.componentInstance.hasBeenDeactivated = true;
      },

      error: () => {
        var confirmationResponseDialog = this.dialog.open(
          DeactivateUserMFAConfirmationResponseComponent,
          {
            height: '11em',
            width: '25em',
          }
        );
        confirmationResponseDialog.componentInstance.hasBeenDeactivated = false;
      },
    });
  }

  public cancelUser2FADeactivation() {
    this.dialog.closeAll();
  }
}
