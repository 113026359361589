<!DOCTYPE html>
<html>
  <head>
    <link
      rel="stylesheet"
      href="https://cdn.jsdelivr.net/npm/bootstrap@4.3.1/dist/css/bootstrap.min.css"
      integrity="sha384-ggOyR0iXCbMQv3Xipma34MD+dH/1fQ784/j6cY/iJTQUOhcWr7x9JvoRxT2MZw1T"
      crossorigin="anonymous"
    />
    <link
      rel="stylesheet"
      href="https://fonts.googleapis.com/css2?family=Material+Symbols+Rounded:opsz,wght,FILL,GRAD@48,400,0,0"
    />
  </head>
  <Body class="theme-alternate">
    <div class="loginComponentDiv">
      <div class="titleHeader">
        <div>
          <div class="logoAndSloganDiv">
            <img
              src="assets/img/skleLogos/Skylife_Infinity_v2.svg"
              alt="logo"
              class="Logo"
            />
            <label class="sloganLabel" i18n
              >Open your eyes to a new reality</label
            >
          </div>
        </div>
      </div>
      <div class="inputFields">
        <div class="userFormField">
          <span class="matPrefix"
            ><mat-icon>account_circle</mat-icon> &nbsp;</span
          >
          <input
            class="userInput"
            (keyup.enter)="login(username.value, password.value)"
            placeholder="User"
            i18n-placeholder="User placeholder"
            [disabled]="disableFieldsAndButtons"
            required
            #username
          />
        </div>
        <div class="userFormField">
          <span class="matPrefix"><mat-icon>lock</mat-icon> &nbsp;</span>
          <input
            class="userInput"
            id="password"
            placeholder="Password"
            i18n-placeholder="Password placeholder"
            [disabled]="disableFieldsAndButtons"
            (keyup.enter)="login(username.value, password.value)"
            [type]="passwordType"
            #password
            required
          />
          <span
            *ngIf="passwordType == 'password'"
            class="matPrefix visibilityButton"
            (click)="togglePassword()"
            ><mat-icon>visibility</mat-icon> &nbsp;</span
          >
          <span
            *ngIf="passwordType == 'text'"
            class="matPrefix visibilityButton"
            (click)="togglePassword()"
            ><mat-icon>visibility_off</mat-icon> &nbsp;</span
          >
        </div>
        <button
          color="primary"
          class="loginButton"
          type="button"
          [disabled]="disableFieldsAndButtons"
          (click)="login(username.value, password.value)"
          i18n
        >
          Log in
        </button>

        <button class="forgotPass" (click)="openForgotPassword()" i18n>
          Forgot password
        </button>
      </div>
      <div *ngIf="loginSpinner" class="spinnerDiv">
        <span>
          <mat-icon class="loadingIcon">sync</mat-icon>
        </span>
      </div>
    </div>
    <footer class="footer">
      <div class="copyrightFooter" i18n>
        Copyright &copy; Skylife Engineering S.L. {{ currentYear }}
      </div>
      <div class="policyFooter">
        <label class="policyLabel">
          <a
            routerLink="/privacy-policy"
            class="labelLink"
            style="color: black"
            i18n
            >Privacy policy
          </a>
          -
          <a
            routerLink="/legal-policy"
            class="labelLink"
            style="color: black"
            i18n
            >Legal policy</a
          >
        </label>
      </div>
    </footer>
  </Body>
</html>
