import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-page-not-found-component',
  templateUrl: './page-not-found-component.component.html',
  styleUrls: ['./page-not-found-component.component.css'],
})
export class PageNotFoundComponentComponent implements OnInit {
  constructor() {}

  token = localStorage.getItem('Authorization');

  ngOnInit(): void {
    const animated = document.querySelector('.planeDiv');

    console.log(this.token);

    animated.addEventListener('animationend', () => {
      document.getElementById('plane').style.display = 'none';
      document.getElementById('plane_off').style.display = 'block';
    });
  }

  signOut() {
    localStorage.removeItem('Authorization');
  }
}
