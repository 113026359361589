import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LoginComponent } from './Components/login/login.component';
import { MatTableModule } from '@angular/material/table';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatIconModule } from '@angular/material/icon';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatListModule } from '@angular/material/list';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatMenuModule } from '@angular/material/menu';
import { MatInputModule } from '@angular/material/input';
import { SessionsComponent } from './Components/management/sessions/sessions.component';
import { SessionsReportComponent } from './Components/management/sessions/sessions-report/sessions-report.component';
import { JwtModule } from '@auth0/angular-jwt';
import { JwtHelperService, JWT_OPTIONS } from '@auth0/angular-jwt';
import { DashboardComponent } from './Components/dashboard/dashboard.component';
import { SessionsFilterComponent } from './Components/management/sessions/sessions-filter/sessions-filter.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { DatePipe } from '@angular/common';
import { LoginMFADialogComponent } from './Components/login/login-mfadialog/login-mfadialog.component';
import { HomeComponent } from './Components/dashboard/home/home.component';
import { NgChartsModule } from 'ng2-charts';
import { ChangePasswordComponent } from './Components/user/profile/security/change-password/change-password.component';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { InvalidTokenComponent } from './Components/common/common-error-dialogs/invalid-token/invalid-token.component';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { InvalidPasswordComponent } from './Components/common/common-error-dialogs/invalid-password/invalid-password.component';
import { SessionsDatePickerFilterDialogComponent } from './Components/management/sessions/sessions-date-picker-filter-dialog/sessions-date-picker-filter-dialog.component';
import { FaqsComponent } from './Components/user/faqs/faqs.component';
import { MatExpansionModule } from '@angular/material/expansion';
import { RemovalMediaConfirmationComponent } from './Components/management/sessions/sessions-report/removal-media-confirmation/removal-media-confirmation.component';
import { AddMediaComponent } from './Components/management/sessions/sessions-report/add-media/add-media.component';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { UserManagementComponent } from './Components/management/user-management/user-management.component';
import { AddNewUserComponent } from './Components/management/user-management/creation/add-new-user/add-new-user.component';
import { NoReportAvailableDialogComponent } from './Components/management/sessions/sessions-error-dialogs/no-report-available-dialog/no-report-available-dialog.component';
import { DeleteSessionConfirmationComponent } from './Components/management/sessions/delete-session-confirmation/delete-session-confirmation.component';
import { ForceChangePasswordComponent } from './Components/login/force-change-password/force-change-password.component';
import { DialogDisableUserConfirmComponent } from './Components/management/user-management/disable/dialog-disable-user-confirm/dialog-disable-user-confirm.component';
import { DisabledUserConfirmationComponent } from './Components/management/user-management/disable/disabled-user-confirmation/disabled-user-confirmation.component';
import { ConfirmationAddNewUser } from './Components/management/user-management/creation/confirmation-add-new-user/confirmation-add-new-user.component';
import { ErrorDeleteSessionDialogComponent } from './Components/management/sessions/sessions-error-dialogs/error-delete-session-dialog/error-delete-session-dialog.component';
import { UserTypeNotAllowedDialogComponent } from './Components/common/common-error-dialogs/user-type-not-allowed-dialog/user-type-not-allowed-dialog.component';
import { InvalidUserTypeDialogComponent } from './Components/common/common-error-dialogs/invalid-user-type-dialog/invalid-user-type-dialog.component';
import { TypeErrorsAddUserEmailComponent } from './Components/management/user-management/creation/creation-error-dialogs/type-errors-add-user-email/type-errors-add-user-email.component';
import { ConfirmForceChangePasswordComponent } from './Components/login/force-change-password/confirm-force-change-password/confirm-force-change-password.component';
import { RevokedTokenComponent } from './Components/common/common-error-dialogs/revoked-token/revoked-token.component';
import { UserHelpComponent } from './Components/user/user-help/user-help.component';
import { ChangePasswordConfirmationDialogComponent } from './Components/user/profile/security/change-password/change-password-confirmation-dialog/change-password-confirmation-dialog.component';
import { ChangeUserPassComponent } from './Components/management/user-management/modification/admin-change-user-pass/admin-change-user-pass.component';
import { UserNoExistDialogComponent } from './Components/common/common-error-dialogs/user-no-exist-dialog/user-no-exist-dialog.component';
import { UserExistButDisabledDialogComponent } from './Components/management/user-management/creation/creation-error-dialogs/user-exist-but-disabled-dialog/user-exist-but-disabled-dialog.component';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { ShowDisabledUsersDialogComponent } from './Components/management/user-management/disable/show-disabled-users/show-disabled-users.component';
import { ForgetPasswordDialogComponent } from './Components/login/forget-password-dialog/forget-password-dialog.component';
import { ActivateUserConfirmationDialogComponent } from './Components/management/user-management/disable/activate-user-confirmation-dialog/activate-user-confirmation-dialog.component';
import { PrivacyPolicyComponent } from './Components/common/legal/privacy-policy/privacy-policy.component';
import { LegalPolicyComponent } from './Components/common/legal/legal-policy/legal-policy.component';
import { CustomMatPaginatorIntl } from './custom-mat-paginator-int';
import { ConfirmationActivateUserComponent } from './Components/management/user-management/disable/show-disabled-users/confirmation-activate-user/confirmation-activate-user.component';
import { ProfileComponent } from './Components/user/profile/profile.component';
import { MfaConfirmationDialogComponent } from './Components/user/profile/security/mfa-activation-dialog/mfa-confirmation-dialog/mfa-confirmation-dialog.component';
import { MfaActivationDialogComponent } from './Components/user/profile/security/mfa-activation-dialog/mfa-activation-dialog.component';
import { QRCodeModule } from 'angularx-qrcode';
import { ReplaceFileConfirmationComponent } from './Components/management/sessions/sessions-report/add-media/replace-file-confirmation/replace-file-confirmation.component';
import { PersonalDataComponent } from './Components/user/profile/personal-data/personal-data.component';
import { SecurityComponent } from './Components/user/profile/security/security.component';
import { WelcomeDialogComponent } from './Components/dashboard/welcome-dialog/welcome-dialog.component';
import { DownloadableFilesComponent } from './Components/user/downloadable-files/downloadable-files.component';
import { SecretCodeDialogComponent } from './Components/user/profile/security/mfa-activation-dialog/secret-code-dialog/secret-code-dialog.component';
import { AcPasswordInputDialogComponent } from './Components/user/profile/security/ac-password-input-dialog/ac-password-input-dialog.component';
import { MfaConfirmationResponseDialogComponent } from './Components/user/profile/security/mfa-activation-dialog/mfa-confirmation-dialog/mfa-confirmation-response-dialog/mfa-confirmation-response-dialog.component';
import { DeactivateUserMFAComponent } from './Components/management/user-management/modification/deactivate-user-mfa/deactivate-user-mfa.component';
import { DeactivateUserMFAConfirmationComponent } from './Components/management/user-management/modification/deactivate-user-mfa/deactivate-user-mfaconfirmation/deactivate-user-mfaconfirmation.component';
import { DeactivateUserMFAConfirmationResponseComponent } from './Components/management/user-management/modification/deactivate-user-mfa/deactivate-user-mfaconfirmation/deactivate-user-mfaconfirmation-response/deactivate-user-mfaconfirmation-response.component';
import { AcQRLoginComponent } from './Components/login/ac-qrlogin/ac-qrlogin.component';
import { MatDividerModule } from '@angular/material/divider';
import { DocumentsDownloadsComponent } from './Components/user/downloadable-files/documents-downloads/documents-downloads.component';
import { ExecutablesDownloadsComponent } from './Components/user/downloadable-files/executables-downloads/executables-downloads.component';
import { VideosDownloadsComponent } from './Components/user/downloadable-files/videos-downloads/videos-downloads.component';
import { DepartmentManagementComponent } from './Components/management/department-management/department-management.component';
import { NewDepartmentComponent } from './Components/management/department-management/new-department/new-department.component';
import { NewDepartmentConfirmationComponent } from './Components/management/department-management/new-department/new-department-confirmation/new-department-confirmation.component';
import { RemoveDepartmentsConfirmationComponent } from './Components/management/department-management/remove-departments-confirmation/remove-departments-confirmation.component';
import { RemoveDepartmentsConfirmationResponseComponent } from './Components/management/department-management/remove-departments-confirmation/remove-departments-confirmation-response/remove-departments-confirmation-response.component';
import { DepartmentUsersComponent } from './Components/management/department-management/department-users/department-users.component';
import { MatCardModule } from '@angular/material/card';
import { EditUserProfileAdminDialogComponent } from './Components/management/user-management/modification/edit-user-profile-admin-dialog/edit-user-profile-admin-dialog.component';
import { ChangesUserDataDialogComponent } from './Components/management/user-management/modification/edit-user-profile-admin-dialog/changes-user-data-dialog/changes-user-data-dialog.component';
import { ConfirmationChangeDataComponent } from './Components/management/user-management/modification/edit-user-profile-admin-dialog/confirmation-change-data/confirmation-change-data.component';
import { NewUserToDepartmentDialogComponent } from './Components/management/department-management/department-users/new-user-to-department-dialog/new-user-to-department-dialog.component';
import { RemoveUserFromDepartmentDialogComponent } from './Components/management/department-management/department-users/remove-user-from-department-dialog/remove-user-from-department-dialog.component';
import { NewUserToDepartmentResponseComponent } from './Components/management/department-management/department-users/new-user-to-department-dialog/new-user-to-department-response/new-user-to-department-response.component';
import { RemoveUserFromDepartmentResponseComponent } from './Components/management/department-management/department-users/remove-user-from-department-dialog/remove-user-from-department-response/remove-user-from-department-response.component';
import { ConfirmationRemoveUserFromDepartmentComponent } from './Components/management/department-management/department-users/remove-user-from-department-dialog/confirmation-remove-user-from-department/confirmation-remove-user-from-department.component';
import { DirectDownloadComponentComponent } from './Components/user/downloadable-files/direct-download-component/direct-download-component.component';
import { PageNotFoundComponentComponent } from './Components/common/page-not-found-component/page-not-found-component.component';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    SessionsComponent,
    SessionsReportComponent,
    DashboardComponent,
    SessionsFilterComponent,
    LoginMFADialogComponent,
    HomeComponent,
    ChangePasswordComponent,
    InvalidTokenComponent,
    InvalidPasswordComponent,
    SessionsDatePickerFilterDialogComponent,
    FaqsComponent,
    RemovalMediaConfirmationComponent,
    AddMediaComponent,
    UserManagementComponent,
    AddNewUserComponent,
    NoReportAvailableDialogComponent,
    DeleteSessionConfirmationComponent,
    ForceChangePasswordComponent,
    ConfirmForceChangePasswordComponent,
    DialogDisableUserConfirmComponent,
    DisabledUserConfirmationComponent,
    ConfirmationAddNewUser,
    UserTypeNotAllowedDialogComponent,
    InvalidUserTypeDialogComponent,
    ErrorDeleteSessionDialogComponent,
    TypeErrorsAddUserEmailComponent,
    RevokedTokenComponent,
    UserHelpComponent,
    ChangePasswordConfirmationDialogComponent,
    ChangeUserPassComponent,
    UserNoExistDialogComponent,
    UserExistButDisabledDialogComponent,
    ShowDisabledUsersDialogComponent,
    ForgetPasswordDialogComponent,
    ActivateUserConfirmationDialogComponent,
    PrivacyPolicyComponent,
    LegalPolicyComponent,
    CustomMatPaginatorIntl,
    ConfirmationActivateUserComponent,
    ProfileComponent,
    MfaActivationDialogComponent,
    MfaConfirmationDialogComponent,
    ReplaceFileConfirmationComponent,
    PersonalDataComponent,
    SecurityComponent,
    WelcomeDialogComponent,
    DownloadableFilesComponent,
    SecretCodeDialogComponent,
    AcPasswordInputDialogComponent,
    MfaConfirmationResponseDialogComponent,
    DeactivateUserMFAComponent,
    DeactivateUserMFAConfirmationComponent,
    DeactivateUserMFAConfirmationResponseComponent,
    AcQRLoginComponent,
    VideosDownloadsComponent,
    ExecutablesDownloadsComponent,
    DocumentsDownloadsComponent,
    DepartmentManagementComponent,
    NewDepartmentComponent,
    NewDepartmentConfirmationComponent,
    RemoveDepartmentsConfirmationComponent,
    RemoveDepartmentsConfirmationResponseComponent,
    DepartmentUsersComponent,
    EditUserProfileAdminDialogComponent,
    ChangesUserDataDialogComponent,
    ConfirmationChangeDataComponent,
    NewUserToDepartmentDialogComponent,
    RemoveUserFromDepartmentDialogComponent,
    NewUserToDepartmentResponseComponent,
    RemoveUserFromDepartmentResponseComponent,
    ConfirmationRemoveUserFromDepartmentComponent,
    DirectDownloadComponentComponent,
    PageNotFoundComponentComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatTableModule,
    MatFormFieldModule,
    MatCheckboxModule,
    MatRadioModule,
    MatSelectModule,
    MatIconModule,
    FormsModule,
    ReactiveFormsModule,
    MatSidenavModule,
    MatToolbarModule,
    MatListModule,
    MatButtonModule,
    MatDialogModule,
    MatMenuModule,
    MatInputModule,
    HttpClientModule,
    JwtModule,
    MatDatepickerModule,
    MatNativeDateModule,
    NgChartsModule,
    MatSlideToggleModule,
    MatPaginatorModule,
    MatSortModule,
    MatExpansionModule,
    MatProgressBarModule,
    MatAutocompleteModule,
    QRCodeModule,
    MatDividerModule,
    MatCardModule,
  ],
  providers: [
    { provide: JWT_OPTIONS, useValue: JWT_OPTIONS },
    { provide: MAT_DATE_LOCALE, useValue: 'en-GB' },
    JwtHelperService,
    MatDatepickerModule,
    MatNativeDateModule,
    DatePipe,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
