import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { UserSessionCheckerService } from '../../../../../Services/UserManagement/UserSessionChecker/user-session-checker.service';

@Component({
  selector: 'app-removal-media-confirmation',
  templateUrl: './removal-media-confirmation.component.html',
  styleUrls: ['./removal-media-confirmation.component.css'],
})
export class RemovalMediaConfirmationComponent implements OnInit {
  constructor(
    public dialog: MatDialogRef<RemovalMediaConfirmationComponent>,
    private userSessionCheckerService: UserSessionCheckerService
  ) {}
  private checkUserSession(): boolean {
    this.userSessionCheckerService.checkUserSession();
    return this.userSessionCheckerService.check;
  }

  confirm(confirmation: string) {
    this.checkUserSession()
      ? this.dialog.close(confirmation)
      : this.dialog.close();
  }

  close() {
    this.dialog.close();
  }

  ngOnInit(): void {}
}
