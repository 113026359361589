<div class="container">
  <div class="content">
    <mat-form-field
      class="example-full-width whiteTextInputForm"
      appearance="fill"
    >
      <mat-label i18n>Username</mat-label>
      <input
        id="usernameInput"
        type="text"
        placeholder="Write the username here"
        i18n-placeholder="Write the username here placeholder"
        aria-label="Number"
        matInput
        [formControl]="form"
        [matAutocomplete]="auto"
        #usernameInput
      />
      <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
        <mat-option
          *ngFor="let option of filteredOptions | async"
          [value]="option"
        >
          {{ option }}
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>
    <div class="buttonDiv">
      <button
        mat-raised-button
        color="primary"
        (click)="showConfirmationDialog(usernameInput.value)"
        i18n
      >
        Confirm
      </button>
      <button
        #cancelButton
        mat-raised-button
        color="error"
        (click)="cancelUser2FADeactivation()"
        i18n
      >
        Cancel
      </button>
    </div>
  </div>
</div>
