import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UserSessionCheckerService } from 'src/app/Services/UserManagement/UserSessionChecker/user-session-checker.service';

@Component({
  selector: 'app-privacy-policy',
  templateUrl: './privacy-policy.component.html',
  styleUrls: ['./privacy-policy.component.css'],
})
export class PrivacyPolicyComponent implements OnInit {
  constructor(
    public router: Router,
    public checkUser: UserSessionCheckerService
  ) {}

  public goBack() {
    var godashboard = this.checkUser.check;
    if (godashboard) {
      this.router.navigate(['/dashboard/home']);
    } else {
      this.router.navigate(['/login']);
    }
  }

  ngOnInit(): void {}
}
