import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { MatDialog } from '@angular/material/dialog';
import { DepartmentApiService } from 'src/app/Services/DepartmentManagement/department-api.service';
import { RemoveUserFromDepartmentResponseComponent } from '../remove-user-from-department-response/remove-user-from-department-response.component';

@Component({
  selector: 'app-confirmation-remove-user-from-department',
  templateUrl: './confirmation-remove-user-from-department.component.html',
  styleUrls: ['./confirmation-remove-user-from-department.component.css'],
})
export class ConfirmationRemoveUserFromDepartmentComponent implements OnInit {
  constructor(
    private dialogRef: MatDialogRef<ConfirmationRemoveUserFromDepartmentComponent>,
    private departmentApiService: DepartmentApiService,
    private dialog: MatDialog
  ) {}
  public userNum = 0;
  public userNames: Array<string> = new Array<string>();
  public idDepartmentUsers: Array<string> = new Array<string>();

  public closeDialog() {
    this.dialogRef.close();
  }

  confirmDeleteUsers() {
    this.departmentApiService
      .removeUserFromDepartment(this.idDepartmentUsers)
      .subscribe({
        next: () => {
          var dialog = this.dialog.open(
            RemoveUserFromDepartmentResponseComponent,
            {
              width: '30em',
              height: '18em',
            }
          );

          dialog.componentInstance.userRemoved = true;
        },
        error: () => {
          var dialog = this.dialog.open(
            RemoveUserFromDepartmentResponseComponent,
            {
              width: '30em',
              height: '18em',
            }
          );

          dialog.componentInstance.userRemoved = false;
        },
      });

    this.dialogRef.close({ remove: false });
  }

  ngOnInit(): void {}
}
