import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from './../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class SessionsManagementAPIService {
  constructor(private http: HttpClient) {}

  public token = '';

  public getSessions(
    sessionId: string,
    localId: string,
    remoteId: string,
    status: string,
    quality: string
  ): Observable<string> {
    this.token = window.localStorage.getItem('Authorization')!;

    var headers = new HttpHeaders().set('Authorization', this.token);

    var response = this.http.post<string>(
      environment.URLgetsessions,
      {
        sessionId: sessionId,
        localId: localId,
        remoteId: remoteId,
        status: status,
        quality: quality,
      },
      {
        headers: headers,
      }
    );

    return response;
  }

  public getSessionsLastMonth(
    sessionId: string,
    localId: string,
    remoteId: string,
    status: string,
    quality: string
  ): Observable<string> {
    this.token = window.localStorage.getItem('Authorization')!;

    var headers = new HttpHeaders().set('Authorization', this.token);

    var response = this.http.post<string>(
      environment.URLgetsessionslastmonth,
      {
        sessionId: sessionId,
        localId: localId,
        remoteId: remoteId,
        status: status,
        quality: quality,
      },
      {
        headers: headers,
      }
    );

    return response;
  }

  public removeSessions(showSession: Array<string>): Observable<string> {
    this.token = window.localStorage.getItem('Authorization')!;

    var headers = new HttpHeaders().set('Authorization', this.token);
    var response = this.http.post<string>(
      environment.URLupdateshowsession,
      {
        sessionsIds: showSession,
      },
      {
        headers: headers,
      }
    );

    return response;
  }

  public saveFile(fileName: string): Observable<string> {
    var token = window.localStorage.getItem('Authorization')!;

    var headers = new HttpHeaders().set('Authorization', token);

    var sessionId = window.localStorage.getItem('sessionId')!;

    var response = this.http.post<string>(
      environment.URLuploadFile,
      {
        key: 'WallboxSessionReports/' + sessionId + '/' + fileName,
      },
      {
        headers: headers,
      }
    );

    return response;
  }

  public updateSessionReport(
    sessionId: string,
    sessionReportUpdated: Map<string, string>
  ): Observable<string> {
    this.token = window.localStorage.getItem('Authorization')!;

    var headers = new HttpHeaders().set('Authorization', this.token);

    var jsonObject = Object.fromEntries(sessionReportUpdated);

    var response = this.http.post<string>(
      environment.URLupdatesessionreport,
      {
        idsession: sessionId,
        report: jsonObject,
      },
      {
        headers: headers,
      }
    );

    return response;
  }
}
