import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './Components//login/login.component';
import { SessionsComponent } from './Components/management/sessions/sessions.component';
import { SessionsReportComponent } from './Components/management/sessions/sessions-report/sessions-report.component';
import { DashboardComponent } from './Components/dashboard/dashboard.component';
import { HomeComponent } from './Components/dashboard/home/home.component';
import { FaqsComponent } from './Components/user/faqs/faqs.component';
import { UserManagementComponent } from './Components/management/user-management/user-management.component';
import { AddNewUserComponent } from './Components/management/user-management/creation/add-new-user/add-new-user.component';
import { ForceChangePasswordComponent } from './Components/login/force-change-password/force-change-password.component';
import { UserHelpComponent } from './Components/user/user-help/user-help.component';
import { ShowDisabledUsersDialogComponent } from './Components/management/user-management/disable/show-disabled-users/show-disabled-users.component';

import { PrivacyPolicyComponent } from './Components/common/legal/privacy-policy/privacy-policy.component';
import { LegalPolicyComponent } from './Components/common/legal/legal-policy/legal-policy.component';
import { ProfileComponent } from './Components/user/profile/profile.component';
import { PersonalDataComponent } from './Components/user/profile/personal-data/personal-data.component';
import { SecurityComponent } from './Components/user/profile/security/security.component';
import { DownloadableFilesComponent } from './Components/user/downloadable-files/downloadable-files.component';

import { VideosDownloadsComponent } from './Components/user/downloadable-files/videos-downloads/videos-downloads.component';
import { DocumentsDownloadsComponent } from './Components/user/downloadable-files/documents-downloads/documents-downloads.component';
import { ExecutablesDownloadsComponent } from './Components/user/downloadable-files/executables-downloads/executables-downloads.component';

import { DepartmentManagementComponent } from './Components/management/department-management/department-management.component';
import { DepartmentUsersComponent } from './Components/management/department-management/department-users/department-users.component';

import { PageNotFoundComponentComponent } from './Components/common/page-not-found-component/page-not-found-component.component';

const routes: Routes = [
  { path: '', redirectTo: '/login', pathMatch: 'full' },
  { path: 'login', component: LoginComponent },
  { path: 'privacy-policy', component: PrivacyPolicyComponent },
  { path: 'legal-policy', component: LegalPolicyComponent },
  { path: 'changePassword', component: ForceChangePasswordComponent },
  {
    path: 'dashboard',
    component: DashboardComponent,
    children: [
      { path: 'home', component: HomeComponent },
      { path: 'sessions', component: SessionsComponent },
      {
        path: 'department-management',
        component: DepartmentManagementComponent,
      },
      {
        path: 'department-user-management/:departmentId',
        component: DepartmentUsersComponent,
      },
      { path: 'user-management', component: UserManagementComponent },
      {
        path: 'disabled-users',
        component: ShowDisabledUsersDialogComponent,
      },
      { path: 'user-management/new-user', component: AddNewUserComponent },
      { path: 'report/:sessionId', component: SessionsReportComponent },
      { path: 'faqs', component: FaqsComponent },
      { path: 'user-help', component: UserHelpComponent },
      {
        path: 'downloadable-files',
        component: DownloadableFilesComponent,
        children: [
          { path: '', component: VideosDownloadsComponent },
          { path: 'videos-downloads', component: VideosDownloadsComponent },
          {
            path: 'documents-downloads',
            component: DocumentsDownloadsComponent,
          },
          {
            path: 'executables-downloads',
            component: ExecutablesDownloadsComponent,
          },
        ],
      },
      {
        path: 'profile',
        component: ProfileComponent,
        children: [
          { path: '', component: PersonalDataComponent },
          {
            path: 'personal-data',
            component: PersonalDataComponent,
          },
          { path: 'security', component: SecurityComponent },
        ],
      },
    ],
  },
  { path: '**', pathMatch: 'full', component: PageNotFoundComponentComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { onSameUrlNavigation: 'reload' })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
