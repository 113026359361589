<div
  (keyup.enter)="
    cmpNewPassword() != 0 &&
    !oPassword.invalid &&
    rPassword.value == nPassword.value
      ? confirm()
      : ''
  "
>
  <mat-form-field class="example-full-width" appearance="fill">
    <mat-label i18n>Old password</mat-label>
    <input
      class="passwordInput"
      #oldPassword
      matInput
      [formControl]="oPassword"
      type="password"
      required
    />
  </mat-form-field>
  <mat-form-field class="example-full-width" appearance="fill">
    <mat-label i18n>New password</mat-label>
    <input
      #newPassword
      class="passwordInput"
      [formControl]="nPassword"
      matInput
      type="password"
      required
    />
  </mat-form-field>

  <label
    class="errorMessage"
    *ngIf="nPassword.value == oPassword.value && nPassword.value != ''"
    i18n
    >Please, the new password cant be the same old password</label
  >
  <label
    class="errorMessage"
    *ngIf="cmpNewPassword() == 0 && nPassword.value != ''"
    >{{ getNewPasswordError() }}</label
  >
  <mat-form-field class="example-full-width" appearance="fill">
    <mat-label i18n>Introduce the password again</mat-label>
    <input
      class="passwordInput"
      #newPassword
      [formControl]="rPassword"
      matInput
      type="password"
      required
    />
  </mat-form-field>
  <label class="errorMessage">{{ repeatPasswordError() }}</label>
  <br />
  <label i18n>
    Must be at least 8 characters long, with numbers, letters and symbols</label
  >
  <button
    [disabled]="
      cmpNewPassword() == 0 ||
      oPassword.invalid ||
      rPassword.value != nPassword.value
    "
    class="confirmButton"
    mat-button
    (click)="confirm()"
    i18n
  >
    Confirm
  </button>
  <button class="confirmButton" mat-button (click)="cancel()" i18n>
    Cancel
  </button>
</div>
