<header>
  <link
    rel="stylesheet"
    href="https://fonts.googleapis.com/css2?family=Material+Symbols+Rounded:opsz,wght,FILL,GRAD@48,400,1,0"
  />
  <link
    rel="stylesheet"
    href="https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@48,400,0,0"
  />
</header>
<body>
  <div class="container hidden" id="hiddenContainer">
    <mat-accordion>
      <mat-expansion-panel hideToggle>
        <mat-expansion-panel-header>
          <mat-panel-title i18n class="matPanelTitle">
            <mat-icon>view_in_ar</mat-icon> Quick login on the HoloLens device
          </mat-panel-title>
          <mat-panel-description
            *ngIf="!small"
            i18n
            class="matPanelDescription"
          >
            Steps to login on the HoloLens device with a QR code.
          </mat-panel-description>
        </mat-expansion-panel-header>
        <p i18n>
          First, go to the "user"
          <mat-icon class="iconText">person</mat-icon> icon (top-right) and
          click on "QR Access". Then, a panel will open and a QR code will be
          shown. This QR code can be scanned with the HoloLens device to
          automatically login into the application.
        </p></mat-expansion-panel
      >

      <mat-expansion-panel hideToggle>
        <mat-expansion-panel-header>
          <mat-panel-title i18n class="matPanelTitle">
            <span class="material-symbols-outlined spanIcon"> download </span>
            Download Infinity Mentor App
          </mat-panel-title>
          <mat-panel-description
            *ngIf="!small"
            i18n
            class="matPanelDescription"
          >
            Steps to "Download Infinity Mentor App".
          </mat-panel-description>
        </mat-expansion-panel-header>
        <p i18n>
          First, go to the
          <b
            >"<span class="material-symbols-outlined"> download </span>
            Downloads"</b
          >
          screen and click on "Executables". Then, press the download button in
          the <b>"Skylife Infinity Mentor - Hololens"</b> or
          <b>"Skylife Infinity Mentor - PC"</b>.
        </p>
        <p i18n>
          If you want, you can use the
          <b
            >"<span class="material-symbols-outlined"> qr_code_2 </span>QR
            Code"</b
          >
          button to download the executables in the HoloLens.
        </p>
      </mat-expansion-panel>
      <mat-expansion-panel hideToggle>
        <mat-expansion-panel-header>
          <mat-panel-title i18n class="matPanelTitle">
            <mat-icon>delete</mat-icon> Delete sessions
          </mat-panel-title>
          <mat-panel-description
            *ngIf="!small"
            i18n
            class="matPanelDescription"
          >
            Steps to "Delete sessions".
          </mat-panel-description>
        </mat-expansion-panel-header>
        <p i18n>
          First, go to <b>sessions list</b>, and click on
          <b>"Delete sessions"</b>. Once you have done this, a new column will
          be displayed with boxes to select the sessions you want to delete.
        </p>

        <p i18n>
          When the sessions have been selected, click on <b>"Delete"</b> and
          <b>"Confirm"</b>
          this action.
        </p>
        <h4 i18n>Select and deselect all</h4>
        <p i18n>
          If you want to select all the sessions, press on top box. Do the same
          to deselect all the sessions.
        </p>
        <h4 i18n>Cancel</h4>
        <p i18n>
          If you want to cancel the delete sessions action you have to click on
          <b>"Cancel"</b>, this button deselect the boxes.
        </p>
        <p i18n>
          If you forget to select a session when you have press
          <b>"Delete"</b>, click <b>"Back"</b> and continue selecting.
        </p>
      </mat-expansion-panel>
      <mat-expansion-panel hideToggle>
        <mat-expansion-panel-header>
          <mat-panel-title i18n class="matPanelTitle"
            ><mat-icon>delete</mat-icon> Disable users
          </mat-panel-title>
          <mat-panel-description
            *ngIf="!small"
            i18n
            class="matPanelDescription"
          >
            Steps to "Disable users".
          </mat-panel-description>
        </mat-expansion-panel-header>
        <p i18n>
          First, go to <b>user list</b> (this option is reserved for
          administrators), and click on "Disable users". Once you have done
          this, a new column will be displayed with boxes to select the users
          you want to delete.
        </p>

        <p i18n>
          When the users have been selected, click on <b>"Disable"</b> and
          <b>"Confirm"</b>
          this action.
        </p>
        <h4 i18n>Select and deselect all</h4>
        <p i18n>
          If you want to select all the users, press on top box. Do the same to
          deselect all the users.
        </p>
        <h4 i18n>Cancel</h4>
        <p i18n>
          If you want to cancel the disable users action you have to click on
          <b>"Cancel"</b>, this button deselect the boxes.
        </p>
        <p i18n>
          If you forget to select a user when you have press <b>"Disable"</b>,
          click <b>"Back"</b> and continue selecting.
        </p>
      </mat-expansion-panel>
      <mat-expansion-panel hideToggle>
        <mat-expansion-panel-header>
          <mat-panel-title i18n class="matPanelTitle"
            ><mat-icon>person_add</mat-icon> Add new user
          </mat-panel-title>
          <mat-panel-description
            *ngIf="!small"
            i18n
            class="matPanelDescription"
          >
            Steps to "Add new user".
          </mat-panel-description>
        </mat-expansion-panel-header>
        <p i18n>
          First, go to <b>user list</b> (this option is reserved for
          administrators), and press on <b>"Add new user"</b>. Once you have
          done this, a panel will open and display a series of fields required
          for the creation of a new user.
        </p>
        <p i18n>
          These fields are the new user's email, the type and the hardware that
          the user will use.
        </p>
        <p i18n>
          When all fields are correctly completed, click <b>"Add user</b>".
          These action send a email to the user email with a random password.
          Login with these password and the sistem will require you a new
          password.
        </p>
        <h4 i18n>User type</h4>
        <p i18n>We have three types of users:</p>
        <ul>
          <li i18n>Admin: This user has access to the complete application</li>
          <li i18n>Expert: This user does not have access to the users list</li>
          <li i18n>
            Operator: This user has only access to the Hololens or desktop
            application
          </li>
        </ul>
      </mat-expansion-panel>
      <mat-expansion-panel hideToggle>
        <mat-expansion-panel-header>
          <mat-panel-title i18n class="matPanelTitle"
            ><span class="material-symbols-rounded iconHead"> key </span> Change
            password</mat-panel-title
          >
          <mat-panel-description
            *ngIf="!small"
            i18n
            class="matPanelDescription"
          >
            Steps to "Change password".
          </mat-panel-description>
        </mat-expansion-panel-header>
        <p i18n>
          First go to the "user" icon (top-right
          <mat-icon class="iconText">person</mat-icon>), and click on
          <b>"Profile"</b>. Once you have done this, a new window will open,
          after that, click on <b>"Security"</b> and scroll down to the bottom.
          Press the <b>"Change password"</b> button to change the old password
          for a new password.
        </p>
        <p i18n>
          When all fields are correctly completed, press <b>"Confirm"</b>. Then
          a confirm dialog will display.
        </p>
      </mat-expansion-panel>
      <mat-expansion-panel hideToggle>
        <mat-expansion-panel-header>
          <mat-panel-title i18n class="matPanelTitle"
            ><span class="material-symbols-rounded iconHead"> key </span>

            Change user password by admin
          </mat-panel-title>
          <mat-panel-description
            *ngIf="!small"
            i18n
            class="matPanelDescription"
          >
            Steps to "Change user password by admin".
          </mat-panel-description>
        </mat-expansion-panel-header>
        <p i18n>
          First, go to <b>user list</b> (this option is reserved for
          administrators), and click on <b>"Change user password"</b>. Once you
          have done this, a panel will open and you will be requested for the
          following information:
        </p>
        <ul>
          <li i18n>User name (user's email)</li>
          <li i18n>User's new password</li>
        </ul>
        <p i18n>
          When all fields are correctly completed, press <b>"Confirm</b>". Then
          a confirm dialog will display.
        </p>
      </mat-expansion-panel>
      <mat-expansion-panel hideToggle>
        <mat-expansion-panel-header>
          <mat-panel-title i18n class="matPanelTitle"
            ><mat-icon>calendar_today</mat-icon> Filter by user creation
            date</mat-panel-title
          >
          <mat-panel-description
            *ngIf="!small"
            i18n
            class="matPanelDescription"
          >
            Steps to "Filter by user creation date".
          </mat-panel-description>
        </mat-expansion-panel-header>
        <p i18n>
          First, go to <b>user list</b> (this option is reserved for
          administrators), and click on <b>"Filter by creation date"</b>. Once
          you have done this, a panel will open in which you must enter a start
          date and an end date that is later than the start date.
        </p>
        <p i18n>
          When all fields are correctly completed, press <b>"Filter"</b>. Then
          the users list will be updated.
        </p>
      </mat-expansion-panel>

      <mat-expansion-panel hideToggle>
        <mat-expansion-panel-header>
          <mat-panel-title class="matPanelTitle" i18n
            ><mat-icon>assignment</mat-icon> Add media in reports
          </mat-panel-title>
          <mat-panel-description
            *ngIf="!small"
            i18n
            class="matPanelDescription"
          >
            Steps to "Add media in reports".
          </mat-panel-description>
        </mat-expansion-panel-header>
        <p i18n>
          First, go to <b>sessions list</b>, and click a
          <b
            >"<mat-icon class="iconText">description</mat-icon> session with
            report"</b
          >. Once you enter this session, the report will open and will show you
          the information contained in the report. Press on
          <b>"Edit report"</b> and, next, enter in <b>"Add media"</b>.
        </p>
        <p i18n>
          In this section, select the file you want to add and press on
          <b>"Upload"</b>.
        </p>
      </mat-expansion-panel>
      <mat-expansion-panel hideToggle>
        <mat-expansion-panel-header>
          <mat-panel-title i18n class="matPanelTitle"
            ><span class="material-symbols-rounded iconHead">
              person_add_disabled
            </span>
            Show disabled users
          </mat-panel-title>
          <mat-panel-description
            *ngIf="!small"
            i18n
            class="matPanelDescription"
          >
            Steps to "Show disabled users".
          </mat-panel-description>
        </mat-expansion-panel-header>
        <p i18n>
          First, go to <b>user list</b> (this option is reserved for
          administrators), and click on <b>"Show disabled users"</b>. Once you
          have done this, a table will open with the users that have been
          disabled.
        </p>
        <h4 i18n>Reactivate user</h4>
        <p i18n>
          If you want to reactivate a particular user, click on
          <b
            >"<mat-icon class="iconText">how_to_reg</mat-icon> Reactivate
            user"</b
          >. A panel will open to change the password of the selected user.
          Proceed to change the password to reactivate the user.
        </p>
      </mat-expansion-panel>
    </mat-accordion>
  </div>
</body>
