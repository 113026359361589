import { Component, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import '@angular/localize/init';
import { UserManagementAPIService } from '../../../Services/UserManagement/user-management-api.service';
import { UserDataApiService } from '../../../Services/UserDataService/user-data-api.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-force-change-password',
  templateUrl: './force-change-password.component.html',
  styleUrls: ['./force-change-password.component.css'],
})
export class ForceChangePasswordComponent implements OnInit {
  constructor(
    private refdialog: MatDialogRef<ForceChangePasswordComponent>,
    public userManagementAPIService: UserManagementAPIService,
    public userDataAPIService: UserDataApiService
  ) {}

  public newPassword = new FormControl('', [
    Validators.required,
    Validators.minLength(8),
  ]);
  public newRepeatedPassword = new FormControl('', [Validators.required]);
  public token = '';

  public cmpNewPassword() {
    if (this.newPassword.value!.length < 8) {
      return 0;
    } else if (
      this.newPassword.value!.replace(/\w/g, '').length ==
      this.newPassword.value!.length
    ) {
      return 0;
    } else if (
      this.newPassword.value!.replace(/\d/g, '').length ==
      this.newPassword.value!.length
    ) {
      return 0;
    } else if (
      this.newPassword.value!.replace(/\W||\D/g, '').length ==
      this.newPassword.value!.length
    ) {
      return 0;
    } else if (
      this.newPassword.value!.replace(/[A-Z]/g, '').length ==
      this.newPassword.value!.length
    ) {
      return 0;
    }
    return 1;
  }

  public confirm() {
    this.userManagementAPIService
      .forceChangePassword(this.newPassword.value!)
      .subscribe({
        next: () => {
          this.userManagementAPIService
            .addNewUserPasswordCognito(this.newPassword.value!)
            .subscribe({
              next: () => {
                var qrData =
                  'skylifeinfinity' +
                  '[SIS]' +
                  localStorage.getItem('SessionUsername') +
                  '[SIS]' +
                  this.newPassword.value!;

                var accessQrCode = this.userDataAPIService.encrypt(
                  environment.secret,
                  qrData
                );

                this.userDataAPIService
                  .updateUserQRAccessCode(
                    localStorage.getItem('SessionUsername'),
                    accessQrCode
                  )
                  .subscribe({
                    next: () => {
                      this.refdialog.close({ changeSuccessfully: true });
                    },
                    error: (err) => {
                      alert('error encrypting pass');
                      console.error(err);
                    },
                  });
              },
              error: (err) => {
                console.error(err);
              },
            });
        },
        error: (error) => {
          this.refdialog.close({ changeSuccessfully: false });
          console.error(error);
        },
      });
  }

  public getNewPasswordError() {
    if (this.newPassword.invalid) {
      return $localize`The password is too short`;
    } else if (
      this.newPassword.value!.replace(/\w/g, '').length ==
      this.newPassword.value!.length
    ) {
      return $localize`The password must contain letters`;
    } else if (
      this.newPassword.value!.replace(/\d/g, '').length ==
      this.newPassword.value!.length
    ) {
      return $localize`The password must contain numbers`;
    } else if (
      this.newPassword.value!.replace(/[A-Z]/g, '').length ==
      this.newPassword.value!.length
    ) {
      return $localize`The password must contain capital letters`;
    } else {
      return $localize`The password must contain special characters`;
    }
  }

  public ngOnInit(): void {}

  public repeatPasswordError() {
    if (
      this.newRepeatedPassword.value != this.newPassword.value &&
      this.newRepeatedPassword.value != ''
    ) {
      return $localize`The password must be the same`;
    }
    return '';
  }
}
