import {
  Component,
  HostListener,
  OnInit,
  ViewChild,
  OnDestroy,
} from '@angular/core';
import { SelectionModel } from '@angular/cdk/collections';
import { MatTableDataSource } from '@angular/material/table';
import { FormControl } from '@angular/forms';
import { UserManagementAPIService } from '../../../Services/UserManagement/user-management-api.service';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { DatePipe } from '@angular/common';
import { SessionsDatePickerFilterDialogComponent } from '../sessions/sessions-date-picker-filter-dialog/sessions-date-picker-filter-dialog.component';
import { User } from '../../../Interfaces/userInterface';
import { MatPaginator } from '@angular/material/paginator';
import { AddNewUserComponent } from './creation/add-new-user/add-new-user.component';
import { DialogDisableUserConfirmComponent } from './disable/dialog-disable-user-confirm/dialog-disable-user-confirm.component';
import { DisabledUserConfirmationComponent } from './disable/disabled-user-confirmation/disabled-user-confirmation.component';
import { UserTypeNotAllowedDialogComponent } from '../../common/common-error-dialogs/user-type-not-allowed-dialog/user-type-not-allowed-dialog.component';
import { MatSort } from '@angular/material/sort';
import { UserSessionCheckerService } from '../../../Services/UserManagement/UserSessionChecker/user-session-checker.service';
import '@angular/localize/init';
import { ChangeUserPassComponent } from './modification/admin-change-user-pass/admin-change-user-pass.component';
import { CustomMatPaginatorIntl } from 'src/app/custom-mat-paginator-int';
import { DeactivateUserMFAComponent } from './modification/deactivate-user-mfa/deactivate-user-mfa.component';
import { EditUserProfileAdminDialogComponent } from './modification/edit-user-profile-admin-dialog/edit-user-profile-admin-dialog.component';

@Component({
  selector: 'app-user-management',
  templateUrl: './user-management.component.html',
  styleUrls: ['./user-management.component.css'],
})
export class UserManagementComponent implements OnInit, OnDestroy {
  constructor(
    private userManagementAPIService: UserManagementAPIService,
    private dialog: MatDialog,
    private router: Router,
    private datePipe: DatePipe,
    private userSessionCheckerService: UserSessionCheckerService
  ) {}

  @ViewChild(CustomMatPaginatorIntl)
  public customPaginator: CustomMatPaginatorIntl;
  @ViewChild(MatPaginator) public paginator: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;

  public EmptyUserList: Array<User> = [];
  public cancelDisableUsers = false;
  public checkColumnsToDisable: string[] = [
    'select',
    'username',
    'creationDate',
    'userType',
    'changePass',
  ];
  public dataSource = new MatTableDataSource<User>();
  public disableUserList = new Array<string>();
  public displayedColumns: string[] = [
    'username',
    'creationDate',
    'userType',
    'changePass',
  ];
  public displayedColumnsInitial: string[] = [
    'username',
    'creationDate',
    'userType',
    'changePass',
  ];
  public endDate = '';
  public endDateFormated = '';
  public filterLabels = new Map<string, string>([
    [$localize`Username`, 'username'],
    [$localize`User Type`, 'userType'],
  ]);
  public filterOptionList: string[] = [
    $localize`Username`,
    $localize`User Type`,
  ];
  public changeuserpassword = false;
  public filterOptions = new FormControl<string[]>([$localize`Username`]);
  public filterOptionsByDefault: string[] = [$localize`Username`];
  public filterSelect = '';
  public filtering = false;
  public filteringByStartDate = false;
  public filteringDate = '';
  public isFiltered = false;
  public removing = false;
  public selection = new SelectionModel<User>(true, []);
  public smallScreenMode = false;
  public startDate = '';
  public startDateFormated = '';
  public token = '';
  public userList: Array<User> = [];
  public userNameList: Array<string> = [];
  public userSessionCheckerServiceSubscription: any;

  public userCapabilities: string[] = JSON.parse(
    localStorage.getItem('userCapabilities')
  );

  @HostListener('window:resize', ['$event'])
  public onResize(event: UIEvent) {
    const w = event.target as Window;
    this.setTableColumnsDependingOnWidth(w.innerWidth);
  }

  public editUser(element: User) {
    if (!this.changeuserpassword) {
      var dialogRef = this.dialog.open(EditUserProfileAdminDialogComponent, {
        width: '95%',
        height: '95%',
      });
      dialogRef.componentInstance.user = element;
    }
  }

  public applyFilter(event: Event) {
    this.cancelDisablingUsers();
    this.filteringByStartDate = false;
    this.filtering = false;

    const filterValue = (event.target as HTMLInputElement).value.toLowerCase();
    this.dataSource.data = [];
    var userListFiltered: Array<User> = [];
    this.filterOptions.value?.forEach((element) => {
      var filterId = this.filterLabels.get(element) as string;
      filterId ??= '';
      this.userList.filter((user) => {
        if (
          user[filterId].toLowerCase().includes(filterValue) &&
          !userListFiltered.includes(user)
        ) {
          userListFiltered.push(user);
        }
      });
    });
    this.generateDataSource(userListFiltered);
  }

  public disableRow() {
    this.changeuserpassword = true;
  }
  public disableRowOut() {
    this.changeuserpassword = false;
  }

  public cancelDisablingUsers() {
    this.selection.clear();
    this.removing = false;
    this.displayedColumns = this.displayedColumnsInitial;
  }

  public changeUserPass(username: string) {
    this.getUsersNames();
    this.userManagementAPIService.setUsersNameList(this.userNameList);
    var dialogRef = this.dialog.open(ChangeUserPassComponent, {
      height: '30em',
      width: '30em',
    });
    dialogRef.componentInstance.username = username;
    dialogRef.componentInstance.reactivate = false;
  }

  public checkSessionDate(date: Date): boolean {
    var errorDate = new Date('2000-01-01 00:00:00');

    if (date.toDateString() == errorDate.toDateString()) {
      return true;
    } else {
      return false;
    }
  }

  public checkboxLabel(row?: User): string {
    if (!row) {
      return `${this.isAllSelected() ? 'deselect' : 'select'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'}`;
  }

  public disableUsers() {
    this.usersChecked();

    var dialogRef = this.dialog.open(DialogDisableUserConfirmComponent, {
      height: '250px',
      width: '400px',
    });

    dialogRef.componentInstance.usersNum = this.disableUserList.length;

    dialogRef.afterClosed().subscribe((result) => {
      var res = Object.values(result);
      if (res[0]) {
        if (this.disableUserList.length != 0) {
          if (this.checkUserSession()) {
            this.userManagementAPIService
              .disabledUserCognito(this.disableUserList)
              .subscribe({
                next: () => {
                  this.userManagementAPIService
                    .updateDisabledStatus(this.disableUserList, 'disable')
                    .subscribe({
                      next: () => {
                        var dialogRef = this.dialog.open(
                          DisabledUserConfirmationComponent
                        );
                        dialogRef.componentInstance.disableUserSuccess = true;
                        this.cancelDisablingUsers();
                      },
                      error: () => {
                        var dialogRef = this.dialog.open(
                          DisabledUserConfirmationComponent
                        );
                        dialogRef.componentInstance.disableUserSuccess = false;
                      },
                    });
                  for (var i = 0; i < this.userList.length; i++) {
                    if (this.isInDisableUsersList(this.userList[i])) {
                      this.userList.splice(i, 1);
                      i--;
                    }
                  }

                  this.dataSource.data = [];
                  this.userList.forEach((element) => {
                    this.dataSource.data.push(element);
                  });

                  this.paginator._intl = this.customPaginator;
                  this.customPaginator.setTotalItems(this.userList.length);
                  this.dataSource.paginator = this.paginator;
                  this.dataSource.sort = this.sort;
                  this.cancelDisableUsers = true;
                },
                error: (error) => {
                  console.log(error);
                },
              });
          }
        }
      } else {
        this.showCheckBox();
      }
      if (this.cancelDisableUsers == true) {
        this.cancelDisablingUsers();
      }
    });
  }

  public filterByStartDates() {
    var dialogRef = this.dialog.open(SessionsDatePickerFilterDialogComponent, {
      height: '400px',
      width: '500px',
    });

    dialogRef.afterClosed().subscribe((result) => {
      this.cancelDisablingUsers();

      var date = Object.values(result);

      this.startDate = date[0] as string;
      this.endDate = date[1] as string;

      this.startDateFormated = this.startDate.replace('T', ' ');
      this.endDateFormated = this.endDate.replace('T', ' ');

      var cancel = date[2] as string;
      if (!cancel) {
        var userListFiltered = this.userList.filter((user) => {
          var startDateParsed = this.transformLocalDateToUTCDate(
            new Date(this.startDate).toString()
          );
          var endDateParsed = this.transformLocalDateToUTCDate(
            new Date(this.endDate).toString()
          );

          var userStartDateParsed = this.transformLocalDateToUTCDate(
            new Date(user.creationDate).toString()
          );

          if (
            userStartDateParsed.getTime() >= startDateParsed.getTime() &&
            userStartDateParsed.getTime() <= endDateParsed.getTime()
          ) {
            return true;
          }

          return false;
        });
        this.generateDataSource(userListFiltered);

        this.filtering = true;
        this.filteringByStartDate = true;

        this.dataSource.filterPredicate = function (record, filter) {
          const matchFilter: boolean[] = [];
          const filters = JSON.parse(filter);
          filters.forEach((element: { id: string; value: string }) => {
            const val = record[element.id] === null ? '' : record[element.id];
            matchFilter.push(
              val.toLowerCase().includes(element.value.toLowerCase())
            );
          });
          return matchFilter.every(Boolean);
        };
      }
    });
  }

  public getUserList(): void {
    this.userManagementAPIService.getUserList('', '', '', '', '').subscribe({
      next: (data: string) => {
        var json = JSON.stringify(data);
        var jsonParsed = JSON.parse(json);
        var type: string;

        this.cancelDisablingUsers();
        this.userList = [];
        this.dataSource.data = [];

        for (var key of Object.keys(jsonParsed['Users'])) {
          var user = <User>{};

          if (jsonParsed['Users'][key].disabled == 0) {
            user.username = jsonParsed['Users'][key].Username;
            user.creationDate = this.transformUTCDateToLocalDate(
              jsonParsed['Users'][key].creationDate
            );
            type = jsonParsed['Users'][key].userType;

            if (type == 'NONE') {
              user.userType = $localize`None`;
            } else if (type == 'ADMIN') {
              user.userType = $localize`Admin`;
            } else if (type == 'USER_N1') {
              user.userType = $localize`Operator`;
            } else if (type == 'USER_N2') {
              user.userType = $localize`Expert`;
            } else if (type == '') {
              user.userType = $localize`User type not setted`;
            }

            this.userList.push(user);
          }
        }
        this.userList.forEach((element) => {
          this.dataSource.data.push(element);
        });

        this.dataSource = new MatTableDataSource<User>(this.dataSource.data);

        this.paginator._intl = this.customPaginator;
        this.customPaginator.setTotalItems(this.userList.length);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;

        this.dataSource.filterPredicate = function (record, filter) {
          const matchFilter: boolean[] = [];
          const filters = JSON.parse(filter);
          filters.forEach((element: { id: string; value: string }) => {
            const val = record[element.id] === null ? '' : record[element.id];
            matchFilter.push(
              val.toLowerCase().includes(element.value.toLowerCase())
            );
          });
          return matchFilter.every(Boolean);
        };
      },
      error: (error) => {
        console.error('There was an error!', error);
      },
    });
  }

  public isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  public isInDisableUsersList(user: User) {
    for (var i = 0; i < this.disableUserList.length; i++) {
      if (user.username == this.disableUserList[i]) {
        return true;
      }
    }
    return false;
  }

  public ngOnInit(): void {
    this.userSessionCheckerService.checkUserSession();
    this.userSessionCheckerServiceSubscription =
      this.userSessionCheckerService.currentUserStatus.subscribe((status) => {
        if (status == true) {
          document.getElementById('hiddenContainer').classList.remove('hidden');

          this.token = window.localStorage.getItem('Authorization')!;

          this.setTableColumnsDependingOnWidth(window.innerWidth);

          if (!this.userCapabilities.includes('VU')) {
            console.log('USER MANAGEMENT: User type not allowed');
            var dialogRef = this.dialog.open(
              UserTypeNotAllowedDialogComponent,
              {
                height: '15em',
                width: '40em',
              }
            );

            dialogRef.afterClosed().subscribe(() => {
              this.router.navigate(['/dashboard/home']);
            });
          }

          if (this.userCapabilities.includes('VU')) {
            this.cancelDisableUsers;
            this.getUserList();
          }
        }
      });
  }

  ngOnDestroy(): void {
    this.userSessionCheckerServiceSubscription.unsubscribe();
  }

  public openNewUserDialog() {
    var dialogRef = this.dialog.open(AddNewUserComponent, {
      height: '38em',
      width: '23em',
      panelClass: 'mat-dialog-width-transition',
    });

    dialogRef.componentInstance.token = this.token;
  }

  public reload(): void {
    window.location.reload();
  }

  //replace / with - in the given string and return it
  public replaceSlash(date: string): string {
    var dateArray = date.split('/');
    var dateString = dateArray[2] + '-' + dateArray[1] + '-' + dateArray[0];
    return dateString;
  }

  public setTableColumnsDependingOnWidth(width: number) {
    if (width < 600) {
      this.smallScreenMode = true;
    } else if (width >= 600) {
      this.displayedColumns = this.displayedColumnsInitial;
      this.smallScreenMode = false;
    }
  }

  public showCheckBox() {
    this.removing = true;
    this.displayedColumns = this.checkColumnsToDisable;
  }

  public showDisabledUsers() {
    this.router.navigateByUrl('dashboard/disabled-users');
  }

  public toggleAllRows() {
    if (this.isAllSelected()) {
      this.selection.clear();
      return;
    }

    this.selection.select(...this.dataSource.data);
  }

  //transform the date to dd-MM-yyyy string
  public transformDate(date: Date): string {
    return this.datePipe.transform(date, 'yyyy-MM-dd')!;
  }

  public transformLocalDateToUTCDate(date: string) {
    var localDate = new Date(date);
    var UTCDate = new Date(localDate).toUTCString();

    return new Date(UTCDate);
  }

  public transformLocalDateToUTCDate2(dateString: string) {
    /*var localDate = new Date(date);
    var UTCDate = new Date(localDate + 'UTC');

    return UTCDate;*/
    var date = new Date(dateString);
    const timestamp = Date.UTC(
      date.getFullYear(),
      date.getMonth(),
      date.getDate(),
      date.getHours(),
      date.getMinutes(),
      date.getSeconds(),
      date.getMilliseconds()
    );

    return new Date(timestamp);
  }

  public transformUTCDateToLocalDate(date: string): Date {
    var dateTest = new Date(date);

    var newDate = new Date(
      dateTest.getTime() + dateTest.getTimezoneOffset() * 60 * 1000
    );

    var offset = dateTest.getTimezoneOffset() / 60;
    var hours = dateTest.getHours();

    newDate.setHours(hours - offset);

    if (newDate.toString() != 'Invalid Date') {
      return newDate;
    } else {
      var dateError = new Date('2000-01-01 00:00:00');
      return dateError;
    }
  }

  public usersChecked() {
    this.disableUserList = [];
    this.selection.selected.forEach((element) => {
      this.disableUserList.push(element.username);
    });
  }

  private checkUserSession(): boolean {
    this.userSessionCheckerService.checkUserSession();
    return this.userSessionCheckerService.check;
  }

  private generateDataSource(userList: Array<User>) {
    this.dataSource.data = [];

    userList.forEach((element) => {
      this.dataSource.data.push(element);
    });
    this.paginator._intl = this.customPaginator;
    this.customPaginator.setTotalItems(userList.length);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  private getUsersNames() {
    this.userList.forEach((element) => {
      this.userNameList.push(element.username);
    });
  }

  public showDeactivation2FADialog() {
    var usernameList = [];

    this.userList.forEach((element) => {
      usernameList.push(element.username);
    });

    var deactivate2FADialogthis = this.dialog.open(DeactivateUserMFAComponent, {
      height: '12em',
      width: '20em',
    });
    deactivate2FADialogthis.componentInstance.options = usernameList;
  }
}
