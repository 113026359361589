<div class="container">
  <div class="content">
    <div class="info">
      <label *ngIf="userRemoved" i18n>User(s) successfully removed</label>
      <label *ngIf="!userRemoved" i18n
        >Sorry we could not remove the selected user(s), try again later</label
      >
    </div>
    <div class="buttonDiv">
      <button color="primary" mat-raised-button i18n (click)="close()">
        Close
      </button>
    </div>
  </div>
</div>
