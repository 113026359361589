<div class="container">
  <div class="content">
    <div *ngIf="emptySelectedDepartments" class="infoEmpty">
      Please select a department to be removed
    </div>
    <div *ngIf="!emptySelectedDepartments" class="info">
      You are about to remove the following departments:
    </div>
    <div *ngIf="!emptySelectedDepartments" class="listOfDepartments">
      <div class="department" *ngFor="let department of selectedDepartments">
        {{ department.title }}
      </div>
    </div>
    <div *ngIf="!emptySelectedDepartments" class="buttonDiv">
      <button
        mat-raised-button
        color="primary"
        class="confirmButton"
        (click)="removeSelected()"
      >
        <mat-icon>delete_forever</mat-icon> <label> Remove </label>
      </button>
      <button
        mat-raised-button
        color="error"
        class="cancelButton"
        (click)="cancel()"
      >
        <mat-icon>clear</mat-icon> <label> Cancel </label>
      </button>
    </div>
    <div *ngIf="emptySelectedDepartments" class="buttonDiv">
      <button
        mat-raised-button
        color="error"
        class="cancelButton"
        (click)="cancel()"
      >
        <mat-icon>clear</mat-icon> <label> Exit </label>
      </button>
    </div>
  </div>
</div>
