<div class="container">
  <div class="content">
    <div class="info" i18n>Select the user to be added</div>
    <div class="selectUserDialog">
      <mat-form-field class="example-full-width" appearance="fill">
        <mat-label>Users</mat-label>
        <input
          type="text"
          placeholder="Pick one"
          aria-label="Users"
          matInput
          [formControl]="userListForm"
          [matAutocomplete]="auto"
          #user
        />
        <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
          <mat-option
            *ngFor="let option of filteredOptions | async"
            [value]="option"
          >
            {{ option }}
          </mat-option>
          <mat-option
            *ngIf="userList.length == 0"
            [disabled]="true"
            i18n
            class="matoption"
          >
            No more users to add
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>
    </div>
    <div class="buttonDiv">
      <button
        mat-raised-button
        [disabled]="!checkUserSelected(user.value)"
        color="primary"
        class="confirmButton button"
        (click)="addUserToDepartment()"
      >
        <mat-icon>add</mat-icon> <label> Add </label>
      </button>
      <button
        mat-raised-button
        color="error"
        (click)="close()"
        class="cancelButton button"
      >
        <mat-icon>clear</mat-icon> <label> Cancel </label>
      </button>
    </div>
  </div>
</div>
