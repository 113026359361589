import { Component, OnInit } from '@angular/core';
import { UserManagementAPIService } from 'src/app/Services/UserManagement/user-management-api.service';
import { MatDialog } from '@angular/material/dialog';
import { MfaActivationDialogComponent } from './mfa-activation-dialog/mfa-activation-dialog.component';
import { MfaConfirmationResponseDialogComponent } from './mfa-activation-dialog/mfa-confirmation-dialog/mfa-confirmation-response-dialog/mfa-confirmation-response-dialog.component';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { ChangePasswordConfirmationDialogComponent } from './change-password/change-password-confirmation-dialog/change-password-confirmation-dialog.component';
import { environment } from 'src/environments/environment';
import { UserDataApiService } from 'src/app/Services/UserDataService/user-data-api.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-security',
  templateUrl: './security.component.html',
  styleUrls: ['./security.component.css'],
})
export class SecurityComponent implements OnInit {
  public mfaEnabled: boolean;

  constructor(
    public userManagementAPIService: UserManagementAPIService,
    public dialog: MatDialog,
    private router: Router,
    public userDataAPIService: UserDataApiService
  ) {}

  public enableMFA() {
    this.dialog.open(MfaActivationDialogComponent, {
      height: '40em',
      width: '25em',
    });
  }

  public changePassword() {
    var dialogRef = this.dialog.open(ChangePasswordComponent, {
      width: '50em',
      height: '40em',
    });

    dialogRef.afterClosed().subscribe((result) => {
      var json = JSON.stringify(result);
      var jsonParsed = JSON.parse(json);
      this.userManagementAPIService
        .changePassword(jsonParsed.oldPass, jsonParsed.newPass)
        .subscribe({
          next: () => {
            var qrData =
              'skylifeinfinity' +
              '[SIS]' +
              localStorage.getItem('SessionUsername') +
              '[SIS]' +
              jsonParsed.newPass;

            var accessQrCode = this.userDataAPIService.encrypt(
              environment.secret,
              qrData
            );

            this.userDataAPIService
              .updateUserQRAccessCode(
                localStorage.getItem('SessionUsername'),
                accessQrCode
              )
              .subscribe({
                next: () => {
                  window.localStorage.removeItem('Authorization');
                  window.localStorage.removeItem('AccessToken');

                  var dialog = this.dialog.open(
                    ChangePasswordConfirmationDialogComponent,
                    {
                      height: '15em',
                      width: '30em',
                    }
                  );

                  dialog.componentInstance.passwordChanged = true;
                  this.router.navigate(['/login']);
                },
                error: (err) => {
                  alert('error encrypting pass');
                  console.error(err);
                },
              });
          },
          error: (error) => {
            var dialog = this.dialog.open(
              ChangePasswordConfirmationDialogComponent,
              {
                height: '15em',
                width: '30em',
              }
            );
            console.error('There was an error!', error);

            var errorResponse = error.error.message;

            if (errorResponse.includes('Incorrect username or password')) {
              dialog.componentInstance.incorrectPassword = true;
              dialog.componentInstance.passwordChanged = false;
              dialog.componentInstance.tooManyAttempts = false;
            } else if (
              errorResponse.includes(
                'Attempt limit exceeded, please try after some time'
              )
            ) {
              dialog.componentInstance.incorrectPassword = false;
              dialog.componentInstance.passwordChanged = false;
              dialog.componentInstance.tooManyAttempts = true;
            } else {
              dialog.componentInstance.incorrectPassword = false;
              dialog.componentInstance.passwordChanged = false;
              dialog.componentInstance.tooManyAttempts = false;
            }
          },
        });
    });
  }

  public disableMFA() {
    this.userManagementAPIService.disableMFA().subscribe({
      next: () => {
        var responseDialog = this.dialog.open(
          MfaConfirmationResponseDialogComponent,
          {
            height: '10em',
            width: '25em',
          }
        );

        responseDialog.componentInstance.mfaIsEnabled = false;

        responseDialog.afterClosed().subscribe(() => {
          location.reload();
        });
        localStorage.removeItem('UserLoginMethod');
      },
      error: () => {
        var responseDialog = this.dialog.open(
          MfaConfirmationResponseDialogComponent,
          {
            height: '10em',
            width: '25em',
          }
        );

        responseDialog.componentInstance.errorInActivation = true;

        responseDialog.afterClosed().subscribe(() => {
          location.reload();
        });
      },
    });
  }

  ngOnInit(): void {}
}
