import { MatDialogRef } from '@angular/material/dialog';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-confirm-force-change-password',
  templateUrl: './confirm-force-change-password.component.html',
  styleUrls: ['./confirm-force-change-password.component.css'],
})
export class ConfirmForceChangePasswordComponent implements OnInit {
  constructor(
    public dialog: MatDialogRef<ConfirmForceChangePasswordComponent>
  ) {}

  public confirmation: boolean;

  public closeDialog() {
    this.dialog.close();
  }
  ngOnInit(): void {}
}
