<div class="container">
  <div class="content">
    <div *ngIf="departmentCreated" class="info" i18n>
      The department has been successfully created
    </div>
    <div *ngIf="!departmentCreated" class="info">
      The department could not be created, please, try again later
    </div>

    <div class="buttonDiv">
      <button
        mat-raised-button
        color="error"
        class="cancelButton"
        (click)="closeDialog()"
      >
        <mat-icon>clear</mat-icon> <label> Exit </label>
      </button>
    </div>
  </div>
</div>
