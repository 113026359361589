<div class="container">
  <div class="content">
    <label class="success" *ngIf="hasBeenDeactivated" i18n
      ><h3>The user 2FA has been deactivated</h3>
    </label>
    <label class="error" *ngIf="!hasBeenDeactivated" i18n>
      <h3>Error in deactivation, try again later</h3>
    </label>
    <div class="buttonDiv">
      <button mat-raised-button color="primary" (click)="closeDialog()" i18n>
        Go back
      </button>
    </div>
  </div>
</div>
