<div class="content">
  <br />
  <div class="container">
    <div id="personalDataContentId" class="header">
      <div class="headerContent">
        <div class="mat-icon-person">
          <img
            class="userImage"
            src="https://wallbox-web-dev.s3.eu-central-1.amazonaws.com/userProfilePictures/default/defaultUserProfileImage.png"
            alt="profilePicture"
          />
        </div>
        <div class="userNameAndDomainDiv">
          <mat-form-field class="name blackTextInputForm">
            <mat-label i18n class="whiteTextInputForm">NAME</mat-label>
            <input
              [disabled]="!edit"
              matInput
              class="aliasMatInput valueInputs textPersonalData"
              id="aliasInput"
              type="text"
              [value]="name"
              #nameInput
            />
          </mat-form-field>

          <mat-form-field class="domain blackTextInputForm">
            <mat-label i18n class="whiteTextInputForm">SURNAME</mat-label>
            <input
              [disabled]="!edit"
              matInput
              class="valueInputs textPersonalData"
              id="domainInput"
              type="text"
              [value]="surname"
              #surnameInput
            />
          </mat-form-field>
        </div>

        <div class="buttons">
          <button
            *ngIf="!this.edit"
            mat-raised-button
            class="editButton"
            (click)="editProfile()"
            color="primary"
            i18n
          >
            Edit profile
          </button>
          <button
            mat-raised-button
            class="saveButton"
            *ngIf="this.edit"
            (click)="
              updateValues(
                nameInput.value,
                surnameInput.value,
                languageInput.value
              )
            "
            color="primary"
            i18n
          >
            Save changes
          </button>
          <button
            mat-raised-button
            class="cancelButton"
            *ngIf="this.edit"
            (click)="cancel()"
            color="primary"
            i18n
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
    <div class="infoContent">
      <div class="personalInformation">
        <table>
          <tr class="tableRow">
            <td class="tableData">
              <mat-form-field class="materialInputForm">
                <mat-label i18n class="textPersonalData">Email</mat-label>
                <input
                  [disabled]="true"
                  matInput
                  class="valueInputs textPersonalData"
                  type="text"
                  [value]="this.email"
                  #emailInput
                />
              </mat-form-field>
            </td>
          </tr>
          <tr class="tableRow">
            <td class="tableData">
              <mat-form-field class="materialInputForm">
                <mat-label class="textPersonalData" i18n>Type</mat-label>
                <input
                  [disabled]="true"
                  matInput
                  type="text"
                  [value]="userType"
                  class="valueInputs textPersonalData"
                />
              </mat-form-field>
              <mat-form-field class="materialInputForm">
                <mat-label class="whiteTextInputForm" i18n>Language</mat-label>

                <mat-select
                  [disabled]="!edit"
                  matNativeControl
                  required
                  #languageInput
                  [(ngModel)]="language"
                  class="valueInputs textPersonalData"
                >
                  <mat-option value="Spanish" i18n>Spanish</mat-option>
                  <mat-option value="English" i18n>English</mat-option>
                </mat-select>
              </mat-form-field>
            </td>
          </tr>
          <tr class="tableRow">
            <td class="tableData">
              <mat-form-field class="materialInputForm">
                <mat-label class="textPersonalData" i18n
                  >Creation date</mat-label
                >
                <input
                  [disabled]="true"
                  matInput
                  type="text"
                  [value]="creationDate"
                  class="valueInputs textPersonalData"
                />
              </mat-form-field>
            </td>
          </tr>
          <tr class="tableRow">
            <td class="tableData">
              <mat-form-field class="materialInputForm">
                <mat-label class="textPersonalData" i18n
                  >Modification date</mat-label
                >
                <input
                  [disabled]="true"
                  matInput
                  type="text"
                  value="Not defined"
                  i18n-value="Not defined value"
                  class="valueInputs textPersonalData"
                />
              </mat-form-field>
            </td>
          </tr>
        </table>
      </div>
      <mat-divider [vertical]="true" class="verticalDivider"></mat-divider>
      <div class="workInformation">
        <table>
          <tr class="workInformationTitleTableRow">
            <td class="workInformationTitle" i18n>Work information</td>
          </tr>
          <tr>
            <td *ngIf="departments.length == 0" i18n>
              There is no work information available.
            </td>
            <td class="departmentTitle" *ngIf="departments.length > 0" i18n>
              Departments:
            </td>
            <td class="departmentContent">
              <div *ngFor="let dep of this.departments" class="valueInputs">
                <mat-list>
                  <mat-list-item>
                    <span class="textPersonalData" matListItemTitle>{{
                      dep
                    }}</span>
                  </mat-list-item>
                </mat-list>
              </div>
            </td>
          </tr>
        </table>
      </div>
    </div>
  </div>
</div>
