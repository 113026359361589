<head>
  <link
    rel="stylesheet"
    href="https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@48,400,0,0"
  />
  <link
    rel="stylesheet"
    href="https://fonts.googleapis.com/css2?family=Material+Symbols+Rounded:opsz,wght,FILL,GRAD@48,400,1,0"
  />
</head>
<body class="departmentUserBodyComponent">
  <div class="container">
    <div class="filter-buttons">
      <button
        *ngIf="!removing"
        mat-flat-button
        class="filterButton"
        color="primary"
        (click)="addNewUserToDepartment()"
        i18n
      >
        <span class="material-symbols-rounded iconButton">
          person_add_alt
        </span>
        Add new user to department
      </button>
      <button
        *ngIf="!removing"
        mat-flat-button
        class="filterButton"
        color="primary"
        (click)="showCheckBox()"
        i18n
      >
        <span class="material-symbols-rounded iconButton"> person_remove </span>
        Remove user from department
      </button>

      <button
        *ngIf="removing"
        mat-flat-button
        class="filterButton"
        color="primary"
        (click)="deleteSelectedUsers()"
        i18n
      >
        <span class="material-symbols-rounded iconButton"> remove </span>
        Remove selected users
      </button>
      <button
        *ngIf="removing"
        mat-flat-button
        class="filterButton"
        color="error"
        (click)="cancelRemoveUserFromDepartment()"
        i18n
      >
        <span class="material-symbols-rounded iconButton"> clear </span>
        Cancel
      </button>
    </div>
    <div class="table-container">
      <table
        id="departmentUserTable"
        mat-table
        [dataSource]="dataSource"
        matSort
        matSortActive="start"
        matSortDirection="desc"
      >
        <ng-container class="select" matColumnDef="select">
          <th mat-header-cell *matHeaderCellDef>
            <mat-checkbox
              (change)="$event ? toggleAllRows() : null"
              [checked]="selection.hasValue() && isAllSelected()"
              [indeterminate]="selection.hasValue() && !isAllSelected()"
              [aria-label]="checkboxLabel()"
            >
            </mat-checkbox>
          </th>
          <td mat-cell *matCellDef="let row">
            <mat-checkbox
              class="checkBox"
              (click)="$event.stopPropagation()"
              (change)="$event ? selection.toggle(row) : null"
              [checked]="selection.isSelected(row)"
              [aria-label]="checkboxLabel(row)"
            >
            </mat-checkbox>
          </td>
        </ng-container>
        <ng-container matColumnDef="username">
          <th mat-header-cell *matHeaderCellDef mat-sort-header i18n>
            Username
          </th>
          <td mat-cell *matCellDef="let element">{{ element.username }}</td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      </table>
    </div>
    <div class="paginatorDiv">
      <mat-paginator
        class="paginator"
        [pageSizeOptions]="[6, 10, 20]"
        [pageIndex]=""
        showFirstLastButtons
        aria-label="Select page of department"
      >
      </mat-paginator>
    </div>
  </div>
</body>
