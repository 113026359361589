<div class="container">
  <div (keyup.enter)="confirmDeleteUsers()" class="content">
    <Label class="infoDiv" i18n
      >Are you sure that you want to delete the selected user(s)?
    </Label>
  </div>
  <div class="userListDiv">
    <ul>
      <li *ngFor="let user of userNames">
        <Label class="tokenLabel">{{ user }}</Label>
      </li>
    </ul>
  </div>
  <div class="buttonDiv">
    <button
      class="confirmButton"
      mat-button
      (click)="confirmDeleteUsers()"
      i18n
    >
      Confirm
    </button>
    <button class="confirmButton" mat-button (click)="closeDialog()" i18n>
      Back
    </button>
  </div>
</div>
