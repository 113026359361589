<body>
  <div class="content">
    <br />
    <div class="container">
      <div id="personalDataContentId" class="header">
        <div class="headerContent">
          <div class="mat-icon-person">
            <img
              class="userImage"
              src="https://wallbox-web-dev.s3.eu-central-1.amazonaws.com/userProfilePictures/default/defaultUserProfileImage.png"
              alt="profilePicture"
            />
          </div>
          <div class="userNameAndDomainDiv">
            <mat-form-field class="name whiteTextInputForm">
              <mat-label i18n class="whiteTextInputForm">NAME</mat-label>
              <input
                [disabled]="!edit"
                matInput
                class="aliasMatInput valueInputs textPersonalData"
                id="surnameInput"
                type="text"
                [value]="name"
                #nameInput
              />
            </mat-form-field>

            <mat-form-field class="domain whiteTextInputForm">
              <mat-label i18n class="whiteTextInputForm">SURNAME</mat-label>
              <input
                [disabled]="!edit"
                matInput
                class="valueInputs textPersonalData"
                id="surnameInput"
                type="text"
                [value]="surname"
                #surnameInput
              />
            </mat-form-field>
          </div>

          <div class="buttons">
            <button
              *ngIf="!edit"
              mat-raised-button
              class="editUserButton"
              (click)="editProfile()"
              color="primary"
              i18n
            >
              Edit profile
            </button>
            <button
              mat-raised-button
              class="editUserButton"
              *ngIf="edit"
              (click)="
                updateValues(
                  nameInput.value,
                  surnameInput.value,
                  languageInput.value
                )
              "
              color="primary"
              i18n
            >
              Save changes
            </button>
            <button
              mat-raised-button
              class="editUserButton"
              *ngIf="edit"
              (click)="cancel()"
              color="primary"
              i18n
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
      <div class="infoContent">
        <div class="personalInformation">
          <table>
            <tr class="tableRow">
              <td class="tableData">
                <mat-form-field class="whiteTextInputForm">
                  <mat-label i18n class="whiteTextInputForm">Email</mat-label>
                  <input
                    [disabled]="true"
                    matInput
                    class="valueInputs textPersonalData"
                    type="text"
                    [value]="email"
                    #emailInput
                  />
                </mat-form-field>
              </td>
            </tr>
            <tr class="tableRow">
              <td class="tableData">
                <mat-form-field class="whiteTextInputForm">
                  <mat-label class="whiteTextInputForm" i18n>Type</mat-label>
                  <input
                    [disabled]="true"
                    matInput
                    type="text"
                    [value]="userType"
                    class="valueInputs textPersonalData"
                  />
                </mat-form-field>
              </td>
            </tr>
            <tr class="tableRow">
              <td class="tableData">
                <mat-form-field class="whiteTextInputForm">
                  <mat-label class="whiteTextInputForm" i18n
                    >Language</mat-label
                  >

                  <mat-select
                    [disabled]="!edit"
                    matNativeControl
                    required
                    #languageInput
                    [(ngModel)]="SeletedLanguageInDB"
                    class="valueInputs textPersonalData"
                  >
                    <mat-option value="Spanish" i18n>Spanish</mat-option>
                    <mat-option value="English" i18n>English</mat-option>
                  </mat-select>
                </mat-form-field>
              </td>
            </tr>
            <tr class="tableRow">
              <td class="tableData">
                <mat-form-field class="whiteTextInputForm">
                  <mat-label class="whiteTextInputForm" i18n
                    >Creation date</mat-label
                  >
                  <input
                    [disabled]="true"
                    matInput
                    type="text"
                    [value]="creationDate"
                    class="valueInputs textPersonalData"
                  />
                </mat-form-field>
              </td>
            </tr>
            <tr class="tableRow">
              <td class="tableData">
                <mat-form-field class="whiteTextInputForm">
                  <mat-label class="whiteTextInputForm" i18n
                    >Modification date</mat-label
                  >
                  <input
                    [disabled]="true"
                    matInput
                    type="text"
                    value="Not defined"
                    i18n-value="Not defined value"
                    class="valueInputs textPersonalData"
                  />
                </mat-form-field>
              </td>
            </tr>
          </table>
        </div>
        <mat-divider [vertical]="true" class="verticalDivider"></mat-divider>

        <div class="workInformation">
          <table class="departments">
            <tr class="workInformationTitleTableRow">
              <td class="workInformationTitle" i18n>Work information</td>
            </tr>
            <tr>
              <td
                *ngIf="
                  departments.length == 0 &&
                  changePersonalData.departments.length == 0
                "
                i18n
              >
                There is no work information available.
              </td>
            </tr>

            <tr>
              <td
                class="departmentTitle"
                *ngIf="
                  departments.length > 0 ||
                  changePersonalData.departments.length > 0
                "
                i18n
              >
                Departments:
              </td>
              <td class="departmentContent" id="departmentContent">
                <div class="departmentInfo">
                  <div
                    *ngFor="let dep of departments"
                    class="valueInputs"
                    id="valueInputs"
                  >
                    <div class="departmentsInfo" id="departmentsInfo">
                      <div class="infoRow">
                        <div class="departmentName">
                          <b>{{ dep.departmentName }}:</b>
                        </div>
                        <div class="departmentDescription">
                          {{ dep.departmentDescription }}
                        </div>
                      </div>

                      <button
                        *ngIf="edit"
                        class="deleteButtonSmallScreen"
                        (click)="deleteDepart(dep, '')"
                        mat-button
                        i18n
                      >
                        <mat-icon>delete</mat-icon>
                      </button>
                    </div>
                    <button
                      *ngIf="edit"
                      class="deleteButtonBigScreen"
                      (click)="deleteDepart(dep, '')"
                      mat-button
                      i18n
                    >
                      <mat-icon>delete</mat-icon>
                    </button>
                  </div>

                  <div
                    class="valueInputs"
                    *ngFor="let dep of changePersonalData.departments"
                  >
                    <div class="departmentsInfo">
                      <div class="infoRow">
                        <div class="departmentName">
                          <b>{{ dep.departmentName }}:</b>
                        </div>
                        <div class="departmentDescription">
                          {{ dep.departmentDescription }}
                        </div>
                      </div>
                      <button
                        *ngIf="edit"
                        class="deleteButtonSmallScreen"
                        (click)="deleteDepart(dep, 'newDepart')"
                        mat-button
                        i18n
                      >
                        <mat-icon>delete</mat-icon>
                      </button>
                    </div>
                    <button
                      *ngIf="edit"
                      class="deleteButtonBigScreen"
                      (click)="deleteDepart(dep, 'newDepart')"
                      mat-button
                      i18n
                    >
                      <mat-icon>delete</mat-icon>
                    </button>
                  </div>
                </div>
              </td>
              <div *ngIf="false" class="addDepart">
                <mat-form-field class="whiteTextInputForm"
                  ><input
                    matInput
                    type="text"
                    id="newDepartInput"
                    class="newDepartInput"
                    required
                    [formControl]="form"
                    [matAutocomplete]="auto"
                    #newDepart
                  /><mat-autocomplete
                    autoActiveFirstOption
                    #auto="matAutocomplete"
                  >
                    <mat-option
                      *ngFor="let option of departmentsOption | async"
                      [value]="option"
                    >
                      {{ option }}
                    </mat-option>
                    <mat-option
                      *ngIf="avaliableDepartmentsName.length == 0"
                      [disabled]="true"
                      i18n
                    >
                      No more departments avaliable
                    </mat-option>
                  </mat-autocomplete></mat-form-field
                >

                <button
                  class="newDepartButton"
                  [disabled]="
                    newDepart.value == '' ||
                    !checkDepartmentAvailable(newDepart.value)
                  "
                  color="primary"
                  (click)="addDepart(newDepart.value)"
                  mat-raised-button
                  i18n
                >
                  <mat-icon>add</mat-icon>
                </button>
              </div>
            </tr>
          </table>
        </div>
      </div>
      <div class="closeDialog">
        <button color="primary" (click)="closeDialog()" mat-raised-button i18n>
          Close
        </button>
      </div>
    </div>
  </div>
</body>
