import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { FormControl, Validators } from '@angular/forms';
import { UserSessionCheckerService } from '../../../../../Services/UserManagement/UserSessionChecker/user-session-checker.service';
import '@angular/localize/init';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.css'],
})
export class ChangePasswordComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<ChangePasswordComponent>,
    public userSessionCheckerService: UserSessionCheckerService
  ) {}

  public nPassword = new FormControl('', [
    Validators.required,
    Validators.minLength(8),
  ]);
  public oPassword = new FormControl('', [
    Validators.required,
    Validators.minLength(8),
  ]);
  public rPassword = new FormControl('', [Validators.required]);
  public token = '';

  public cmpNewPassword() {
    if (this.nPassword.value!.length < 8) {
      return 0;
    } else if (
      this.nPassword.value!.replace(/\w/g, '').length ==
      this.nPassword.value!.length
    ) {
      return 0;
    } else if (
      this.nPassword.value!.replace(/\d/g, '').length ==
      this.nPassword.value!.length
    ) {
      return 0;
    } else if (
      this.nPassword.value!.replace(/\W||\D/g, '').length ==
      this.nPassword.value!.length
    ) {
      return 0;
    } else if (
      this.nPassword.value!.replace(/[A-Z]/g, '').length ==
      this.nPassword.value!.length
    ) {
      return 0;
    }
    return 1;
  }
  private checkUserSession(): boolean {
    this.userSessionCheckerService.checkUserSession();
    return this.userSessionCheckerService.check;
  }
  public cancel() {
    this.dialogRef.close();
  }
  public confirm() {
    if (this.checkUserSession()) {
      this.dialogRef.close({
        oldPass: this.oPassword.value,
        newPass: this.nPassword.value,
      });
    } else {
      this.dialogRef.close();
    }
  }

  public getNewPasswordError() {
    if (this.nPassword.invalid) {
      return $localize`The password is too short`;
    } else if (
      this.nPassword.value!.replace(/\w/g, '').length ==
      this.nPassword.value!.length
    ) {
      return $localize`The password must contain letters`;
    } else if (
      this.nPassword.value!.replace(/\d/g, '').length ==
      this.nPassword.value!.length
    ) {
      return $localize`The password must contain numbers`;
    } else if (
      this.nPassword.value!.replace(/[A-Z]/g, '').length ==
      this.nPassword.value!.length
    ) {
      return $localize`The password must contain capital letters`;
    } else {
      return $localize`The password must contain special characters`;
    }
  }

  public ngOnInit(): void {
    this.token = window.localStorage.getItem('Authorization')!;

    this.userSessionCheckerService.checkUserSession();
  }

  public repeatPasswordError() {
    if (
      this.rPassword.value != this.nPassword.value &&
      this.rPassword.value != ''
    ) {
      return $localize`The password must be the same`;
    }
    return '';
  }
}
