import { MatPaginatorIntl } from '@angular/material/paginator';
import { Directive } from '@angular/core';

@Directive({ selector: 'mat-paginator' })
export class CustomMatPaginatorIntl extends MatPaginatorIntl {
  constructor() {
    super();

    this.getAndInitTranslations();
  }

  override getRangeLabel = (page: number, pageSize: number, length: number) => {
    if (length === 0 || pageSize === 0) {
      return `0 / ${length}`;
    }
    length = Math.max(length, 0);
    const startIndex = page * pageSize;
    const endIndex =
      startIndex < length
        ? Math.min(startIndex + pageSize, length)
        : startIndex + pageSize;
    return `${startIndex + 1} - ${endIndex} / ${this.totalItems}`; // TODO: customize as needed
  };
  public totalItems: number;

  public getAndInitTranslations() {
    this.itemsPerPageLabel = $localize`Items per page`;
    this.nextPageLabel = $localize`Next`;
    this.previousPageLabel = $localize`Previous`;
    this.lastPageLabel = $localize`Last page`;
    this.firstPageLabel = $localize`First page`;
    this.changes.next();
  }

  public setTotalItems(value: number) {
    this.totalItems = value;
  }
}
