import { Component, OnInit } from '@angular/core';
import { UserManagementAPIService } from 'src/app/Services/UserManagement/user-management-api.service';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MfaConfirmationDialogComponent } from './mfa-confirmation-dialog/mfa-confirmation-dialog.component';
import { SecretCodeDialogComponent } from './secret-code-dialog/secret-code-dialog.component';
import { UserSessionCheckerService } from 'src/app/Services/UserManagement/UserSessionChecker/user-session-checker.service';

@Component({
  selector: 'app-mfa-activation-dialog',
  templateUrl: './mfa-activation-dialog.component.html',
  styleUrls: ['./mfa-activation-dialog.component.css'],
})
export class MfaActivationDialogComponent implements OnInit {
  constructor(
    public userManagementAPIService: UserManagementAPIService,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<SecretCodeDialogComponent>,
    private userSessionCheckerService: UserSessionCheckerService
  ) {}

  dataString: string;
  secretCode: string;

  ngOnInit(): void {
    this.userSessionCheckerService.checkUserSession();

    var accessToken = window.localStorage.getItem('AccessToken')!;

    this.userManagementAPIService
      .associateSoftwareToken(accessToken)
      .subscribe({
        next: (data: string) => {
          var json = JSON.stringify(data);
          var jsonParsed = JSON.parse(json);

          this.secretCode = jsonParsed.SecretCode;

          this.dataString =
            'otpauth://totp/SkylifeMentor2FA?secret=' +
            this.secretCode +
            '&issuer=Skylife' +
            '&image=http://google.com/image/logo.png';
        },
        error: (error) => {
          console.error(error);
        },
      });
  }

  public confirmMFACode() {
    this.dialog.closeAll();

    this.dialog.open(MfaConfirmationDialogComponent, {
      height: '17em',
      width: '25em',
    });
  }

  public showSecretCode() {
    this.dialog.closeAll();

    var secretCodeDialog = this.dialog.open(SecretCodeDialogComponent, {
      height: '12em',
      width: '45em',
    });

    secretCodeDialog.componentInstance.secretCode = this.secretCode;
  }

  public closeDialog() {
    this.dialog.closeAll();
  }
}
