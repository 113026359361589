import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-type-errors-add-user-email',
  templateUrl: './type-errors-add-user-email.component.html',
  styleUrls: ['./type-errors-add-user-email.component.css'],
})
export class TypeErrorsAddUserEmailComponent implements OnInit {
  constructor(public dialog: MatDialogRef<TypeErrorsAddUserEmailComponent>) {}

  public errorType: string;
  public userLimit: number;
  public numberOfUsers: number;

  public closeDialog() {
    this.dialog.close();
  }

  ngOnInit(): void {}
}
