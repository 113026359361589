<div (keyup.enter)="confirm()" class="content">
  <Label *ngIf="!revoked" class="tokenLabel" i18n
    >The session has expired</Label
  >
  <Label *ngIf="revoked" class="tokenLabel" i18n
    >This session has been closed because this user has logged in on another
    device. You will be redirected to the login page</Label
  >
  <button class="confirmButton" mat-button (click)="confirm()" i18n>
    Redirect to login
  </button>
</div>
