<div (keyup.enter)="closeDialog()" class="content">
  <label class="tokenLabel" *ngIf="confirmation" i18n
    >Password has been changed successfully
  </label>
  <label class="tokenLabel" *ngIf="!confirmation" i18n
    >Password could not be changed, please try again
  </label>
</div>
<div>
  <button class="confirmButton" mat-button (click)="closeDialog()" i18n>
    Back
  </button>
</div>
