<body>
  <div id="container" class="container">
    <div class="normalAddUser">
      <mat-form-field
        class="example-full-width whiteTextInputForm"
        appearance="fill"
      >
        <mat-label i18n>Email</mat-label>
        <input
          matInput
          placeholder="example@domain.com"
          i18n-placeholder="example@domain.com placeholder"
          [formControl]="email"
          required
          #emailInput
        />
        <mat-error *ngIf="this.checkEmail(email.value)" i18n
          >Please, enter a valid email</mat-error
        >
      </mat-form-field>
      <mat-form-field class="whiteTextInputForm" appearance="fill">
        <mat-label i18n>User Type</mat-label>
        <mat-select
          matNativeControl
          required
          #userType
          [formControl]="userTypes"
        >
          <mat-option value="NONE" i18n>None</mat-option>
          <mat-option value="ADMIN" i18n>Admin</mat-option>
          <mat-option value="USER_N1" i18n>Operator</mat-option>
          <mat-option value="USER_N2" i18n>Expert</mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field class="whiteTextInputForm" appearance="fill">
        <mat-label i18n>User Language</mat-label>
        <mat-select
          matNativeControl
          required
          #userLanguage
          [formControl]="userLanguages"
          [(ngModel)]="selected"
        >
          <mat-option value="Spanish" i18n>Spanish</mat-option>
          <mat-option value="English" i18n>English</mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field
        class="example-full-width whiteTextInputForm"
        appearance="fill"
      >
        <mat-label i18n>Name</mat-label>
        <input
          matInput
          placeholder="name"
          i18n-placeholder="name placeholder"
          [formControl]="name"
          #nameInput
        />
      </mat-form-field>
      <mat-form-field
        class="example-full-width whiteTextInputForm"
        appearance="fill"
      >
        <mat-label i18n>Surname</mat-label>
        <input
          matInput
          placeholder="surname"
          i18n-placeholder="surname placeholder"
          [formControl]="surname"
          #surnameInput
        />
      </mat-form-field>
      <button
        mat-button
        title="Licensing"
        [matMenuTriggerFor]="menu"
        class="licensingInf"
      >
        <mat-icon>info</mat-icon> <label i18n>Licensing information</label>
        <mat-menu #menu="matMenu">
          <div class="fullContainer">
            <div class="titleLicensing">
              <h1 i18n>Licenses</h1>
            </div>
            <div class="contentMenu">
              <div>
                <h3 i18n>Admin</h3>
                <ul>
                  <li i18n>Total licences: {{ this.adminLicenses }}</li>
                  <li i18n>Used licences: {{ this.currentAdminLicenses }}</li>
                  <li i18n>
                    Remaining licences:
                    {{ this.adminLicenses - this.currentAdminLicenses }}
                  </li>
                </ul>
                <h3 i18n>Expert</h3>
                <ul>
                  <li i18n>Total licences: {{ this.expertLicenses }}</li>
                  <li i18n>Used licences: {{ this.currentExpertLicenses }}</li>
                  <li i18n>
                    Remaining licences:
                    {{ this.expertLicenses - this.currentExpertLicenses }}
                  </li>
                </ul>
                <h3 i18n>Operator</h3>
                <ul>
                  <li i18n>Total licences: {{ this.operatorLicenses }}</li>
                  <li i18n>
                    Used licences: {{ this.currentOperatorLicenses }}
                  </li>
                  <li i18n>
                    Remaining licences:
                    {{ this.operatorLicenses - this.currentOperatorLicenses }}
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </mat-menu>
      </button>
      <div class="confirmatioButtons">
        <button
          class="cancelButton"
          [disabled]="
            !this.checkEmail(email.value) ||
            userType.value == '' ||
            name.value == '' ||
            surname.value == ''
          "
          *ngIf="!this.spinner"
          mat-button
          (click)="
            onAddNewUser(
              emailInput.value,
              userType.value,
              name.value,
              surname.value,
              userLanguage.value
            )
          "
          i18n
        >
          Add user
        </button>

        <div *ngIf="this.spinner" class="loader"></div>
        <button
          *ngIf="!this.spinner"
          mat-button
          class="cancelButton"
          (click)="cancelAddNewUser()"
          i18n
        >
          Cancel
        </button>
      </div>
    </div>

    <div class="contentMenu">
      <div class="advancedAddNewUser">
        <div class="departmentSubsection">
          <table class="departmentsTable">
            <tr class="departmentsTableRow">
              <label i18n>DEPARTMENTS:</label>
              <br />
              <td class="departmentContentAddNewUser">
                <div class="addDepart">
                  <div class="addDepartInput">
                    <mat-form-field class="whiteTextInputForm"
                      ><input
                        matInput
                        type="text"
                        (onfocus)="noMoreDepartments()"
                        id="newDepartInput"
                        placeholder="Department name"
                        i18n-placeholder="Department name placeholder"
                        [formControl]="form"
                        [matAutocomplete]="auto"
                        #newDepart
                      /><mat-autocomplete
                        autoActiveFirstOption
                        #auto="matAutocomplete"
                      >
                        <mat-option
                          *ngFor="let option of departmentsOption | async"
                          [value]="option"
                        >
                          {{ option }}
                        </mat-option>
                      </mat-autocomplete></mat-form-field
                    >
                    <div class="example-button-container">
                      <button
                        mat-mini-fab
                        [disabled]="
                          newDepart.value == '' ||
                          !checkDepartmentAvailable(newDepart.value)
                        "
                        color="primary"
                        (click)="addDepart(newDepart.value)"
                        (click)="updateScroll()"
                        mat-raised-button
                        i18n
                      >
                        <mat-icon>add</mat-icon>
                      </button>
                    </div>
                  </div>

                  <div class="departmentList" id="departmentList">
                    <div
                      *ngFor="let dep of departmentsAdded"
                      class="departmentValues"
                      id="departmentValues"
                    >
                      <div class="departmentsInfoAddNewUser">
                        <div class="departmentName">
                          <b>{{ dep.departmentName }}:</b>
                        </div>
                        <div class="departmentDescription">
                          {{ dep.departmentDescription }}
                        </div>
                      </div>
                      <button
                        class="deleteButtonBigScreen"
                        (click)="deleteDepart(dep)"
                        mat-button
                        i18n
                      >
                        <mat-icon>delete</mat-icon>
                      </button>
                    </div>
                  </div>
                </div>
              </td>
            </tr>
          </table>
        </div>
      </div>
    </div>
  </div>
</body>
