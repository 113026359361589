import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-user-no-exist-dialog',
  templateUrl: './user-no-exist-dialog.component.html',
  styleUrls: ['./user-no-exist-dialog.component.css'],
})
export class UserNoExistDialogComponent implements OnInit {
  constructor(public dialog: MatDialogRef<UserNoExistDialogComponent>) {}

  public confirm() {
    this.dialog.close();
  }

  ngOnInit(): void {}
}
