<div class="container">
  <div class="header">
    <img
      src="assets/img/skleLogos/Skylife_Infinity_White_1024.png"
      alt="logo"
      class="logo"
    />
  </div>
  <div class="content">
    <div class="textDiv">
      <h1>Error</h1>
      <h1>404</h1>
      <h3 i18n>Page Not Found</h3>

      <p i18n>Make sure the address is correct and the page hasn't moved.</p>
      <p i18n>
        Please contact your administrator if you think this is a mistake.
      </p>
    </div>
    <div class="planeAnimationDiv">
      <div class="planeDiv">
        <mat-icon class="plane" id="plane">flight</mat-icon>
        <mat-icon class="plane" id="plane_off">airplanemode_inactive</mat-icon>
      </div>
    </div>
    <div class="footer">
      <h2>
        <a *ngIf="token != null" routerLink="/dashboard/home" i18n>Home ·</a>
        <a *ngIf="token != null" routerLink="/login" (click)="signOut()" i18n>
          Sign out ·</a
        >

        <a *ngIf="token != null" routerLink="/dashboard/user-help" i18n>Help</a>
        <a *ngIf="token == null" routerLink="/login" i18n>Go back to login</a>
      </h2>
    </div>
  </div>
</div>
