<div
  (keyup.enter)="
    cmpDates(sDate.value, eDate.value) && !sDate.invalid && !eDate.invalid
      ? confirm(startDate.value, endDate.value)
      : ''
  "
  class="container"
>
  <mat-form-field class="whiteTextInputForm">
    <input
      matInput
      class="filterByStartDateInput"
      type="datetime-local"
      [formControl]="sDate"
      placeholder="start date"
      i18n-placeholder="start date placeholder"
      required
      #startDate
    />
    <mat-error *ngIf="sDate.invalid" i18n
      >You must enter a valid start date</mat-error
    >
  </mat-form-field>
  <mat-form-field class="whiteTextInputForm">
    <input
      matInput
      class="filterByEndDateInput"
      type="datetime-local"
      [formControl]="eDate"
      placeholder="end date"
      i18n-placeholder="end date placeholder"
      required
      #endDate
    />
    <mat-error *ngIf="eDate.invalid" i18n
      >You must enter a valid end date</mat-error
    >
  </mat-form-field>

  <label
    class="error"
    *ngIf="!cmpDates(sDate.value, eDate.value) && !eDate.invalid"
    i18n
    >You cannot enter a start date that is later than the end date</label
  >

  <div>
    <button
      [disabled]="
        !cmpDates(sDate.value, eDate.value) || sDate.invalid || eDate.invalid
      "
      mat-raised-button
      color="primary"
      (click)="confirm(startDate.value, endDate.value)"
      i18n
    >
      Filter
    </button>
    <button
      mat-raised-button
      class="cancelButton"
      color="primary"
      (click)="cancelDateFilter()"
      i18n
    >
      Cancel
    </button>
  </div>
</div>
