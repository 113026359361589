<div (keyup.enter)="closeDialog()" class="content">
  <label class="tokenLabel" i18n
    >The security code is not correct, please try again
  </label>
</div>
<div>
  <button class="confirmButton" mat-button (click)="closeDialog()" i18n>
    Back
  </button>
</div>
