<div class="container">
  <div class="content">
    <div class="info" i18n>Insert the new department information</div>
    <div class="fields">
      <mat-form-field>
        <mat-label i18n>Department name</mat-label>
        <input matInput #departmentName />
      </mat-form-field>
      <mat-form-field hintLabel="Max 200 characters">
        <mat-label i18n>Department description</mat-label>
        <textarea
          #departmentDescription
          matInput
          maxlength="200"
          rows="8"
        ></textarea>
        <mat-hint align="end"
          >{{ departmentDescription.value?.length || 0 }}/200</mat-hint
        >
      </mat-form-field>
    </div>
    <div class="buttonDiv">
      <button
        mat-raised-button
        color="primary"
        class="confirmButton"
        (click)="
          addDepartment(departmentName.value, departmentDescription.value)
        "
        i18n
      >
        <mat-icon>add</mat-icon> <label> Add </label>
      </button>
      <button
        mat-raised-button
        color="error"
        class="cancelButton"
        (click)="closeDialog()"
        i18n
      >
        <mat-icon>clear</mat-icon> <label> Cancel </label>
      </button>
    </div>
  </div>
</div>
