import { MatDialogRef } from '@angular/material/dialog';
import { Component, OnInit } from '@angular/core';

//@Inject(MAT_DIALOG_DATA) public data: any,

@Component({
  selector: 'app-delete-session-confirmation',
  templateUrl: './delete-session-confirmation.component.html',
  styleUrls: ['./delete-session-confirmation.component.css'],
})
export class DeleteSessionConfirmationComponent implements OnInit {
  constructor(
    public dialog: MatDialogRef<DeleteSessionConfirmationComponent>
  ) {}

  public sessionsNum: number;

  public confirmDeleteSessions(res: boolean) {
    this.dialog.close({ deleteConfirm: res });
  }

  public ngOnInit(): void {}
}
