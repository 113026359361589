import { MatDialogRef } from '@angular/material/dialog';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'confirmation-add-new-user-dialog',
  templateUrl: './confirmation-add-new-user.component.html',
  styleUrls: ['./confirmation-add-new-user.component.css'],
})
export class ConfirmationAddNewUser implements OnInit {
  constructor(public dialog: MatDialogRef<ConfirmationAddNewUser>) {}

  public addUserSuccess: boolean;

  public closeDialog() {
    this.dialog.close();
  }

  public closeDialogAndReload() {
    this.dialog.close();
    window.location.reload();
  }

  ngOnInit(): void {}
}
