import { Component, OnInit } from '@angular/core';
import { UserSessionCheckerService } from 'src/app/Services/UserManagement/UserSessionChecker/user-session-checker.service';
import { UserDataApiService } from 'src/app/Services/UserDataService/user-data-api.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-personal-data',
  templateUrl: './personal-data.component.html',
  styleUrls: ['./personal-data.component.css'],
})
export class PersonalDataComponent implements OnInit {
  constructor(
    public userDataApiService: UserDataApiService,
    public userSessionCheckerService: UserSessionCheckerService
  ) {}

  public creationDate = '';
  public departments = [];
  public edit = false;
  public email = '';
  public name = '';
  public surname = '';
  public language = '';

  public userType =
    window.localStorage.getItem('userType') == 'NONE'
      ? $localize`None`
      : window.localStorage.getItem('userType') == 'ADMIN'
      ? $localize`Admin`
      : window.localStorage.getItem('userType') == 'USER_N1'
      ? $localize`Operator`
      : window.localStorage.getItem('userType') == 'USER_N2'
      ? $localize`Expert`
      : $localize`User type not defined`;

  public cancel() {
    this.edit = false;
  }

  public editProfile() {
    this.edit = true;
  }

  public getData() {
    this.userDataApiService.getCurrentUserData().subscribe({
      next: (data) => {
        var json = JSON.stringify(data);
        var jsonParsed = JSON.parse(json);

        this.name =
          jsonParsed.name == 'None' ? $localize`Not defined` : jsonParsed.name;

        this.email = localStorage.getItem('SessionUsername');
        this.language = localStorage.getItem('UserLanguage');

        this.surname =
          jsonParsed.surname == 'None'
            ? $localize`Not defined`
            : jsonParsed.surname;

        this.creationDate =
          jsonParsed.creationDate == 'None'
            ? $localize`Not defined`
            : jsonParsed.creationDate;

        /*
        var arrayParsed = JSON.parse(jsonParsed.departments);

        arrayParsed.forEach((department: string) => {
          this.departments.push(department);
        });

        */
      },
      error: (error) => {
        console.error('There was an error!', error);
      },
    });
  }

  public ngOnInit(): void {
    this.userSessionCheckerService.checkUserSession();
    this.getData();
  }

  public updateValues(name: string, surname: string, language: string) {
    this.userDataApiService
      .updateCurrentUserData(this.email, name, surname, [], [], language)
      .subscribe({
        next: () => {
          var url = window.location.href;
          var urlSplitted = url.split('/');
          var urlLanguage = urlSplitted[3];

          //in development es or en is not visible in the url, so we need to check if the url has it, in the case it has it, we redirect to the correct url
          //in the case it doesn't have it, we redirect to the default language and doesn't crash into the 404 page

          if (
            (urlLanguage == 'es' || urlLanguage == 'en') &&
            language != localStorage.getItem('UserLanguage')
          ) {
            if (language == 'English') {
              window.location.href =
                environment.WebLanguages.English + '/dashboard/home';
            }
            if (language == 'Spanish') {
              window.location.href =
                environment.WebLanguages.Spanish + '/dashboard/home';
            }
          } else {
            location.reload();
          }
        },
        error: (error) => {
          console.error('There was an error!', error);
        },
      });
  }
}
