import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-user-type-not-allowed-dialog',
  templateUrl: './user-type-not-allowed-dialog.component.html',
  styleUrls: ['./user-type-not-allowed-dialog.component.css'],
})
export class UserTypeNotAllowedDialogComponent implements OnInit {
  constructor(
    private dialog: MatDialogRef<UserTypeNotAllowedDialogComponent>
  ) {}

  public close() {
    this.dialog.close();
  }

  ngOnInit(): void {}
}
