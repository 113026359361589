import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
@Component({
  selector: 'app-confirmation-activate-user',
  templateUrl: './confirmation-activate-user.component.html',
  styleUrls: ['./confirmation-activate-user.component.css'],
})
export class ConfirmationActivateUserComponent implements OnInit {
  constructor(private dialog: MatDialog) {}
  public activateUserSuccess: boolean;
  public userTypeFull = false;
  public backToUserList() {
    this.dialog.closeAll();
  }
  ngOnInit(): void {}
}
