import { Component, OnInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import { Observable } from 'rxjs';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { DepartmentApiService } from 'src/app/Services/DepartmentManagement/department-api.service';
import { MatDialog } from '@angular/material/dialog';
import { RemoveDepartmentsConfirmationComponent } from './remove-departments-confirmation/remove-departments-confirmation.component';
import { NewDepartmentComponent } from './new-department/new-department.component';
import { Router } from '@angular/router';
import { NewUserToDepartmentDialogComponent } from './department-users/new-user-to-department-dialog/new-user-to-department-dialog.component';
import { RemoveUserFromDepartmentDialogComponent } from './department-users/remove-user-from-department-dialog/remove-user-from-department-dialog.component';

@Component({
  selector: 'app-department-management',
  templateUrl: './department-management.component.html',
  styleUrls: ['./department-management.component.css'],
})
export class DepartmentManagementComponent implements OnInit {
  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    public departmentApiService: DepartmentApiService,
    public dialog: MatDialog,
    private router: Router
  ) {}

  @ViewChild(MatPaginator) public paginator: MatPaginator;

  public allSelected: boolean;
  public cards: Observable<Array<Card>>;
  public dataSource: MatTableDataSource<Card>;
  public departmentsCardList: Card[] = [];
  public removeMode: boolean;
  public userNamesList = new Array<string>();

  public ngOnInit(): void {
    this.departmentApiService.getDepartments().subscribe({
      next: (data) => {
        var json = JSON.stringify(data);
        var jsonParsed = JSON.parse(json);

        for (var key of Object.keys(jsonParsed)) {
          var card = <Card>{};

          card.iddepartment = key;
          card.title = jsonParsed[key].departmentName;
          card.subtitle = jsonParsed[key].departmentName;
          card.text = jsonParsed[key].departmentDescription;
          card.selected = false;
          this.allSelected = false;

          this.departmentsCardList.push(card);
        }

        this.dataSource = new MatTableDataSource<Card>(
          this.departmentsCardList
        );

        this.changeDetectorRef.detectChanges();
        this.dataSource.paginator = this.paginator;
        this.cards = this.dataSource.connect();

        console.log(this.departmentsCardList);
      },
      error: (err) => {
        console.log(err);
      },
    });
  }

  private getUsersFromDepartment(idDepartment: string) {
    this.departmentApiService
      .getUserListFromDepartment(idDepartment)
      .subscribe({
        next: (data) => {
          var json = JSON.stringify(data);
          var jsonParsed = JSON.parse(json);

          console.log(jsonParsed.userlist);

          jsonParsed.userlist.forEach((userData) => {
            var userName = '';

            userName = userData.username;
            this.userNamesList.push(userName);
          });
        },
        error: () => {},
      });
  }
  public addNewUserToDepartment(idDepartment: string) {
    this.getUsersFromDepartment(idDepartment);
    var refDialog = this.dialog.open(NewUserToDepartmentDialogComponent, {
      height: '20em',
      width: '30em',
    });

    refDialog.componentInstance.idDepartment = idDepartment;
    refDialog.componentInstance.departmentUsers = this.userNamesList;

    refDialog.afterClosed().subscribe((res) => {
      this.userNamesList = [];
    });
  }

  public openDepartmentUserList(departmentId: string) {
    this.departmentApiService.updateDepartmentId(departmentId);

    this.router.navigate([
      'dashboard/department-user-management/' + departmentId,
    ]);
  }

  public addDepartment() {
    this.dialog.open(NewDepartmentComponent, {
      width: '25em',
      height: '30em',
    });
  }

  public removeUsersFromDepartments(iddepartment: string) {
    this.getUsersFromDepartment(iddepartment);
    var refDialog = this.dialog.open(RemoveUserFromDepartmentDialogComponent, {
      height: '20em',
      width: '30em',
    });
    console.log(this.userNamesList);
    refDialog.componentInstance.idDepartment = iddepartment;
    refDialog.componentInstance.departmentUsers = this.userNamesList;

    refDialog.afterClosed().subscribe((res) => {
      this.userNamesList = [];
    });
  }

  public removeSelected() {
    var selectedDepartments = this.departmentsCardList.filter(
      (card) => card.selected
    );

    if (selectedDepartments.length === 0) {
      var removalConfirmation = this.dialog.open(
        RemoveDepartmentsConfirmationComponent,
        {
          width: '25em',
          height: '13em',
        }
      );

      removalConfirmation.componentInstance.emptySelectedDepartments = true;
    } else {
      var removalConfirmation = this.dialog.open(
        RemoveDepartmentsConfirmationComponent,
        {
          width: '30em',
          height: '30em',
        }
      );

      removalConfirmation.componentInstance.selectedDepartments =
        selectedDepartments;
    }
  }

  checkSelectedStatus(cards: Observable<Card[]>) {
    cards.subscribe((cardArray) => {
      cardArray.forEach((card) => {
        if (card.selected == true) {
          document
            .getElementById(card.iddepartment)
            .classList.add('matCardSelected');
        }
      });
    });
  }

  public setAll(selected: boolean) {
    this.allSelected = selected;
    if (this.departmentsCardList == null) {
      return;
    }

    this.departmentsCardList.forEach((card) => {
      card.selected = selected;
    });
  }

  public updateAllComplete() {
    this.allSelected =
      this.departmentsCardList != null &&
      this.departmentsCardList.every((card) => card.selected);
  }
}

export interface Card {
  iddepartment: string;
  selected: boolean;
  subtitle: string;
  text: string;
  title: string;
}
