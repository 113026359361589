import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { UserSessionCheckerService } from 'src/app/Services/UserManagement/UserSessionChecker/user-session-checker.service';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss'],
})
export class ProfileComponent implements OnInit, OnDestroy {
  constructor(
    public router: Router,
    private userSessionCheckerService: UserSessionCheckerService
  ) {}

  private userSessionCheckerServiceSubscription: any;

  ngOnDestroy(): void {
    this.userSessionCheckerServiceSubscription.unsubscribe();
  }

  ngOnInit(): void {
    this.userSessionCheckerService.checkUserSession();

    this.userSessionCheckerServiceSubscription =
      this.userSessionCheckerService.currentUserStatus.subscribe((status) => {
        if (status == true) {
          document.getElementById('hiddenContainer').classList.remove('hidden');
        }
      });
  }
}
