import { Component, OnInit } from '@angular/core';
import { Card } from '../department-management.component';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
import { RemoveDepartmentsConfirmationResponseComponent } from './remove-departments-confirmation-response/remove-departments-confirmation-response.component';
import { DepartmentApiService } from 'src/app/Services/DepartmentManagement/department-api.service';

@Component({
  selector: 'app-remove-departments-confirmation',
  templateUrl: './remove-departments-confirmation.component.html',
  styleUrls: ['./remove-departments-confirmation.component.css'],
})
export class RemoveDepartmentsConfirmationComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<RemoveDepartmentsConfirmationComponent>,
    public dialog: MatDialog,
    public departmentApiService: DepartmentApiService
  ) {}

  selectedDepartments: Card[] = [];
  emptySelectedDepartments: boolean;

  ngOnInit(): void {}

  public cancel() {
    this.dialogRef.close();
  }

  public removeSelected() {
    var selectedDepartmentsIds = this.selectedDepartments.map(
      (card) => card.iddepartment
    );

    this.departmentApiService
      .deleteDepartments(selectedDepartmentsIds)
      .subscribe({
        next: () => {
          this.dialogRef.close();
          var responseDialog = this.dialog.open(
            RemoveDepartmentsConfirmationResponseComponent,
            {
              width: '20em',
              height: '15em',
            }
          );

          responseDialog.componentInstance.departmentsRemoved = true;
        },
        error: (err) => {
          this.dialogRef.close();
          var responseDialog = this.dialog.open(
            RemoveDepartmentsConfirmationResponseComponent,
            {
              width: '20em',
              height: '15em',
            }
          );

          responseDialog.componentInstance.departmentsRemoved = false;
        },
      });
  }
}
