<body>
  <div (keyup)="confirm('remove')">
    <div><label i18n>Are you sure of removing this media?</label></div>
    <div class="buttonsDiv">
      <div>
        <button
          class="returnButton"
          (click)="close()"
          mat-button
          color="primary"
          i18n
        >
          Go back
        </button>
      </div>
      <div>
        <button (click)="confirm('remove')" mat-flat-button color="warn" i18n>
          Remove
        </button>
      </div>
    </div>
  </div>
</body>
