<html>
  <body>
    <img
      src="assets/img/skleLogos/sklePrivacyPolicyLogo.png"
      class="skleLogo"
    />
    <div class="container">
      <div class="content">
        <div class="title">Aviso legal</div>
        <div class="subregionTitle">Datos del Responsable</div>
        <div class="textContent">
          <ul>
            <li><b>Identidad del Responsable:</b> Skylife Engineering S.L</li>
            <li><b>Nombre comercial:</b> Skylife Engineering S.L</li>
            <li><b>NIF/CIF:</b> B91936997</li>
            <li>
              <b>Dirección:</b> Calle Américo Vespucio 5 Bloque 1 Local A 8-12.
              41092 Sevilla (Sevilla, ESPAÑA)
            </li>
            <li><b>Correo electrónico:</b> skylife@skylife-eng.com</li>
            <li>
              <b>Inscrita en el Registro Mercantil</b> de Sevilla, en el tomo
              5370, folio 63, hoja SE-89533, el 03-05-2011
            </li>
          </ul>
          <p>
            En este espacio, el usuario, podrá encontrar toda la información
            relativa a los términos y condiciones legales que definen las
            relaciones entre los usuarios y nosotros como responsables de esta
            web. Como usuario, es importante que conozcas estos términos antes
            de continuar tu navegación.
          </p>
          <p>
            Skylife Engineering S.L como responsables de esta web, asumimos el
            compromiso de procesar la información de nuestros usuarios y
            clientes con plenas garantías y cumplir con los requisitos
            nacionales y europeos que regulan la recopilación y uso de los datos
            personales de nuestros usuarios.
          </p>
          <p>
            Esta web, por tanto, cumple rigurosamente con el Reglamento (UE)
            2016/679 del Parlamento Europeo y del Consejo de 27 de abril de 2016
            relativo a la protección de las personas físicas (RGPD), la Ley
            Orgánica 3/2018, de 5 de diciembre, de Protección de Datos
            Personales y garantía de los derechos digitales (LOPDGDD), así como
            con la Ley 34/2002, de 11 de julio, de Servicios de la Sociedad de
            la Información y Comercio Electrónico (LSSICE ó LSSI).
          </p>

          <p class="subregionTitle">Condiciones generales de uso</p>

          <p>
            Las presentes Condiciones Generales regulan el uso (incluyendo el
            mero acceso) de las páginas de web, integrantes del sitio web de
            Skylife Engineering S.L incluidos los contenidos y servicios puestos
            a disposición en ellas. Toda persona que acceda a la web,
            https://skylife-eng.com (“usuario”) acepta someterse a las
            Condiciones Generales vigentes en cada momento del portal
            https://skylife-eng.com.
          </p>

          <p class="subregionTitle">
            Datos personales que recabamos y como lo hacemos
          </p>
          <link
            rel="stylesheet"
            href="https://skylife-eng.com/politica-de-privacidad-y-proteccion-de-datos/"
          />

          <p class="subregionTitle">
            Compromisos y obligaciones de los usuarios
          </p>
          <p>
            El usuario queda informado, y acepta, que el acceso a la presente
            web no supone, en modo alguno, el inicio de una relación comercial
            con Skylife Engineering S.L. De esta forma, el usuario se compromete
            a utilizar el sitio web, sus servicios y contenidos sin contravenir
            la legislación vigente, la buena fe y el orden público.
          </p>
          <p>
            Queda prohibido el uso de la web, con fines ilícitos o lesivos, o
            que, de cualquier forma, puedan causar perjuicio o impedir el normal
            funcionamiento del sitio web. Respecto de los contenidos de esta
            web, se prohíbe:
          </p>

          <ul>
            <li>
              Su reproducción, distribución o modificación, total o parcial, a
              menos que se cuente con la autorización de sus legítimos
              titulares.
            </li>
            <li>
              Cualquier vulneración de los derechos del prestador o de los
              legítimos titulares;
            </li>
            <li>Su utilización para fines comerciales o publicitarios.</li>
          </ul>
          <p>
            En la utilización de la web, https://skylife-eng.com el usuario se
            compromete a no llevar a cabo ninguna conducta que pudiera dañar la
            imagen, los intereses y los derechos de Skylife Engineering S.L o de
            terceros o que pudiera dañar, inutilizar o sobrecargar el portal
            https://skylife-eng.com o que impidiera, de cualquier forma, la
            normal utilización de la web.
          </p>

          <p>
            No obstante, el usuario debe ser consciente de que las medidas de
            seguridad de los sistemas informáticos en Internet no son
            enteramente fiables y que, por tanto https://skylife-eng.com no
            puede garantizar la inexistencia de malware u otros elementos que
            puedan producir alteraciones en los sistemas informáticos (software
            y hardware) del usuario o en sus documentos electrónicos y ficheros
            contenidos en los mismos aunque ponemos todos los medios necesarios
            y las medidas de seguridad oportunas para evitar la presencia de
            estos elementos dañinos.
          </p>

          <p class="subregionTitle">Medidas de seguridad</p>
          <p>
            Los datos personales comunicados por el usuario a Skylife
            Engineering S.L pueden ser almacenados en bases de datos
            automatizadas o no, cuya titularidad corresponde en exclusiva a
            Skylife Engineering S.L, asumiendo ésta todas las medidas de índole
            técnica, organizativa y de seguridad que garantizan la
            confidencialidad, integridad y calidad de la información contenida
            en las mismas de acuerdo con lo establecido en la normativa vigente
            en protección de datos. La comunicación entre los usuarios y
            https://skylife-eng.com utiliza un canal seguro, y los datos
            transmitidos son cifrados gracias a protocolos a https, por tanto,
            garantizamos las mejores condiciones de seguridad para que la
            confidencialidad de los usuarios esté garantizada.
          </p>

          <p class="subregionTitle">Plataforma de resolución de conflictos</p>
          <p>
            Ponemos también a disposición de los usuarios la plataforma de
            resolución de litigios que facilita la Comisión Europea y que se
            encuentra disponible en el siguiente enlace:
            <link rel="stylesheet" href="http://ec.europa.eu/consumers/odr/" />
          </p>

          <p class="subregionTitle">
            Derechos de propiedad intelectual e industrial
          </p>
          <p>
            En virtud de la Ley de Propiedad Intelectual, quedan expresamente
            prohibidas la reproducción, la distribución y la comunicación
            pública, incluida su modalidad de puesta a disposición, de la
            totalidad o parte de los contenidos de esta página web, con fines
            comerciales, en cualquier soporte y por cualquier medio técnico, sin
            la autorización de Skylife Engineering S.L. El usuario se compromete
            a respetar los derechos de Propiedad Intelectual e Industrial
            titularidad de Skylife Engineering S.L.
          </p>
          <p>
            El usuario conoce y acepta que la totalidad del sitio web,
            conteniendo sin carácter exhaustivo el texto, software, contenidos
            (incluyendo estructura, selección, ordenación y presentación de los
            mismos) podcast, fotografías, material audiovisual y gráficos, está
            protegida por marcas, derechos de autor y otros derechos legítimos,
            de acuerdo con los tratados internacionales en los que España es
            parte y otros derechos de propiedad y leyes de España.
          </p>
          <p>
            En el caso de que un usuario o un tercero consideren que se ha
            producido una violación de sus legítimos derechos de propiedad
            intelectual por la introducción de un determinado contenido en la
            web, deberá notificar dicha circunstancia a Skylife Engineering S.L
            indicando:
          </p>

          <ul>
            <li>
              Datos personales del interesado titular de los derechos
              presuntamente infringidos, o indicar la representación con la que
              actúa en caso de que la reclamación la presente un tercero
              distinto del interesado.
            </li>
            <li>
              Señalar los contenidos protegidos por los derechos de propiedad
              intelectual y su ubicación en la web, la acreditación de los
              derechos de propiedad intelectual señalados y declaración expresa
              en la que el interesado se responsabiliza de la veracidad de las
              informaciones facilitadas en la notificación.
            </li>
          </ul>
          <p class="subregionTitle">Foro</p>
          <p>
            https://skylife-eng.com no somos responsables de ningún contenido
            publicado en foro generado por cualquier usuario ni de sus cuentas.
            El contenido remitido expresa el punto de vista de su autor
            solamente.
          </p>
          <p>
            El usuario acepta no usar este servicio para publicar o enlazar a
            cualquier material difamatorio, abusivo, de odio, amenazador, spam o
            similar a spam, susceptible de ofender, sea contenido para adultos o
            censurable, contenga información personal de otros, infrinja los
            derechos de autor, estimule la actividad ilegal, o que viole de
            cualquier otra manera cualquier ley.
          </p>
          <p>
            https://skylife-eng.com se reserva el derecho de eliminar o
            modificar cualquier mensaje publicado por cualquier motivo y sin
            explicación. Las solicitudes de eliminación o modificación de
            contenido se llevarán a cabo únicamente a nuestro criterio o juicio.
            Nos reservamos el derecho a tomar medidas contra cualquier cuenta en
            cualquier momento.
          </p>
          <p>
            Toda la información o contenido que se remita o suba puede ser
            revisado por https://skylife-eng.com. No debes remitir cualquier
            contenido o información que considere privada o confidencial.
          </p>
          <p>
            Skylife Engineering S.L Y MODERADORES NO SE HACEN RESPONSABLES DE
            LAS OPINIONES VERTIDAS POR LOS USUARIOS DEL FORO, ES UN FORO CON
            LIBERTAD DE EXPRESIÓN Y LAS OPINIONES SON LIBRES Y SUBJETIVAS
          </p>
          <p class="subregionTitle">¿Exclusión de garantías y responsable</p>
          <p>
            <b>
              Skylife Engineering S.L(Skylife Engineering S.L) no otorga ninguna
              garantía ni se hace responsable, en ningún caso, de los daños y
              perjuicios de cualquier naturaleza que pudieran traer causa de:</b
            >
          </p>

          <ul>
            <li>
              La falta de disponibilidad, mantenimiento y efectivo
              funcionamiento de la web, o de sus servicios y contenidos;
            </li>
            <li>
              La existencia de malware, programas maliciosos o lesivos en los
              contenidos.
            </li>
            <li>
              El uso ilícito, negligente, fraudulento o contrario a este Aviso
              Legal.
            </li>
            <li>
              La falta de licitud, calidad, fiabilidad, utilidad y
              disponibilidad de los servicios prestados por terceros y puestos a
              disposición de los usuarios en el sitio web.
            </li>
            <li>
              El prestador no se hace responsable bajo ningún concepto de los
              daños que pudieran dimanar del uso ilegal o indebido de la
              presente página web.
            </li>
          </ul>

          <p class="subregionTitle">Legislación y fuero aplicable</p>
          <p>
            Con carácter general las relaciones entre con los usuarios de sus
            servicios telemáticos, presentes en esta web se encuentran sometidas
            a la legislación de ESPAÑA y a los juzgados y tribunales de Sevilla.
          </p>

          <p class="subregionTitle">Contacto</p>
          <p>
            En caso de que cualquier usuario tuviese alguna duda acerca de estas
            Condiciones legales o cualquier comentario sobre el portal
            https://skylife-eng.com/, por favor diríjase a
            skylife@skylife-eng.com. El aviso legal han sido actualizadas por
            última vez el 02-11-2021.
          </p>
        </div>
        <button
          mat-flat-button
          (click)="goBack()"
          color="primary"
          class="goBackButton"
          i18n
        >
          Go back
        </button>
      </div>
    </div>
  </body>
</html>
